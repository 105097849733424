/**
 *
 * OCSubStep
 *
 */

import { H5 } from 'components/Text'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { faPlus } from '@fa-pro-regular/faPlus'
import { faEdit } from '@fa-pro-light/faEdit'
import { Col, Icon, Row, T } from '@nv/react-commons/src/Components'

import { ActionsStep } from '../ActionsStep'
import { IconButton } from '../IconButton'
import { Button } from '../Button'
import { Preview } from '../Preview'
import { Text } from '../Text'
import { withSize } from '../BreakpointListener'
import { media, Colors } from 'themes'

const FlexRow = styled(Row).attrs({ type: 'flex' })`
  && {
    flex-wrap: nowrap;
  }
`
const FlexCol = styled(Col)`
  && {
    min-width: 0;
    overflow: hidden;
  }
`
const FlexRowVertical = styled(FlexRow)`
  && {
    flex-direction: column;
  }
`
const TitleCol = styled(Col)`
  && {
    width: 72px;
    flex-grow: 0;
    flex-shrink: 0;
  }
`
const TitleRowMobile = styled(Row).attrs({ type: 'flex' })`
  && {
    margin-bottom: 16px;
  }
`
const StyledTitleMobile = styled(Col)`
  && {
    height: 24px;
    line-height: 24px;
  }
`
const StyledEditMobile = styled(StyledTitleMobile)`
  && {
    margin-left: auto;
  }
`
const StyledTitle = styled(H5).attrs({
  type: 'regular'
})`
  letter-spacing: 0.7px;
`
const DetailsCol = styled(Col)`
  && {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    ${media.min.tablet`
    padding-left: 16px;
  `};
    ${props => props.onClick && 'cursor: pointer;'};
  }
`
const Details = styled(Text).attrs({
  type: 'bold',
  size: 13
})``
const StyledSubStepIcon = styled(Icon).attrs({
  color: Colors.pinkishGrey,
  type: 'medium'
})`
  ${media.max.tablet`visibility: hidden;`};
  margin-right: 16px;
`
const StyledMobileIcon = styled(Icon).attrs({
  color: Colors.pinkishGrey,
  type: 'medium'
})``

const ActionCol = styled(Col)`
  && {
    flex-grow: 0;
  }
`
const Active = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${media.min.tablet`padding-left: 16px;`} ${media.max.tablet`padding-left: 8px;`};
`
const Subtitle = styled(Text).attrs({
  type: 'bold',
  color: Colors.pinkishGrey,
  size: 14
})``
const StyledIcon = styled(Icon)`
  margin-right: 8px;
`
const StyledIconButton = styled(IconButton).attrs({
  type: 'medium'
})`
  ${media.min.tablet`margin-left: 32px;`} ${media.max.tablet`margin-left: 16px;`};
`
const StyledButton = styled(Button).attrs({
  size: 'small'
})`
  && {
    min-width: 178px;
    width: auto;
  }
`
const StyledAddAnotherWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`
const StyledActionsWrapper = styled.div`
  margin-top: 16px;
`

class OCSubStep extends React.Component {
  static OPEN = 'open'
  static CLOSE = 'close'
  static DISABLE = 'disable'

  renderTitle = () => (
    <TitleCol>
      <StyledTitle id={this.props.title} allCaps />
    </TitleCol>
  )

  renderTitleMobile = () => (
    <TitleRowMobile>
      <StyledTitleMobile>
        <StyledMobileIcon icon={this.props.icon} fixedWidth />
      </StyledTitleMobile>
      <StyledTitleMobile>
        <StyledTitle id={this.props.title} allCaps />
      </StyledTitleMobile>
      <StyledEditMobile>{this.renderEditAction()}</StyledEditMobile>
    </TitleRowMobile>
  )

  isDisabled = () => this.props.status === OCSubStep.DISABLE

  renderEditAction = () =>
    this.isDisabled() ? null : (
      <ActionCol>
        <StyledIconButton
          icon={faEdit}
          onClick={this.props.onEdit}
          data-analyticsid={`editOCPickup ${this.props.title}`}
        />
      </ActionCol>
    )

  renderAddAnother = () => {
    if (this.props.renderAddAnother) {
      return this.props.renderAddAnother()
    } else if (this.props.onAddAnother) {
      return (
        <StyledAddAnotherWrapper>
          <StyledButton onClick={this.props.onAddAnother}>
            <StyledIcon icon={faPlus} color='inherit' />
            <T id='add_another' />
          </StyledButton>
        </StyledAddAnotherWrapper>
      )
    } else {
      return null
    }
  }

  renderBody = () => {
    const {
      isDesktop,
      status,
      loading,
      onSave,
      onCancel,
      icon,
      subtitle,
      renderActive,
      emptyTitle,
      renderPreview,
      hideSave,
      onEdit,
      title,
      previewStyle,
      saveLabel,
      cancelLabel
    } = this.props
    if (status === OCSubStep.OPEN) {
      return (
        <Active>
          {subtitle && <Subtitle id={subtitle} />}
          {renderActive()}
          {this.renderAddAnother()}
          {!hideSave && (
            <StyledActionsWrapper>
              <ActionsStep
                loading={loading}
                onSave={onSave}
                onCancel={onCancel}
                saveLabel={saveLabel}
                cancelLabel={cancelLabel}
                data-analyticsid={`ocPickup ${title}`}
              />
            </StyledActionsWrapper>
          )}
        </Active>
      )
    } else {
      return (
        <>
          <DetailsCol
            onClick={this.isDisabled() ? undefined : onEdit}
            data-analyticsid={`editOCPickup ${this.props.title}`}
          >
            <Details>
              <FlexRow>
                <StyledTitleMobile>
                  <StyledSubStepIcon icon={icon} fixedWidth />
                </StyledTitleMobile>
                <FlexCol style={previewStyle}>
                  {emptyTitle ? <Preview title={<T id={emptyTitle} />} disabled /> : renderPreview()}
                </FlexCol>
              </FlexRow>
            </Details>
          </DetailsCol>
          {isDesktop && this.props.onEdit && this.renderEditAction()}
        </>
      )
    }
  }

  render () {
    const { title, isDesktop } = this.props
    return (
      <>
        {isDesktop ? (
          <FlexRow>
            {title ? this.renderTitle() : null}
            {this.renderBody()}
          </FlexRow>
        ) : (
          <FlexRowVertical>
            {title ? this.renderTitleMobile() : null}
            {this.renderBody()}
          </FlexRowVertical>
        )}
      </>
    )
  }
}

OCSubStep.propTypes = {
  emptyTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideSave: PropTypes.bool,
  icon: PropTypes.object,
  isDesktop: PropTypes.bool,
  loading: PropTypes.bool,
  onAddAnother: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  previewStyle: PropTypes.object,
  renderActive: PropTypes.func,
  renderAddAnother: PropTypes.func,
  renderPreview: PropTypes.func,
  status: PropTypes.oneOf([OCSubStep.DISABLE, OCSubStep.CLOSE, OCSubStep.OPEN]),
  subtitle: PropTypes.string,
  title: PropTypes.string
}

const _OCSubStep = withSize(OCSubStep)
_OCSubStep.displayName = 'OCSubStep'
export { _OCSubStep as OCSubStep }
