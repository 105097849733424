import _ from 'lodash'
import { put, select, takeLatest } from 'redux-saga/effects'
import { nvCall } from '@nv/react-commons/src/Services'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { printerCreators, printerTypes } from 'containers/Base/redux'
import { dashApi } from 'services/api'
import { getSocket } from 'services/socket'
import { selectPrinter } from './selectors'
import {
  selectCountry,
  selectMarketplaceShipperViewFilter,
} from '../Base/selectors'
import { makeSelectLocale } from 'containers/LanguageProvider/selectors'
import { selectPrinterSettings } from 'containers/Base/selectors'
import { COUNTRIES } from '@nv/react-commons/src/Constants/Country'

const { selector } = SelectorUtils

const NORMAL_LIMIT = 40
const SPECIAL_LIMIT = 10
export const MAX_ITEMS = 5
export const TRUNCATION_MARK = '...'
export const PARCEL_SEPARATOR = '\\&'

export function * print ({ data: { batchId, trackingIds } }) {
  const searchFilters = []
  if (batchId) {
    searchFilters.push({
      field: 'batch_id',
      values: [batchId]
    })
  }
  if (!_.isEmpty(trackingIds)) {
    searchFilters.push({
      field: 'tracking_id',
      values: trackingIds
    })
  }
  if (_.isEmpty(searchFilters)) {
    return
  }
  const ordersViewFilter = yield select(selectMarketplaceShipperViewFilter())
  if (ordersViewFilter){
    searchFilters.push(ordersViewFilter)
  }

  const locale = yield select(makeSelectLocale())
  const printerSettings = yield select(selectPrinterSettings())
  const isUsingNewTemplate = printerSettings && (printerSettings.showCod || printerSettings.showParcelDescription)

  const response = isUsingNewTemplate
    ? yield nvCall(dashApi.searchOrderWithDetails, locale, { searchFilters }, 0, 10000)
    : yield nvCall(dashApi.searchOrder, locale, { searchFilters }, 0, 10000)
  const socket = getSocket()
  if (response.ok && socket) {
    const printer = yield select(selectPrinter())
    const systemId = yield select(selectCountry())
    // TODO - remove these shameful hard-coded values
    let TEMPLATE_ID = 6
    if (isUsingNewTemplate) {
      const showBoth = printerSettings.showCod && printerSettings.showParcelDescription
      const showCodOnly = printerSettings.showCod && !printerSettings.showParcelDescription
      const showParcelDescriptionOnly = !printerSettings.showCod && printerSettings.showParcelDescription
      if (showBoth) TEMPLATE_ID = 1000
      else if (showCodOnly) TEMPLATE_ID = 1001
      else if (showParcelDescriptionOnly) TEMPLATE_ID = 1002
    }
    const sortedData = _.sortBy(response?.data?.searchData, 'order.bulkSequenceNumber')
    const jobs = _.map(sortedData, order => {
      const orderToReturn = {
        id: order?.order?.trackingId ?? '',
        recipient_name: order?.order?.toName ?? '',
        address1: order?.order?.toAddress1 ?? '',
        address2: order?.order?.toAddress2 ?? '',
        contact: order?.order?.toContact ?? '',
        country: order?.order?.toCountry ?? '',
        postcode: order?.order?.toPostcode ?? '',
        shipper_name: order?.order?.fromName ?? '',
        tracking_id: order?.order?.trackingId ?? '',
        barcode: order?.order?.trackingId ?? '',
        route_id: '',
        driver_name: '',
        template_id: TEMPLATE_ID,
        sort_code: order?.order?.sortCode ?? 'X',
        system_id: systemId.toUpperCase()
      }
      if (isUsingNewTemplate) {
        if (printerSettings.showParcelDescription) {
          _.merge(orderToReturn, {
            parcel_descriptions: generateDescription(order?.order?.packageContent ?? '', systemId)
          })
        }
        if (printerSettings.showCod) {
          _.merge(orderToReturn, {
            cod: order?.order?.cod ?? 0
          })
        }
      }
      return orderToReturn
    })
    const data = {
      event: 'PRINTER:PRINT',
      socket_id: socket.id,
      jobs: jobs
    }
    socket.emit('message', printer.socketId, data)
  }
}

export function generateDescription (description, systemId) {
  let items = JSON.parse(description)
  if (items.length > MAX_ITEMS) {
    items = items.slice(0, MAX_ITEMS - 1)
    items.push({
      item_description: TRUNCATION_MARK
    })
  } else {
    items = items.slice(0, MAX_ITEMS)
  }
  return items
    .map(e => {
      /* eslint-disable no-control-regex */
      const regexNonASCII = /[^\x00-\x7F]/
      const limit =
        [COUNTRIES.VN, COUNTRIES.TH].includes(systemId.toLowerCase()) || !regexNonASCII.test(e.item_description)
          ? NORMAL_LIMIT
          : SPECIAL_LIMIT

      const quantity = e.quantity ? ` x${e.quantity}` : ''
      const fullItemDescription = e.item_description + quantity
      const shouldTruncate = fullItemDescription.length > limit
      const truncation = shouldTruncate ? TRUNCATION_MARK : ''

      const finalLimit = limit - truncation.length - quantity.length
      const limitedItemDescription = e.item_description.substring(0, finalLimit)

      return limitedItemDescription + truncation + quantity
    })
    .join(PARCEL_SEPARATOR)
}

export function * getPrinters () {
  const response = yield nvCall(dashApi.getAccountSettings, 'label_printer')
  if (response.ok) {
    const { data } = response
    const normalizedData = _.mapKeys(data, 'identifier')
    const prevData = yield select(selector('global', 'printer', 'data')())
    yield put(printerCreators.getPrintersSuccess({ ...normalizedData, ...prevData }))
  } else {
    yield put(printerCreators.getPrintersFailure(response.data.error))
  }
}

export default function * defaultSaga () {
  yield takeLatest(printerTypes.GET_PRINTERS_REQUEST, getPrinters)
  yield takeLatest(printerTypes.PRINT_REQUEST, print)
}

/* eslint camelcase:0 */
