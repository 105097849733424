import React, { useContext, useMemo, useState } from 'react'
import { StyledFlexBlock } from 'containers/FPLOrderCreate/styles'
import Button from 'components/Button'
import { T, Row, Col } from '@nv/react-commons/src/Components'
import { faArrowLeft } from '@fa-pro-light/faArrowLeft'
import { faBoxes } from '@fa-pro-light/faBoxes'
import { CURRENY_COUNTRY_LABELS, GOODS_CURRENCY_DATA, INPUT_METHODS } from 'containers/FPLOrderCreate/constants'
import { ButtonFullWidth, CancelButton, HorizontalSeperateLine, StyledMinCard } from './styles'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { Form } from '@nv/react-commons/src/Components'
import { compose } from 'redux'
import { BoxTable } from './BoxTable'
import { max } from 'lodash'
import { EditBoxForm } from './EditBoxForm'
import { EditBoxModal } from './EditBoxModal'
import { ListBoxSummary } from './ListBoxSummary'
import { Vspace } from 'components/Vspace'
import { KEYBOARD_INPUT_STATUS, MMCC_OC_STEP } from './constants'
import { aggregateBoxFromUploadFileFromKeyboard, buildBoxesForB2BOrders } from './utils'
import { PreviewStepInfo } from './PreviewStepInfo'

const initialState = {
  editBoxNo: null,
  isAdding: true,
  visibleModal: false,
  isResetEditForm: false
}

export const ManualKeyboardForm = ({ boxOrders, form, onMethodChange }) => {
  const [state, setState] = useState(initialState)
  const { editBoxNo, isAdding, visibleModal, isResetEditForm } = state

  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { keyboardInputStatus, draftOrdersFromKeyboard, selectedService } = ocState
  const { destination_country: destinationCountry } = selectedService

  const currency = useMemo(() => {
    return CURRENY_COUNTRY_LABELS[destinationCountry] || GOODS_CURRENCY_DATA.USD
  }, [destinationCountry])

  const totalValuesInPreviewOrder = useMemo(() => {
    const { totalBox, totalGoodsValue } = aggregateBoxFromUploadFileFromKeyboard(boxOrders)
    return {
      totalBox,
      totalGoodsValue
    }
  }, [boxOrders])

  const handleChangeCreationMethod = method => {
    onMethodChange(method)
  }

  const cancelAddNewBage = () => {
    setState(prevState => ({ ...prevState, isAdding: false, isResetEditForm: true }))
  }

  const validateFields = () => {
    form.validateFields((err, values) => {
      if (!err) {
        const boxNo = !draftOrdersFromKeyboard.length ? 1 : max(draftOrdersFromKeyboard.map(order => order.boxNo)) + 1
        setState(prevState => ({
          ...prevState,
          isAdding: true
        }))
        const newBoxOrdersOfForm = [...draftOrdersFromKeyboard, { ...values, boxNo }]
        const boxData = buildBoxesForB2BOrders(newBoxOrdersOfForm, currency)
        updateOCState({ draftOrdersFromKeyboard: newBoxOrdersOfForm, boxOrdersFromKeyboard: boxData })
        form.resetFields()
      }
    })
  }

  const addNewBag = () => {
    if (!isAdding) {
      setState(prevState => ({ ...prevState, isAdding: true }))
    } else {
      validateFields()
    }
  }

  const deleteOrder = order => {
    const updatedOrders = draftOrdersFromKeyboard.filter(or => or.boxNo != order.boxNo)
    setState(prevState => ({
      ...prevState,
      isAdding: !updatedOrders.length ? true : prevState.isAdding
    }))
    const boxData = buildBoxesForB2BOrders(updatedOrders, currency)
    updateOCState({ draftOrdersFromKeyboard: updatedOrders, boxOrdersFromKeyboard: boxData })
  }

  const editOrder = order => {
    setState(prevState => ({
      ...prevState,
      editBoxNo: order,
      visibleModal: true,
      isAdding: false,
      isResetEditForm: false
    }))
  }

  const closeModal = () => {
    setState(prevState => ({ ...prevState, visibleModal: false, isResetEditForm: true }))
    form.resetFields()
  }

  const saveEditOrder = order => {
    const updatedOrders = draftOrdersFromKeyboard.map(or => {
      if (or.boxNo === order.boxNo) {
        return order
      }
      return or
    })
    setState(prevState => ({
      ...prevState,
      editBoxNo: null,
      visibleModal: false
    }))
    const boxData = buildBoxesForB2BOrders(updatedOrders, currency)
    updateOCState({ draftOrdersFromKeyboard: updatedOrders, boxOrdersFromKeyboard: boxData })
  }

  const saveOrders = () => {
    updateOCState({
      keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.COMMERCIAL_INVOICE
    })
  }

  const handleEditStep = () => {
    updateOCState({ keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING })
  }

  return (
    <>
      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_SHOWING && (
        <Row gutter={14}>
          <Col span={18}>
            <StyledMinCard hoverable={false}>
              <Button size='small' icon={faArrowLeft} onClick={() => handleChangeCreationMethod(INPUT_METHODS.NONE)}>
                <T id='choose_input' allCaps />
              </Button>
              <HorizontalSeperateLine className='mt-2 mb-2' />

              <BoxTable orders={draftOrdersFromKeyboard} onDeleteOrder={deleteOrder} onEditOrder={editOrder} />

              <Vspace height={4} />
              {draftOrdersFromKeyboard.length > 0 && <HorizontalSeperateLine />}
              <Vspace height={14} />
              {isAdding && !visibleModal && <EditBoxForm orders={draftOrdersFromKeyboard} form={form} />}

              <StyledFlexBlock>
                <Button onClick={addNewBag} type='primary' size='medium'>
                  <T id={isAdding ? 'save_and_add_new_box' : 'add_new_box'} allCaps />
                </Button>
                {isAdding && draftOrdersFromKeyboard.length > 0 && (
                  <CancelButton onClick={cancelAddNewBage} size='medium'>
                    <T id='cancel' allCaps />
                  </CancelButton>
                )}
              </StyledFlexBlock>
            </StyledMinCard>
          </Col>
          <Col span={6}>
            <StyledMinCard hoverable={false}>
              <ListBoxSummary orders={draftOrdersFromKeyboard} />
            </StyledMinCard>
            <ButtonFullWidth
              type='primary'
              size='medium'
              disabled={!draftOrdersFromKeyboard.length || isAdding || visibleModal}
              onClick={saveOrders}
            >
              <T id='save_and_continue' allCaps />
            </ButtonFullWidth>
          </Col>
        </Row>
      )}

      {editBoxNo && (
        <EditBoxModal
          visible={visibleModal}
          order={editBoxNo}
          isResetEditForm={isResetEditForm}
          onCancel={closeModal}
          onOk={saveEditOrder}
        />
      )}

      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_PREVIEW && (
        <PreviewStepInfo
          quantity={totalValuesInPreviewOrder.totalBox}
          unit={currency}
          creationMethod={INPUT_METHODS.KEYBOARD}
          totalValue={totalValuesInPreviewOrder.totalGoodsValue}
          translatedKeys={{
            titleKey: 'international_total_boxes',
            totalPluralKey: 'boxes',
            totalSingularKey: 'box',
            totalValueKey: 'total_goods_value',
            creationMethodKey: 'international_creation_method'
          }}
          icon={faBoxes}
          onEditStep={handleEditStep}
        />
      )}
    </>
  )
}

export const ManualKeyboard = compose(Form.create())(React.memo(ManualKeyboardForm))
