import { PREMIUM_SERVICE_LEVEL, STANDARD_SERVICE_LEVEL } from '@nv/react-commons/src/Constants'
import { CountryUtils, SelectorUtils } from '@nv/react-commons/src/Utils'
import {
  selectPremiumShipper,
  selectShipper,
  selectOcSettings,
  selectIsMarketplaceSeller,
  selectIsCorporateHQ,
  selectIsMarketplace,
  selectAllowedDeliveryTimeslots
} from 'containers/Base/selectors'
import {
  PICKUP_ADDRESS,
  RESERVATION,
  selectIsOCTypeNinjaPack,
  selectIsOCTypeCorporateAWB,
  selectIsOCTypeReturn,
  selectMapping,
  selectIsDocumentSupported,
  VOLUME,
  selectIsBulkUpload,
  selectOrders,
  selectOrderInfo,
  selectIsB2BBundleSupported,
  selectIsCorporateB2BBundleSupported
} from 'containers/OrderCreate/selectors'
import { getServiceType, calculateOrderServiceType } from 'containers/OrderCreate/utils'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { selectIsPrePaidAndSupported, selectPricingData } from 'containers/WalletProvider/selectors'
import _ from 'lodash'
import moment from 'moment'
import csvMapping, { getSourceId, mapOrderToPayload } from 'utils/csvMapping'
import { getTimezone, momentUTCWithTimezone } from 'utils/time'
import { ocCompletedTypes } from 'containers/OCCompleted/redux'
import { orderCreateTypes } from 'containers/Base/redux/order-create'
import { selectIsCorporateDocumentSupported, selectIsParcelOCSupported } from '../containers/OrderCreate/selectors'
import { select } from 'redux-saga/effects'
import { mapPickupAddressToOrderFields } from './address'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'
import { mapBool } from './csvMappingFields'

const { getAddressInfo } = CountryUtils
const { selector } = SelectorUtils

const BATCH_SIZE = 25

export function* createOrderPayload (action) {
  const { batchSize = BATCH_SIZE, type, recompose } = action
  let { orders } = action
  const allowedDeliveryTimeslots = yield select(selectAllowedDeliveryTimeslots())
  const shipper = yield select(selectShipper())
  const isPremium = yield select(selectPremiumShipper())
  const isNinjaPackOrder = yield select(selectIsOCTypeNinjaPack())
  const isCorpAwbOrder = yield select(selectIsOCTypeCorporateAWB())
  const isDocumentTypeSupported = yield select(selectIsDocumentSupported())
  const isParcelOCSupported = yield select(selectIsParcelOCSupported())
  const isCorporateDocumentTypeSupported = yield select(selectIsCorporateDocumentSupported())
  const isB2BBundleSupported = yield select(selectIsB2BBundleSupported())
  const isCorporateB2BBundleSupported = yield select(selectIsCorporateB2BBundleSupported())
  const serviceLevel = isPremium ? PREMIUM_SERVICE_LEVEL : STANDARD_SERVICE_LEVEL
  const country = shipper?.country
  const isBulkUpload = yield select(selectIsBulkUpload())
  const isReturnOrder = yield select(selectIsOCTypeReturn())
  const userMapping = yield select(selectMapping())
  const isPrePaid = yield select(selectIsPrePaidAndSupported())
  const pricingData = yield select(selectPricingData())
  const ocSettings = yield select(selectOcSettings())
  const isMarketplaceSeller = yield select(selectIsMarketplaceSeller())
  const isCorporateHq = yield select(selectIsCorporateHQ())
  const isMarketplace = yield select(selectIsMarketplace())
  const defaultDeliveryType = yield select(selector('global', 'orderCreate', 'deliveryType')())

  const shouldGetPricingData = isPrePaid && !isNinjaPackOrder && !isCorpAwbOrder
  const isInternational = userMapping?.type === 'International'
  const dataSelector = ['global', isReturnOrder ? 'orderReturn' : 'orderCreate']
  const orderCreate = yield select(selector(...dataSelector)())
  const timezone = getTimezone(country)
  const rsvn = orderCreate?.[RESERVATION]
  const pickupApproximateVolume = orderCreate?.[VOLUME]
  const batchId = orderCreate?.progress?.batchId
  const isPickupRequired = orderCreate.pickupType === PICKUP_TYPES.SCHEDULED
  const fromInfo = orderCreate?.[PICKUP_ADDRESS]
  const addressInfo = _.map(_.values(getAddressInfo(country)), value => _.camelCase(value))
  const isCorporateBranch = ocSettings?.isCorporateBranch
  const serviceType = getServiceType({
    isReturnOrder,
    isInternational,
    isNinjaPackOrder,
    isCorporateBranch,
    isCorpAwbOrder,
    isDocumentTypeSupported,
    isParcelOCSupported,
    isMarketplaceSeller,
    isCorporateHq,
    isMarketplace
  })
  const fields = csvMapping.getAllOrderFields(
    shipper,
    orderCreate,
    serviceType,
    isB2BBundleSupported,
    allowedDeliveryTimeslots
  )

  if (!orders) {
    switch (type) {
      case ocCompletedTypes.ESTIMATE_PRICE_REQUEST: {
        // Gets orders if the orders are completed
        const orderInfo = yield select(selectOrderInfo())
        orders = orderInfo.orders
        break
      }
      case orderCreateTypes.SUBMIT:
        // Gets orders if the orders are being created
        orders = yield select(selectOrders({ isReturnOrder, recompose }))
        break
      default:
        // This should be the default case as order payload is normally used before orders are completed
        orders = yield select(selectOrders({ isReturnOrder, recompose }))
        break
    }

    orders = orders.map((order, i) => ({
      batchSequenceNumber: i + 1,
      // ...order is behind batchSequenceNumber because we want to maintain the original batchSequenceNumber
      ...order,
      serviceType:
        // needed to show MPS form if editing failing MPS orders, because we're checking the "MPS" service type
        isB2BBundleSupported && isMPSOrder(order) ? OC_SERVICE_TYPES.MULTI_PIECE_SHIPMENT : order.serviceType,
      deliveryType: order.deliveryType || defaultDeliveryType
    }))
  }

  const payload = {
    ...(batchId ? { batchId } : {}),
    serviceType,
    orders: orders.map((o, i) => {
      const orderPayload = {
        ...(shouldGetPricingData ? { price: pricingData?.[i]?.price } : {}),
        serviceType: calculateOrderServiceType(
          isDocumentTypeSupported,
          isCorporateDocumentTypeSupported,
          o,
          serviceType,
          orderCreate?.serviceType
        ),
        internalRef: {
          sourceId: getSourceId(isBulkUpload, isReturnOrder),
          batchSequenceNumber: o.batchSequenceNumber
        },
        from: !fromInfo
          ? {
              name: '-',
              phoneNumber: '0000000',
              address: {
                address1: '-',
                address2: '-',
                postcode: '00000',
                country: country
              }
            }
          : {
              ..._.pick(fromInfo, ['name', 'email']),
              phoneNumber: fromInfo.contact,
              address: {
                ..._.pick(mapPickupAddressToOrderFields({ country, pickupAddress: fromInfo }), [
                  'address1',
                  'address2',
                  'postcode',
                  'latitude',
                  'longitude',
                  ...addressInfo
                ]),
                country
              }
            },
        parcelJob: {
          isPickupRequired,
          ...(isPickupRequired
            ? {
                pickupServiceType: 'Scheduled',
                pickupServiceLevel: serviceLevel,
                pickupAddressId: rsvn.addressId ? `${rsvn.addressId}` : undefined,
                pickupDate: moment(rsvn.readyDatetime).format('YYYY-MM-DD'),
                pickupInstructions: rsvn.comments,
                pickupTimeslot: {
                  startTime: rsvn?.startTime || momentUTCWithTimezone(rsvn?.readyDatetime, country).format('HH:mm'),
                  endTime: rsvn?.endTime || momentUTCWithTimezone(rsvn?.latestDatetime, country).format('HH:mm'),
                  timezone
                },
                pickupApproximateVolume
              }
            : {})
        }
      }
      if (o.parcelDescription || o.isDangerousGood) {
        orderPayload.parcelJob.items = parseParcelDescription(o)
      }

      const isOrderB2BBundle = isB2BBundleOrder(o)
      if (isB2BBundleSupported && isOrderB2BBundle) {
        orderPayload.b2b = parseB2BBundleInformation(o)
        orderPayload.bundleInformation = parseB2BBundleCartonInformation(o)
      } else {
        delete orderPayload.b2b
        delete orderPayload.bundleInformation
      }

      if (isCorporateBranch) {
        orderPayload.corporate = {
          branchId: shipper?.externalRef
        }
      }
      if (isMarketplaceSeller) {
        orderPayload.marketplace = {
          sellerId: shipper?.externalRef,
          sellerCompanyName: shipper?.shortName
        }
      }

      const { payload: mappedPayload, errors } = mapOrderToPayload(fields, o, orderPayload)

      if (isB2BBundleSupported && isOrderB2BBundle) {
        mappedPayload.serviceType = OC_SERVICE_TYPES.B2B_BUNDLE
        if (isCorporateB2BBundleSupported) {
          mappedPayload.serviceType = OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE
        }

        if (!mappedPayload.parcelJob.deliveryInstructions) {
          mappedPayload.parcelJob.deliveryInstructions = parseInstructions(o)
        }
      }

      return _.assign(mappedPayload, { errors })
    })
  }

  return {
    payload,
    orders,
    isPrePaid,
    batchSize
  }
}

const parseInstructions = ({ noOfCartons = 1, rdoRequired, grnRequired }) => {
  const rdo = mapBool(rdoRequired)
  const grn = mapBool(grnRequired)

  let requirements = ''
  if (rdo) requirements += 'RDO'
  if (rdo && grn) requirements += ' and '
  if (grn) requirements += 'GRN'

  const cartonLabel = noOfCartons === 1 ? 'Carton' : 'Cartons'

  return `${noOfCartons} ${cartonLabel}${requirements ? `, ${requirements} required` : ''}`
}

export const isB2BBundleOrder = o => {
  return isMPSOrder(o) || mapBool(o.rdoRequired) || mapBool(o.grnRequired)
}

export const isMPSOrder = o => {
  return parseInt(o.noOfCartons) > 1
}

const parseB2BBundleInformation = o => {
  const documentsRequired = ['grn', 'rdo'].filter(key => mapBool(o[`${key}Required`])).map(key => key.toUpperCase())

  return { documentsRequired }
}

const parseB2BBundleCartonInformation = o => {
  const mpsInfoObj = {
    totalQuantity: 1
  }
  if (o.noOfCartons !== undefined) {
    let parseNoOfCartons = parseInt(o.noOfCartons)
    if (!isNaN(parseNoOfCartons)) {
      mpsInfoObj.totalQuantity = parseNoOfCartons
    }
    const requestedPieceTids = o.requestedPieceTrackingNumbers
    if (!_.isEmpty(requestedPieceTids)) {
      mpsInfoObj.requestedPieceTrackingNumbers = requestedPieceTids.split(',').map(item => item.trim())
    }
  }

  return mpsInfoObj
}

export const parseParcelDescription = order => {
  let items

  const isDangerousGoodsObj =
    order.isDangerousGood !== undefined && order.isDangerousGood !== ''
      ? { isDangerousGood: order.isDangerousGood.toString().toLowerCase() === 'true' }
      : {}

  if (!order.parcelDescription) {
    items = [{ itemDescription: '', ...isDangerousGoodsObj }]
  } else {
    const parsedItemDescription = order.parcelDescription.split(/\r\n|\r|\n|,/)
    if (parsedItemDescription?.length >= 1) {
      const parsedItems = parsedItemDescription.filter(Boolean).map(item => {
        if (item) {
          const itemQuantityArr = item.match(/\s[xX]\d+/g)
          const itemNameArr = item.split(/\s[xX]\d+/)
          if (itemQuantityArr && itemNameArr) {
            return {
              itemDescription: itemNameArr[0].trim(),
              quantity: parseInt(itemQuantityArr[0].trim().split(/[xX]/)[1]),
              ...isDangerousGoodsObj
            }
          }
          return { itemDescription: item.trim(), ...isDangerousGoodsObj }
        }
      })
      items = parsedItems
    } else {
      items = [{ itemDescription: order.parcelDescription, ...isDangerousGoodsObj }]
    }
  }
  return items
}
