import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/Text'
import { StyledButton, StyledCircleIcon } from './styles'

const LargeOptionButton = ({ icon, children, ...props }) => (
  <StyledButton {...props}>
    {icon && <StyledCircleIcon size='big' icon={icon} />}
    <Text size={16}>{children}</Text>
  </StyledButton>
)

LargeOptionButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node
}

LargeOptionButton.defaultProps = {
  children: 'LargeOptionButton'
}

export { LargeOptionButton }
