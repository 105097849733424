import React, { useEffect, useState } from 'react'
import { OCHeader } from 'components/OCHeader'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { BCWrapper, VerticalStepperContainer } from './styles'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { ServiceDetailsStep } from './ServiceDetailsStep'
import { OrdersStep } from './OrdersStep'
import { StepStatus, VerticalStepper } from '@nv/react-akira'
import {
  CCType,
  FILE_UPLOAD_STATUS,
  INTERNAL_STEP_STATUS,
  KEYBOARD_INPUT_STATUS,
  MMCC_OC_STEP,
  OCMethod,
  SUPPORTED_DOCUMENT_STATUS,
  TRANSLATION_KEY_BY_MMCC_STEP
} from './constants'
import { useIntl } from 'hooks/common'
import { useMarketingContent } from 'containers/FPLMarketingPage/useMarketingContent'
import { MMCCOrderCreationState, Service } from './types'
import { Spin } from '@nv/react-commons/src/Components'
import { buildFullErrorInfo } from 'containers/FPLErrorPage/dataUtils'
import { NOT_FOUND_CODE } from 'containers/FPLErrorPage/constants'
import FPLErrorPage from 'containers/FPLErrorPage'
import FPLMarketingPage from 'containers/FPLMarketingPage'
import { PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { NoServicesErrorAlert } from 'containers/FPLOrderCreate/ErrorAlert'
import { INPUT_METHODS, SERVICE_TYPE } from '../FPLOrderCreate/constants'
import { PICKUP_TYPES } from '../PickupType/constants'
import { getPickupType } from './utils'
import { CommercialInvoiceStep } from './CommercialInvoiceStep'
import { SaveAndContinue } from '../FPLOrderCreate/SaveAndContinue'
import { DEFAULT_VOLUME } from 'containers/FPLOrderCreate/constants'
import { ReviewStep } from './ReviewStep'
import { DeliveryOrderStep } from './DeliveryOrderStep'

const initialState: MMCCOrderCreationState = {
  currentStep: MMCC_OC_STEP.SERVICE_DETAILS,
  services: [],
  isSelectingService: false,
  selectedService: undefined,
  senderAddress: undefined,
  recipientAddress: undefined,
  pickupAddress: undefined,
  pickupType: PICKUP_TYPES.SCHEDULED,
  selectedCCType: CCType.B2B,
  ocMethod: undefined,
  isAddressLoading: false,
  timeSlot: undefined,
  timeSlots: [],
  volume: DEFAULT_VOLUME,
  fileUploadStatus: FILE_UPLOAD_STATUS.READY_TO_UPLOAD,
  boxOrders: [],
  pickupAddresses: [],
  senderAddresses: [],
  recipientAddresses: [],
  boxOrdersFromKeyboard: [],
  draftOrdersFromKeyboard: [],
  keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING,
  allOrders: {},
  validOrders: {},
  invalidOrders: {},
  validRows: {},
  invalidRows: {},
  fileName: undefined,
  numberOfValidItemsInValidOrders: 0,
  numberOfInvalidItemsInInvalidOrders: 0,
  numberOfValidItemsInInvalidOrders: 0,

  // supported document
  commercialStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
  deliveryStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
  commercialFiles: [],
  deliveryFiles: [],
  commercialErrors: [],
  commercialFileNames: [],
  deliveryErrors: [],
  deliveryFileNames: []
}

const MMCCOrderCreation = () => {
  const navigate = useNavigate()
  const { error, detail, loading, fullRes } = useMarketingContent(navigate, location, ROUTES.FPL_MMCC_ORDER_CREATION, {
    noHeader: false
  })

  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const updateState = (newState: Partial<MMCCOrderCreationState>) => {
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const { currentStep, isSelectingService, services, selectedService, selectedCCType } = state

  const updateService = (newService: Service) => {
    if (selectedService == newService) {
      return
    }

    updateState({
      // common info
      selectedService: newService,
      ocMethod: undefined,
      currentStep: MMCC_OC_STEP.SERVICE_DETAILS,

      // service details
      senderAddress: undefined,
      recipientAddress: undefined,
      pickupAddress: undefined,
      pickupType: getPickupType(newService),
      timeSlot: undefined,
      volume: DEFAULT_VOLUME,
      selectedCCType: CCType.B2B,

      // orders info
      boxOrders: [],
      boxOrdersFromKeyboard: [],
      fileUploadStatus: FILE_UPLOAD_STATUS.READY_TO_UPLOAD,
      keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING,
      draftOrdersFromKeyboard: [],
      allOrders: {},
      validOrders: {},
      invalidOrders: {},
      validRows: {},
      invalidRows: {},
      fileName: undefined,
      numberOfValidItemsInValidOrders: 0,
      numberOfInvalidItemsInInvalidOrders: 0,
      numberOfValidItemsInInvalidOrders: 0,

      // Supporting docs
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      deliveryStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      commercialFiles: [],
      deliveryFiles: [],
      commercialErrors: [],
      commercialFileNames: [],
      deliveryErrors: [],
      deliveryFileNames: []
    })
  }

  const updateCCType = (newCCType: CCType) => {
    if (newCCType == selectedCCType) {
      return
    }

    updateState({
      selectedCCType: newCCType,
      boxOrders: [],
      boxOrdersFromKeyboard: [],
      fileUploadStatus: FILE_UPLOAD_STATUS.READY_TO_UPLOAD,
      keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING,
      currentStep: MMCC_OC_STEP.SERVICE_DETAILS,
      draftOrdersFromKeyboard: [],
      ocMethod: undefined
    })
  }

  useEffect(() => {
    updateState({ isSelectingService: true })
    const partnerServices = detail?.settings?.oc_config?.services || []
    const mmccServices = partnerServices.filter((service: Service) => {
      return service.type === SERVICE_TYPE.MMCC
    })
    const defaultFirstService = mmccServices.length ? mmccServices[0] : undefined
    setState(prevState => ({
      ...prevState,
      isSelectingService: false,
      services: mmccServices,
      selectedService: defaultFirstService
    }))
  }, [detail])

  const handleOnLeaveHeader = () => {
    navigate(ROUTES.FPL_HOME)
  }

  const changeCurrentStep = (step: number) => {
    setState(prevState => ({ ...prevState, currentStep: step }))
  }

  // error happened when fetch partner
  if (error && !loading) {
    const { apiUrl, message, status } = buildFullErrorInfo(fullRes)
    const errorInfo = {
      errorApi: apiUrl,
      errorMessage: message,
      errorStatus: status
    }
    return error.code !== NOT_FOUND_CODE ? (
      <FPLErrorPage pageName={PAGE_NAMES[ROUTES.FPL_OC]} errors={[errorInfo]} />
    ) : (
      <FPLMarketingPage />
    )
  }

  // error happens when services not found
  if (!services.length && detail) {
    return <NoServicesErrorAlert message={intl.formatMessage({ id: 'cross_border_profile_no_mmcc_service' })} />
  }

  const saveOCMethod = (method: string) => {
    let currentOcMethod = undefined
    if (method === INPUT_METHODS.FILE) {
      currentOcMethod = OCMethod.UPLOAD
    }
    if (method === INPUT_METHODS.KEYBOARD) {
      currentOcMethod = OCMethod.KEYBOARD
    }
    setState(prevState => ({
      ...prevState,
      ocMethod: currentOcMethod
    }))
  }

  return (
    <MMCCOrderCreationContext.Provider
      value={{ ocState: state, updateService, updateCCType, updateOCState: updateState }}
    >
      <OCHeader title='create_international_mmcc_order' onLeave={handleOnLeaveHeader} />
      {loading || isSelectingService ? (
        <div className='flex justify-center items-center h-36 w-100'>
          <Spin />{' '}
        </div>
      ) : (
        <BCWrapper>
          <VerticalStepperContainer>
            <VerticalStepper.Step
              key={MMCC_OC_STEP.SERVICE_DETAILS}
              title={intl.formatMessage({ id: TRANSLATION_KEY_BY_MMCC_STEP[MMCC_OC_STEP.SERVICE_DETAILS] })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.SERVICE_DETAILS)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.SERVICE_DETAILS)}
              className='w-100'
            >
              {(currentStep == MMCC_OC_STEP.SERVICE_DETAILS || currentStep == MMCC_OC_STEP.REVIEW) && (
                <ServiceDetailsStep />
              )}
              {currentStep == MMCC_OC_STEP.SERVICE_DETAILS && (
                <SaveAndContinue
                  onSave={() => {
                    changeCurrentStep(MMCC_OC_STEP.ORDERS)
                  }}
                />
              )}
            </VerticalStepper.Step>
            <VerticalStepper.Step
              key={MMCC_OC_STEP.ORDERS}
              title={intl.formatMessage({ id: TRANSLATION_KEY_BY_MMCC_STEP[MMCC_OC_STEP.ORDERS] })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.ORDERS)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.ORDERS)}
              className='w-100'
            >
              {(currentStep == MMCC_OC_STEP.ORDERS || currentStep == MMCC_OC_STEP.REVIEW) && (
                <OrdersStep
                  ccType={selectedCCType}
                  saveOCMethod={saveOCMethod}
                  selectedService={selectedService}
                  partnerId={detail?.id}
                />
              )}
            </VerticalStepper.Step>
            {/* Will enable in another ticket */}
            <VerticalStepper.Step
              key={MMCC_OC_STEP.COMMERCIAL_INVOICE}
              title={intl.formatMessage({ id: TRANSLATION_KEY_BY_MMCC_STEP[MMCC_OC_STEP.COMMERCIAL_INVOICE] })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.COMMERCIAL_INVOICE)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.COMMERCIAL_INVOICE)}
              className='w-100'
            >
              {(currentStep == MMCC_OC_STEP.COMMERCIAL_INVOICE || currentStep == MMCC_OC_STEP.REVIEW) && (
                <CommercialInvoiceStep />
              )}
            </VerticalStepper.Step>
            <VerticalStepper.Step
              key={MMCC_OC_STEP.DELIVERY_ORDER}
              title={intl.formatMessage({ id: TRANSLATION_KEY_BY_MMCC_STEP[MMCC_OC_STEP.DELIVERY_ORDER] })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.DELIVERY_ORDER)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.DELIVERY_ORDER)}
              className='w-100'
            >
              {(currentStep == MMCC_OC_STEP.DELIVERY_ORDER || currentStep == MMCC_OC_STEP.REVIEW) && (
                <DeliveryOrderStep />
              )}
            </VerticalStepper.Step>
            <VerticalStepper.Step
              key={MMCC_OC_STEP.REVIEW}
              title={intl.formatMessage({ id: TRANSLATION_KEY_BY_MMCC_STEP[MMCC_OC_STEP.REVIEW] })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.REVIEW)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.REVIEW)}
              className='w-100'
            >
              {currentStep == MMCC_OC_STEP.REVIEW && <ReviewStep />}
            </VerticalStepper.Step>
          </VerticalStepperContainer>
        </BCWrapper>
      )}
    </MMCCOrderCreationContext.Provider>
  )
}

const getStepStatus = (currentStep: number, step: number) => {
  if (step === currentStep) {
    return INTERNAL_STEP_STATUS.current as StepStatus
  }
  if (currentStep > step) {
    return INTERNAL_STEP_STATUS.completed as StepStatus
  }
  return INTERNAL_STEP_STATUS.unvisited as StepStatus
}

export default MMCCOrderCreation
