import { createSelector } from 'reselect'
import { SelectorUtils } from '@nv/react-commons/src/Utils'

const { selector } = SelectorUtils

export const selectShopifyOrders = country =>
  createSelector(selector('entity', 'shopifyOrders')(), entity => {
    return entity?.data ?? []
  })

export const selectShopifyOrdersError = () =>
  createSelector(selector('entity', 'shopifyOrders')(), entity => {
    const error = entity?.error
    if (!error) {
      return error
    }
    const msg = error?.message
    if (msg) {
      try {
        const msgJson = JSON.parse(msg)
        return msgJson?.error?.title
      } catch (e) {
        // ignore
      }
    }
    return error?.title ?? 'Unknown error occured'
  })

export const selectIsCreatingShopifyOrders = () =>
  createSelector(selector('global', 'orderCreate', 'orderSource')(), orderSource => {
    return orderSource === 'shopify'
  })
