import React, { useContext } from 'react'
import { SupportedDocument } from './SupportedDocument'
import { MMCC_OC_STEP, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'

const CommercialInvoiceStep = () => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { commercialStatus, commercialFiles, commercialErrors, commercialFileNames } = ocState

  const handleSkip = () => {
    updateOCState({
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.DELIVERY_ORDER,
      commercialFiles: [],
      commercialErrors: [],
      commercialFileNames: []
    })
  }

  const handleEditStep = () => {
    updateOCState({
      commercialStatus: commercialFiles.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
        : SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD
    })
  }

  const saveFiles = (uploadedResult, fileErrors, fileNames) => {
    updateOCState({
      commercialFiles: uploadedResult,
      commercialErrors: fileErrors,
      commercialFileNames: fileNames,
      commercialStatus: fileErrors.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
        : SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
    })
  }

  const goToNextStep = () => {
    updateOCState({
      currentStep: MMCC_OC_STEP.DELIVERY_ORDER,
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW
    })
  }

  const handleReset = () => {
    updateOCState({
      commercialFiles: [],
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      commercialErrors: [],
      commercialFileNames: []
    })
  }

  return (
    <SupportedDocument
      fileType={MMCC_OC_STEP.COMMERCIAL_INVOICE}
      status={commercialStatus}
      errors={commercialErrors}
      fileNames={commercialFileNames}
      uploadedFiles={commercialFiles}
      onEditStep={handleEditStep}
      onSave={saveFiles}
      onNext={goToNextStep}
      onSkip={handleSkip}
      onReset={handleReset}
    />
  )
}

export { CommercialInvoiceStep }
