import { COUNTRIES } from '@nv/react-commons/src/Constants'
import {
  generateFullPath,
  generateHomePath,
  generateInternationalPath,
  generatePublicPath,
  generateRootPath,
  generateUnificationPath
} from 'utils/url'
import { generateOauthPath, MAIN_ROUTES } from '../../utils/url'
import { ACCOUNT_DELETION_PATH } from 'containers/DeleteAccountModal/DeleteAccountModal.constants'

export const DEFAULT_LOCALE = 'en'
export const REHYDRATE = 'persist/REHYDRATE'

const OC = 'order-create'
const ORDER_RETURN = 'order-return'
const OC_1 = `${OC}/1`
const OC_2 = `${OC}/2`
const OC_3 = `${OC}/3`
const OC_PACKS = 'order-create-packs'
const MAPPING = 'mapping'
const SETTINGS = 'settings'
const ORDER_CORPORATE_MANUAL_AWB = 'order-corporate-manual-awb'
const TRACKING = 'tracking'

export const BASE_ROUTES = {
  ...MAIN_ROUTES,
  OC,
  OC_1,
  OC_2,
  OC_3,
  OC_PACKS,
  ORDER_CORPORATE_MANUAL_AWB,
  ORDER_RETURN,
  OC_SUMMARY: `${OC_2}/summary`,
  OC_MANUAL: `${OC_2}/manual`,
  OC_UPLOAD: `${OC_2}/upload`,
  OC_PROCESS: `${OC}-process`,
  OC_COMPLETED: `${OC}-completed`,
  OC_ERROR: `${OC_2}/edit-error`,
  OC_RETURN_ERROR: `${ORDER_RETURN}/3/edit-error`,
  OC_PACKS_ERROR: `${OC_PACKS}/2/edit-error`,
  ORDER_CORPORATE_MANUAL_AWB_ERROR: `${ORDER_CORPORATE_MANUAL_AWB}/2/edit-error`,
  OC_EDIT_ERROR: 'edit-error',
  TRACKING,
  TRACKING_SEARCH: 'search',
  TRACKING_ADVANCED: 'advanced',
  ORDER: 'order',
  ORDER_HISTORY: 'order-history',
  MAPPING,
  MAPPING_NEW: 'new',
  MAPPING_PREVIEW: ':id',
  MAPPING_EDIT: ':id/edit',
  MAPPING_EDIT_ERRORS: ':id/edit-errors',
  PICKUPS: 'pickups',
  REPORTS: 'reports',
  PAYMENTS: 'payments',
  SETTINGS,
  ACCOUNT: 'account',
  PAYMENT: 'payment',
  ADDRESS: 'addresses',
  BILLING: 'billing',
  BILLING_HISTORY: 'history',
  S_ORDER: 'order',
  NOTIFICATION: 'notifications',
  IT: 'it',
  RETURN_PARCELS: 'return-parcels',
  LOGIN: 'login',
  CONTACT_US: 'contact-us',
  SIGNUP: 'signup',
  LOGIN_V2: 'login-v2',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  COMPLETE_SIGNUP: 'complete-sign-up',
  RETAIL_SIGNUP: 'signup/retail',
  VERIFY_EMAIL_ADDRESS: 'verify-email-address',
  CONFIRM_LINK_ACCOUNT: 'confirm-link-account',
  DEBUG: 'debug',
  DASHBOARD_TOUR: 'dashboard-tour',
  DVT: 'dvt',
  SHOPIFY: 'shopify',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  FPL_OC: 'order-create',
  FPL_SERVICE_REGISTRATION: 'registration',
  FPL_OC_REQUEST_BY_BATCH: 'order-requests',
  FPL_OC_HISTORY: 'batch-history',
  FPL_PARCEL_DETAIL: 'order/:parcelId',
  FPL_HOME: 'home',
  FPL_TRACKING: 'tracking',
  FPL_TRACKING_SEARCH: 'tracking/search',
  FPL_ORDER_BATCH: 'orders/batch',
  FPL_MMCC_ORDER_BATCH: 'mmcc-orders/batch',
  FPL_ORDERS: 'orders',
  FPL_MMCC_ORDER_CREATION: 'mmcc-order-creation',
  OAUTH_LOGIN: 'login',
  OAUTH_FORGOT_PASSWORD: 'reset-password',
  OAUTH_SELECT_SHIPPER: 'select-shipper',
  VERIFY_PHONE: 'verify-phone',
  VERIFICATION: 'verification',
  PASSWORD: 'password',
  COMING_SOON: 'coming-soon',
  PROFILE: 'profile',
  TICKET_SUMMARY: 'ticket-summary',
  TOKEN_LOGIN: 'token-login',
  HELP_DESK: 'help-desk',
  SHIPPER_SUPPORT: 'shipper-support',
  SHIPPER_SUPPORT_SUCCESS: 'success',
  DELETE_ACCOUNT_SUCCESS: 'success',
  PUBLIC: 'public'
}

export const ROUTES = {
  ROOT: BASE_ROUTES.ROOT,
  HOME: BASE_ROUTES.HOME,
  PUBLIC: BASE_ROUTES.PUBLIC,
  INTERNATIONAL: BASE_ROUTES.INTERNATIONAL,
  FULL: BASE_ROUTES.FULL,
  OC_PACKS: generateFullPath(BASE_ROUTES.OC_PACKS),
  ORDER_CORPORATE_MANUAL_AWB: generateFullPath(BASE_ROUTES.ORDER_CORPORATE_MANUAL_AWB),
  ORDER_RETURN: generateFullPath(BASE_ROUTES.ORDER_RETURN),
  OC_PROCESS: generateFullPath(BASE_ROUTES.OC_PROCESS),
  OC_EDIT_ERROR: generateFullPath(BASE_ROUTES.OC_EDIT_ERROR),
  DVT: generateFullPath(BASE_ROUTES.DVT),
  TERMS_AND_CONDITIONS: generateFullPath(BASE_ROUTES.TERMS_AND_CONDITIONS),
  FPL_OC: generateInternationalPath(BASE_ROUTES.FPL_OC),
  LOGIN: generateRootPath(BASE_ROUTES.LOGIN),
  CONTACT_US: generateRootPath(BASE_ROUTES.CONTACT_US),
  SIGNUP: generateRootPath(BASE_ROUTES.SIGNUP),
  LOGIN_V2: generateRootPath(BASE_ROUTES.LOGIN_V2),
  FORGOT_PASSWORD: generateRootPath(BASE_ROUTES.FORGOT_PASSWORD),
  COMPLETE_SIGNUP: generateRootPath(BASE_ROUTES.COMPLETE_SIGNUP),
  RETAIL_SIGNUP: generateRootPath(BASE_ROUTES.RETAIL_SIGNUP),
  OAUTH_LOGIN: generateOauthPath(BASE_ROUTES.OAUTH_LOGIN),
  OAUTH_FORGOT_PASSWORD: generateOauthPath(BASE_ROUTES.OAUTH_FORGOT_PASSWORD),
  RESET_PASSWORD: generateRootPath(BASE_ROUTES.RESET_PASSWORD),
  SHIPPER_SUPPORT_PUBLIC: generatePublicPath(BASE_ROUTES.SHIPPER_SUPPORT),
  SHIPPER_SUPPORT_SUCCESS_PUBLIC: generatePublicPath(
    `${BASE_ROUTES.SHIPPER_SUPPORT}/${BASE_ROUTES.SHIPPER_SUPPORT_SUCCESS}`
  ),
  VERIFY_EMAIL_ADDRESS: generateRootPath(BASE_ROUTES.VERIFY_EMAIL_ADDRESS),
  CONFIRM_LINK_ACCOUNT: generateRootPath(BASE_ROUTES.CONFIRM_LINK_ACCOUNT),
  FPL_OC_REQUEST_BY_BATCH: generateInternationalPath(BASE_ROUTES.FPL_OC_REQUEST_BY_BATCH),
  FPL_OC_HISTORY: generateInternationalPath(BASE_ROUTES.FPL_OC_HISTORY),
  FPL_PARCEL_DETAIL: generateInternationalPath(BASE_ROUTES.FPL_PARCEL_DETAIL),
  FPL_HOME: generateInternationalPath(BASE_ROUTES.FPL_HOME),
  FPL_TRACKING: generateInternationalPath(BASE_ROUTES.FPL_TRACKING),
  FPL_TRACKING_SEARCH: generateInternationalPath(BASE_ROUTES.FPL_TRACKING_SEARCH),
  FPL_ORDER_BATCH: generateInternationalPath(BASE_ROUTES.FPL_ORDER_BATCH),
  FPL_MMCC_ORDER_BATCH: generateInternationalPath(BASE_ROUTES.FPL_MMCC_ORDER_BATCH),
  FPL_MMCC_ORDER_CREATION: generateInternationalPath(BASE_ROUTES.FPL_MMCC_ORDER_CREATION),
  OC_COMPLETED: generateHomePath(BASE_ROUTES.OC_COMPLETED),
  ORDER: generateHomePath(BASE_ROUTES.ORDER),
  ORDER_HISTORY: generateHomePath(BASE_ROUTES.ORDER_HISTORY),
  RETURN_PARCELS: generateHomePath(BASE_ROUTES.RETURN_PARCELS),
  PICKUPS: generateHomePath(BASE_ROUTES.PICKUPS),
  REPORTS: generateHomePath(BASE_ROUTES.REPORTS),
  PAYMENTS: generateHomePath(BASE_ROUTES.PAYMENTS),
  SETTINGS: generateHomePath(BASE_ROUTES.SETTINGS),
  ACCOUNT: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.ACCOUNT}`),
  DELETE_ACCOUNT_SUCCESS: generateHomePath(
    `${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.ACCOUNT}/${ACCOUNT_DELETION_PATH}/${BASE_ROUTES.DELETE_ACCOUNT_SUCCESS}`
  ),
  PAYMENT: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.PAYMENT}`),
  ADDRESS: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.ADDRESS}`),
  BILLING: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.BILLING}`),
  BILLING_HISTORY: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.BILLING}/${BASE_ROUTES.BILLING_HISTORY}`),
  S_ORDER: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.S_ORDER}`),
  NOTIFICATION: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.NOTIFICATION}`),
  IT: generateHomePath(`${BASE_ROUTES.SETTINGS}/${BASE_ROUTES.IT}`),
  OAUTH_SELECT_SHIPPER: generateOauthPath(BASE_ROUTES.OAUTH_SELECT_SHIPPER),
  OC: generateFullPath(BASE_ROUTES.OC),
  OC_1: generateFullPath(BASE_ROUTES.OC_1),
  OC_2: generateFullPath(BASE_ROUTES.OC_2),
  OC_3: generateFullPath(BASE_ROUTES.OC_3),
  OC_SUMMARY: generateFullPath(BASE_ROUTES.OC_SUMMARY),
  OC_MANUAL: generateFullPath(BASE_ROUTES.OC_MANUAL),
  OC_UPLOAD: generateFullPath(BASE_ROUTES.OC_UPLOAD),
  OC_ERROR: generateFullPath(BASE_ROUTES.OC_ERROR),
  OC_RETURN_ERROR: generateFullPath(BASE_ROUTES.OC_RETURN_ERROR),
  OC_PACKS_ERROR: generateFullPath(BASE_ROUTES.OC_PACKS_ERROR),
  ORDER_CORPORATE_MANUAL_AWB_ERROR: generateFullPath(BASE_ROUTES.ORDER_CORPORATE_MANUAL_AWB_ERROR),
  TRACKING: generateHomePath(BASE_ROUTES.TRACKING),
  TRACKING_SEARCH: generateHomePath(`${BASE_ROUTES.TRACKING}/${BASE_ROUTES.TRACKING_SEARCH}`),
  TRACKING_ADVANCED: generateHomePath(`${BASE_ROUTES.TRACKING}/${BASE_ROUTES.TRACKING_ADVANCED}`),
  MAPPING: generateFullPath(BASE_ROUTES.MAPPING),
  MAPPING_NEW: generateFullPath(`${BASE_ROUTES.MAPPING}/${BASE_ROUTES.MAPPING_NEW}`),
  MAPPING_PREVIEW: generateFullPath(`${BASE_ROUTES.MAPPING}/${BASE_ROUTES.MAPPING_PREVIEW}`),
  MAPPING_EDIT: generateFullPath(`${BASE_ROUTES.MAPPING}/${BASE_ROUTES.MAPPING_EDIT}`),
  DEBUG: BASE_ROUTES.DEBUG,
  SHOPIFY: generateHomePath(BASE_ROUTES.SHOPIFY),
  DASHBOARD_TOUR: BASE_ROUTES.DASHBOARD_TOUR,
  FPL_ORDERS: generateInternationalPath(BASE_ROUTES.FPL_ORDERS),
  FPL_SERVICE_REGISTRATION: generateInternationalPath(BASE_ROUTES.FPL_SERVICE_REGISTRATION),
  UNIFIED_LOGIN: generateUnificationPath(BASE_ROUTES.LOGIN),
  VERIFY_PHONE: generateUnificationPath(`${BASE_ROUTES.LOGIN}/${BASE_ROUTES.VERIFY_PHONE}`),
  UNIFIED_FORGOT_PASSWORD: generateUnificationPath(BASE_ROUTES.FORGOT_PASSWORD),
  UNIFIED_SIGNUP: generateUnificationPath(BASE_ROUTES.SIGNUP),
  UNIFIED_COMING_SOON: generateUnificationPath(`${BASE_ROUTES.SIGNUP}/${BASE_ROUTES.COMING_SOON}`),
  SIGNUP_VERIFICATION: generateUnificationPath(`${BASE_ROUTES.SIGNUP}/${BASE_ROUTES.VERIFICATION}`),
  SIGNUP_PASSWORD: generateUnificationPath(`${BASE_ROUTES.SIGNUP}/${BASE_ROUTES.PASSWORD}`),
  SIGNUP_PROFILE: generateUnificationPath(`${BASE_ROUTES.SIGNUP}/${BASE_ROUTES.PROFILE}`),
  TOKEN_LOGIN: generateUnificationPath(BASE_ROUTES.TOKEN_LOGIN),
  HELP_DESK: generateHomePath(BASE_ROUTES.HELP_DESK),
  SHIPPER_SUPPORT: generateHomePath(`${BASE_ROUTES.HELP_DESK}/${BASE_ROUTES.SHIPPER_SUPPORT}`),
  SHIPPER_SUPPORT_SUCCESS: generateHomePath(
    `${BASE_ROUTES.HELP_DESK}/${BASE_ROUTES.SHIPPER_SUPPORT}/${BASE_ROUTES.SHIPPER_SUPPORT_SUCCESS}`
  ),
  TICKET_SUMMARY: generateHomePath(`${BASE_ROUTES.HELP_DESK}/${BASE_ROUTES.TICKET_SUMMARY}`)
}

export const ORDER = {
  STATUS: {
    STAGING: 'Staging',
    PENDING: 'Pending',
    PICKUP_FAIL: 'Pickup fail',
    TRANSIT: 'Transit',
    DELIVERY_FAIL: 'Delivery fail',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    ON_HOLD: 'On Hold'
  },
  GRANULAR_STATUS: {
    STAGING: 'Staging',
    PENDING_PICKUP: 'Pending Pickup',
    PENDING_PICKUP_AT_DISTRIBUTION_POINT: 'Pending Pickup at Distribution Point',
    VAN_EN_ROUTE_TO_PICKUP: 'Van en-route to pickup',
    EN_ROUTE_TO_SORTING_HUB: 'En-route to Sorting Hub',
    PICKUP_FAIL: 'Pickup fail',
    ARRIVED_AT_SORTING_HUB: 'Arrived at Sorting Hub',
    ON_VEHICLE_FOR_DELIVERY: 'On Vehicle for Delivery',
    COMPLETED: 'Completed',
    PENDING_RESCHEDULE: 'Pending Reschedule',
    TRANSFERRED_TO_3_PL: 'Transferred to 3PL',
    RETURNED_TO_SENDER: 'Returned to Sender',
    CANCELLED: 'Cancelled',
    ARRIVED_AT_DISTRIBUTION_POINT: 'Arrived at Distribution Point',
    ARRIVED_AT_ORIGIN_HUB: 'Arrived at Origin Hub',
    CROSS_BORDER_TRANSIT: 'Cross Border Transit',
    CUSTOMS_CLEARED: 'Customs Cleared',
    CUSTOMS_HELD: 'Customs Held'
  }
}

export const JOB = {
  TYPE: {
    PICKUP: 'PP',
    DELIVERY: 'DD'
  },
  STATUS: {
    PENDING: 'Pending',
    FAIL: 'Fail',
    SUCCESS: 'Success'
  }
}

export const SHIPPER_TYPE = {
  PREMIUM_SAMEDAY: 'PREMIUM_SAMEDAY',
  PREMIUM: 'PREMIUM',
  STANDARD_SAMEDAY: 'STANDARD_SAMEDAY',
  STANDARD: 'STANDARD'
}

export const DASH_SHIPPER_TYPE = {
  LITE: 'lite',
  PRO: 'pro'
}

export const OC_SERVICES_AVAILABLE = {
  SAMEDAY_ORDER: 'SAMEDAY'
}

export const OC_SERVICE_TYPES = {
  PARCEL: 'Parcel',
  DOCUMENT: 'Document',
  MARKETPLACE: 'Marketplace',
  INTERNATIONAL: 'International',
  NINJA_PACKS: 'Ninja Pack',
  RETURN: 'Return',
  CORPORATE: 'Corporate',
  CORPORATE_RETURN: 'Corporate Return',
  CORPORATE_AWB: 'Corporate AWB',
  CORPORATE_DOCUMENT: 'Corporate Document',
  MARKETPLACE_INTERNATIONAL: 'Marketplace International',
  MARKETPLACE_SORT: 'Marketplace Sort',
  INTERNATIONAL_RETURN: 'International Return',
  CORPORATE_INTERNATIONAL: 'Corporate International',
  RELABEL: 'Relabel',
  MULTI_PIECE_SHIPMENT: 'Multi Piece Shipment',
  MARKETPLACE_RETURN: 'Marketplace Return',
  B2B_BUNDLE: 'B2B Bundle',
  CORPORATE_B2B_BUNDLE: 'Corporate B2B Bundle'
}

export const ORDER_DETAIL_SERVICE_TYPE = {
  PARCEL: 'PARCEL',
  DOCUMENT: 'DOCUMENT',
  MARKETPLACE: 'MARKETPLACE',
  INTERNATIONAL: 'INTERNATIONAL',
  NINJA_PACK: 'NINJA_PACK',
  RETURN: 'RETURN',
  CORPORATE: 'CORPORATE',
  CORPORATE_RETURN: 'CORPORATE_RETURN',
  CORPORATE_AWB: 'CORPORATE_AWB',
  CORPORATE_DOCUMENT: 'CORPORATE_DOCUMENT',
  MARKETPLACE_INTERNATIONAL: 'MARKETPLACE_INTERNATIONAL',
  MARKETPLACE_SORT: 'MARKETPLACE_SORT',
  INTERNATIONAL_RETURN: 'INTERNATIONAL_RETURN',
  CORPORATE_INTERNATIONAL: 'CORPORATE_INTERNATIONAL',
  RELABEL: 'RELABEL',
  MULTI_PIECE_SHIPMENT: 'MULTI_PIECE_SHIPMENT',
  MARKETPLACE_RETURN: 'MARKETPLACE_RETURN',
  B2B_BUNDLE: 'B2B_BUNDLE',
  CORPORATE_B2B_BUNDLE: 'CORPORATE_B2B_BUNDLE'
}

export const B2B_BUNDLE_TYPES = [
  ORDER_DETAIL_SERVICE_TYPE.B2B_BUNDLE,
  ORDER_DETAIL_SERVICE_TYPE.CORPORATE_B2B_BUNDLE,
  ORDER_DETAIL_SERVICE_TYPE.MULTI_PIECE_SHIPMENT
]

export const PICKUP_SERVICES_TYPES = {
  SCHEDULED: 'Scheduled'
}

export const EVENTS = {
  ADDED_TO_SHIPMENT: 'ADDED_TO_SHIPMENT',
  ARRIVED_AT_DESTINATION_HUB: 'ARRIVED_AT_DESTINATION_HUB',
  ARRIVED_AT_ORIGIN_HUB: 'ARRIVED_AT_ORIGIN_HUB',
  ARRIVED_AT_TRANSIT_HUB: 'ARRIVED_AT_TRANSIT_HUB',
  CANCEL: 'CANCEL',
  DELIVERY_FAILURE: 'DELIVERY_FAILURE',
  DELIVERY_SUCCESS: 'DELIVERY_SUCCESS',
  DRIVER_INBOUND_SCAN: 'DRIVER_INBOUND_SCAN',
  DRIVER_PICKUP_SCAN: 'DRIVER_PICKUP_SCAN',
  FORCED_SUCCESS: 'FORCED_SUCCESS',
  FROM_DP_TO_CUSTOMER: 'FROM_DP_TO_CUSTOMER',
  FROM_DP_TO_DRIVER: 'FROM_DP_TO_DRIVER',
  FROM_DRIVER_TO_DP: 'FROM_DRIVER_TO_DP',
  FROM_SHIPPER_TO_DP: 'FROM_SHIPPER_TO_DP',
  HUB_INBOUND_SCAN: 'HUB_INBOUND_SCAN',
  PARCEL_ROUTING_SCAN: 'PARCEL_ROUTING_SCAN',
  RESCHEDULE: 'RESCHEDULE',
  RESUME: 'RESUME',
  ROUTE_INBOUND_SCAN: 'ROUTE_INBOUND_SCAN',
  RTS: 'RTS',
  TRANSFERRED_TO_THIRD_PARTY: 'TRANSFERRED_TO_THIRD_PARTY',
  UPDATE_CASH: 'UPDATE_CASH'
}

export const SHOPIFY_ENABLED_COUNTRIES = [COUNTRIES.PH, COUNTRIES.SG, COUNTRIES.MY]
export const OAUTH_ROUTES = [ROUTES.OAUTH_FORGOT_PASSWORD, ROUTES.OAUTH_LOGIN, ROUTES.OAUTH_SELECT_SHIPPER]

export const CAMPAIGN_ACTIVE_STATUS = 'Active'
