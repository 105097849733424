import React, { useCallback, useContext, useState } from 'react'
import { Address, TimeSlotProps } from './types'
import { StyledCustomCard, VspaceWrapper } from 'containers/FPLOrderCreate/styles'
import { AddressSelection } from './AddressSelection'
import { SERVICE_SUB_STEPS } from './constants'
import { OCSubStep } from 'components/OCSubStep'
import { PickupType } from './PickupType'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { ServiceSelection } from './ServiceSelection'
import { Volumes } from './Volumes'
import { CCTypeSelection } from './CCTypeSelection'
import { CustomCurrency } from '../FPLOrderCreate/CustomCurrency'
import { CURRENY_COUNTRY_LABELS, GOODS_CURRENCY_DATA } from '../FPLOrderCreate/constants'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { TimeSlot } from './TimeSlot'

interface State {
  currentSubStep?: string
}

const initialState: State = {
  currentSubStep: undefined
}

const ServiceDetailsStep = () => {
  const [state, setState] = useState<State>(initialState)
  const { currentSubStep } = state
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    senderAddresses = [],
    recipientAddresses = [],
    pickupAddresses = [],

    senderAddress,
    recipientAddress,
    pickupAddress,

    pickupType,
    isAddressLoading,
    volume,

    timeSlot,
    timeSlots = []
  } = ocState
  const { origin_country: originCountry, destination_country: destinationCountry } = ocState.selectedService
  const customCurrency = CURRENY_COUNTRY_LABELS[destinationCountry] || GOODS_CURRENCY_DATA.USD

  const getStatusOfStep = useCallback(
    step => {
      return currentSubStep === step ? OCSubStep.OPEN : OCSubStep.CLOSE
    },
    [currentSubStep]
  )

  const cancelEdit = () => {
    setState(preState => ({ ...preState, currentSubStep: undefined }))
  }

  const editSubStep = (type: string) => {
    setState(preState => ({ ...preState, currentSubStep: type }))
  }

  const closeSubStep = () => {
    setState(prevState => ({ ...prevState, currentSubStep: OCSubStep.CLOSE }))
  }

  const getFieldByType = type => {
    let singleAddressField = ''
    let listAddressField = ''
    let addressIndication = null
    let listAddressIndication = []
    switch (type) {
      case SERVICE_SUB_STEPS.SENDER_ADDRESS:
        singleAddressField = 'senderAddress'
        listAddressField = 'senderAddresses'
        addressIndication = senderAddress
        listAddressIndication = senderAddresses
        break
      case SERVICE_SUB_STEPS.RECIPIENT_ADDRESS:
        singleAddressField = 'recipientAddress'
        listAddressField = 'recipientAddresses'
        addressIndication = recipientAddress
        listAddressIndication = recipientAddresses
        break
      case SERVICE_SUB_STEPS.PICKUP_ADDRESS:
        singleAddressField = 'pickupAddress'
        listAddressField = 'pickupAddresses'
        addressIndication = pickupAddress
        listAddressIndication = pickupAddresses
        break
    }
    return {
      singleAddressField,
      listAddressField,
      addressIndication,
      listAddressIndication
    }
  }

  const handleChangeSelectedAddress = (selectedAddress: Address, type) => {
    const { singleAddressField } = getFieldByType(type)
    updateOCState({ [singleAddressField]: selectedAddress })
    closeSubStep()
  }

  const deleteAddress = (deletedAddress: Address, type) => {
    const { singleAddressField, listAddressField, addressIndication, listAddressIndication } = getFieldByType(type)

    let updatedSenderAddress = addressIndication
    const updatedSenderAddreses = listAddressIndication.filter(add => add.id !== deletedAddress.id)
    if (deletedAddress.id === addressIndication?.id) {
      updatedSenderAddress = undefined
    }

    updateOCState({
      [singleAddressField]: updatedSenderAddress,
      [listAddressField]: updatedSenderAddreses
    })
  }

  const editAddress = (address, type) => {
    const { singleAddressField, listAddressField, addressIndication, listAddressIndication } = getFieldByType(type)

    const updatedAddresses = listAddressIndication.map(add => {
      if (add.id === address.id) {
        return address
      }
      return add
    })
    let updatedAddress = addressIndication
    if (address.id === addressIndication?.id) {
      updatedAddress = address
    }
    updateOCState({
      [singleAddressField]: updatedAddress,
      [listAddressField]: updatedAddresses
    })
  }

  const editTimeslot = (editedTimeslot: TimeSlotProps) => {
    const updatedTimeslots = timeSlots.map(slot => {
      if (slot.id === editedTimeslot.id) {
        return editedTimeslot
      }
      return slot
    })
    let updatedTimeslot = timeSlot
    if (editedTimeslot.id === timeSlot?.id) {
      updatedTimeslot = editedTimeslot
    }
    updateOCState({
      timeSlot: updatedTimeslot,
      timeSlots: updatedTimeslots
    })
  }

  const changeTimeslot = (timeslot: TimeSlotProps) => {
    updateOCState({ timeSlot: timeslot })
    closeSubStep()
  }

  const deleteTimeslot = (deletedTimeslot: TimeSlotProps) => {
    let updatedTimeslot = timeSlot
    const updatedTimeslots = timeSlots.filter(slot => slot.id !== deletedTimeslot.id)
    if (deletedTimeslot.id === timeSlot?.id) {
      updatedTimeslot = undefined
    }

    updateOCState({
      timeSlot: updatedTimeslot,
      timeSlots: updatedTimeslots
    })
  }

  return (
    <>
      <StyledCustomCard hoverable={false}>
        <ServiceSelection
          status={getStatusOfStep(SERVICE_SUB_STEPS.SERVICE)}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.SERVICE)}
          onCancel={cancelEdit}
          onSave={closeSubStep}
        />
        <VspaceWrapper height={16} />
        <CCTypeSelection
          status={getStatusOfStep(SERVICE_SUB_STEPS.CUSTOMS_CLEARANCE_TYPE)}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.CUSTOMS_CLEARANCE_TYPE)}
          onCancel={cancelEdit}
          onSave={closeSubStep}
        />
        <VspaceWrapper height={16} />
        <AddressSelection
          title='sender_info'
          country={originCountry}
          addresses={senderAddresses}
          selectedAddress={senderAddress}
          isLoading={isAddressLoading}
          status={getStatusOfStep(SERVICE_SUB_STEPS.SENDER_ADDRESS)}
          onCancel={cancelEdit}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.SENDER_ADDRESS)}
          onEditAddress={(data: Address) => editAddress(data, SERVICE_SUB_STEPS.SENDER_ADDRESS)}
          onAddAddress={(data: Address) => {
            updateOCState({ senderAddresses: [...senderAddresses, data] })
          }}
          onChangeSelectedAddress={(data: Address) =>
            handleChangeSelectedAddress(data, SERVICE_SUB_STEPS.SENDER_ADDRESS)
          }
          onDeleteAddress={(data: Address) => deleteAddress(data, SERVICE_SUB_STEPS.SENDER_ADDRESS)}
        />
        <VspaceWrapper height={16} />
        <AddressSelection
          title='recipient_info_in_short'
          country={destinationCountry}
          addresses={recipientAddresses}
          selectedAddress={recipientAddress}
          isLoading={isAddressLoading}
          status={getStatusOfStep(SERVICE_SUB_STEPS.RECIPIENT_ADDRESS)}
          onCancel={cancelEdit}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.RECIPIENT_ADDRESS)}
          onEditAddress={(data: Address) => editAddress(data, SERVICE_SUB_STEPS.RECIPIENT_ADDRESS)}
          onAddAddress={(data: Address) => {
            updateOCState({ recipientAddresses: [...recipientAddresses, data] })
          }}
          onChangeSelectedAddress={(data: Address) =>
            handleChangeSelectedAddress(data, SERVICE_SUB_STEPS.RECIPIENT_ADDRESS)
          }
          onDeleteAddress={(data: Address) => deleteAddress(data, SERVICE_SUB_STEPS.RECIPIENT_ADDRESS)}
        />
        <VspaceWrapper height={16} />
        <PickupType
          status={getStatusOfStep(SERVICE_SUB_STEPS.PICKUP_TYPE)}
          onCancel={cancelEdit}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.PICKUP_TYPE)}
          onSave={data => {
            closeSubStep()
            updateOCState({ pickupType: data })
          }}
          pickupType={pickupType}
        />
        {ocState.pickupType === PICKUP_TYPES.SCHEDULED && (
          <>
            <VspaceWrapper height={16} />
            <AddressSelection
              title='pickup.pickup_address'
              country={originCountry}
              addresses={pickupAddresses}
              selectedAddress={pickupAddress}
              isLoading={isAddressLoading}
              status={getStatusOfStep(SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
              onCancel={cancelEdit}
              onEdit={() => editSubStep(SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
              onEditAddress={(data: Address) => editAddress(data, SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
              onAddAddress={(data: Address) => {
                updateOCState({ pickupAddresses: [...pickupAddresses, data] })
              }}
              onChangeSelectedAddress={(data: Address) =>
                handleChangeSelectedAddress(data, SERVICE_SUB_STEPS.PICKUP_ADDRESS)
              }
              onDeleteAddress={(data: Address) => deleteAddress(data, SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
            />
            <VspaceWrapper height={16} />
            <TimeSlot
              selectedTimeslot={timeSlot}
              timeslots={timeSlots}
              country={originCountry}
              status={getStatusOfStep(SERVICE_SUB_STEPS.TIME)}
              onEdit={() => editSubStep(SERVICE_SUB_STEPS.TIME)}
              onCancel={cancelEdit}
              onAddTimeslot={(data: TimeSlotProps) => {
                updateOCState({ timeSlots: [...timeSlots, data] })
              }}
              onChangeTimeslot={changeTimeslot}
              onDeleteTimeslot={deleteTimeslot}
              onEditTimeslot={data => editTimeslot(data)}
            />
            <VspaceWrapper height={16} />
            <Volumes
              selectedVolume={volume}
              status={getStatusOfStep(SERVICE_SUB_STEPS.VOLUMES)}
              onEdit={() => editSubStep(SERVICE_SUB_STEPS.VOLUMES)}
              onCancel={cancelEdit}
              onSave={data => {
                closeSubStep()
                updateOCState({ volume: data })
              }}
            />
          </>
        )}
        <VspaceWrapper height={16} />
        {customCurrency && (
          <CustomCurrency
            currency={customCurrency}
            status={OCSubStep.CLOSE}
            onCancel={cancelEdit}
            onSave={closeSubStep}
          />
        )}
      </StyledCustomCard>
    </>
  )
}

export { ServiceDetailsStep }
