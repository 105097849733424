import { OCForm } from 'components/OCForm'
import moment from 'moment'

const mapString = value => value && value.toString()
const mapFloat = value => (isNaN(value) ? undefined : parseFloat(value))
const mapDeliveryDate = value => moment(value).format('YYYY-MM-DD')
export const transformSize = size => {
  return global.isNaN(size) ? size : OCForm.SIZE_OPTS[parseInt(size)]
}

const makeName = () => ({
  key: 'name',
  label: 'NAME',
  mappedTo: 'to.name',
  mappingFunction: mapString
})
const makeAddress1 = () => ({
  key: 'address1',
  label: 'ADDRESS 1',
  mappedTo: 'to.address.address1',
  mappingFunction: mapString
})
const makeAddress2 = () => ({
  key: 'address2',
  label: 'ADDRESS 2',
  mappedTo: 'to.address.address2',
  mappingFunction: mapString
})
const makeEmail = () => ({
  label: 'EMAIL',
  key: 'email',
  mappedTo: 'to.email',
  mappingFunction: mapString
})
const makePhoneNumber = () => ({
  key: 'phoneNumber',
  label: 'CONTACT',
  mappedTo: 'to.phoneNumber',
  mappingFunction: mapString
})
const makePostcode = () => ({
  key: 'postcode',
  label: 'POSTCODE',
  mappedTo: 'to.address.postcode',
  mappingFunction: mapString
})
const makeCountry = ({ country }) => ({
  label: 'COUNTRY',
  key: 'country',
  mappedTo: 'to.address.country',
  defaultValue: country, // Default to the shipper's country,
  mappingFunction: mapString
})
const makeCOD = () => ({
  key: 'cod',
  label: 'CASH ON DELIVERY',
  mappedTo: 'parcelJob.cashOnDelivery',
  mappingFunction: mapFloat
})

const makeShipperOrderNumber = () => ({
  key: 'externalOrderId',
  label: 'SHIPPER ORDER NO',
  mappedTo: 'reference.merchantOrderNumber',
  mappingFunction: mapString
})

const makeLocality = ({ addressInfo: { locality } }) => ({
  key: locality,
  label: locality.toUpperCase(),
  mappedTo: `to.address.${locality}`,
  mappingFunction: mapString
})
const makeRegion = ({ addressInfo: { region } }) => ({
  key: region,
  label: region.toUpperCase(),
  mappedTo: `to.address.${region}`,
  mappingFunction: mapString
})

const makeExternalOrderId = () => ({
  key: 'externalOrderId',
  label: 'SHOPIFY ORDER ID',
  mappedTo: 'externalOrderId',
  mappingFunction: mapString
})

const makeSize = () => ({
  key: 'size',
  defaultValue: 'S',
  label: 'SIZE',
  mappedTo: 'parcelJob.dimensions.size',
  mappingFunction: transformSize
})

const makeWeight = () => ({
  key: 'weight',
  defaultValue: 1,
  label: 'WEIGHT',
  mappedTo: 'parcelJob.dimensions.weight',
  mappingFunction: mapFloat
})

export const makeDeliveryTimeslot = () => ({
  key: 'order.deliveryTimeslot',
  label: 'DELIVERY TIME SLOT',
  mappedTo: 'parcelJob.deliveryTimeslot',
  defaultValue: {
    /* eslint-disable camelcase */
    start_time: '09:00',
    end_time: '22:00',
    /* eslint-enable camelcase */
    timezone: 'Asia/Singapore'
  }
})

export const makeDeliveryStartDate = ({ rsvn }) => ({
  label: 'DELIVERY DATE',
  mappedTo: 'parcelJob.deliveryStartDate',
  defaultValue: moment(rsvn?.readyDatetime)
    .add(1, 'day')
    .format('YYYY-MM-DD'),
  mappingFunction: mapDeliveryDate
})

export const makeDeliveryType = () => ({
  key: 'serviceLevel',
  label: 'DELIVERY TYPE',
  mappedTo: 'serviceLevel',
  defaultValue: 'STANDARD'
})

export const makeRequestedTrackingNumber = () => ({
  key: 'requestedTrackingNumber',
  mappedTo: 'requestedTrackingNumber',
  mappingFunction: mapString
})

export const makeInsuredValue = () => ({
  key: 'cod',
  mappedTo: 'parcelJob.insuredValue',
  mappingFunction: mapFloat
})

const makeLineItems = () => ({
  key: 'lineItems',
  mappedTo: 'parcelJob.deliveryInstructions',
  defaultValue: '',
  mappingFunction: mapString
})

export const normalFields = [
  makeName,
  makeAddress1,
  makeAddress2,
  makeEmail,
  makePhoneNumber,
  makePostcode,
  makeCountry,
  makeCOD,
  makeShipperOrderNumber,
  makeSize,
  makeWeight,
  makeExternalOrderId,
  makeDeliveryTimeslot,
  makeDeliveryStartDate,
  makeDeliveryType,
  makeRequestedTrackingNumber,
  makeInsuredValue,
  makeLineItems
]

export const addressFields = [makeLocality, makeRegion]
