import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'hooks/common'
import { ExcelUtils, FileUtils } from '@nv/react-commons/src/Utils'
import { Vspace } from 'components/Vspace'
import { CircleIcon } from 'components/CircleIcon'
import { faCloudUpload } from '@fa-pro-light/faCloudUpload'
import { Menu, T } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { DropZone } from 'containers/FPLAllOrders/DropZone'
import { InlineErrorMessage } from 'containers/FPLAllOrders/InlineErrorMessage'
import { ItemButton, StyledCenterText, StyledTextInline } from '../FPLOrderCreate/styles'
import { friendlyErrMessage } from '../FPLOrderCreate/dataUtils'
import { Dropdown } from 'antd/lib'
import { generateTemplate, verifyFileExtensionAndTemplate } from './utils'

const initialState = {
  fileName: '',
  errorMessages: [],
  isLoadingFile: false
}

const BulkUploadZone = ({ onSave }) => {
  const [state, setState] = useState(initialState)
  const intl = useIntl()

  const { fileName, errorMessages, isLoadingFile } = state

  const { headerFields, dataFields } = generateTemplate()

  const handleUploadFile = async file => {
    setState(prevState => ({ ...prevState, isLoadingFile: true }))
    if (!file) {
      setState({
        isLoadingFile: false,
        fileName: undefined,
        errorMessages: [intl.formatMessage({ id: 'international_advanced_search_wrong_format' })]
      })
      return
    }

    const { fileData, errorMsg, fileName: name } = await verifyFileExtensionAndTemplate(file)
    if (fileData.data.length === 0) {
      setState({
        isLoadingFile: false,
        fileName: undefined,
        errorMessages: [intl.formatMessage({ id: 'international_advanced_search_wrong_format' })]
      })
      return
    }

    setState({
      fileName: name,
      isLoadingFile: false,
      errorMessages: friendlyErrMessage(intl, errorMsg)
    })

    if (!errorMsg.length) {
      onSave({ filename: name, data: fileData.data })
    }
  }

  const handleDownloadExcel = () => {
    ExcelUtils.downloadFileWithHeaders(dataFields, [headerFields], 'international_mccc_sample.xlsx')
  }

  const handleDownloadCsv = () => {
    FileUtils.download(
      FileUtils.buildCSVURI({
        fields: headerFields,
        data: dataFields
      }),
      { filename: 'international_mccc_sample.csv' }
    )
  }

  const renderMenu = () => {
    return (
      <Menu>
        <Menu.Item key='xb-bulk-oc-csv'>
          <ItemButton data-test-id='download-international-sample' onClick={handleDownloadCsv}>
            <T id='international_csv_templates' />
          </ItemButton>
        </Menu.Item>
        <Menu.Item key='xb-bulk-oc-xlx'>
          <ItemButton onClick={handleDownloadExcel}>
            <T id='international_excel_templates' />
          </ItemButton>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <>
      <DropZone
        data-analyticsid='browseFilesForBulkOC'
        height={200}
        onDrop={handleUploadFile}
        hint={
          <>
            <CircleIcon icon={faCloudUpload} size='big' type='success' gap={1} />
            <Vspace height={16} />
            <StyledCenterText size={16} id='upload_csv_xls' />
          </>
        }
      />
      <InlineErrorMessage isLoading={isLoadingFile} errorMessages={errorMessages} fileName={fileName} />
      <>
        <Vspace height={16} />
        <Text>
          <T id='international_noted' />:
        </Text>
        <StyledTextInline style={{ display: 'flex' }}>
          <Text>
            <T id='international_bulk_upload_note_description' />
            ,&nbsp;
          </Text>
          <Dropdown
            style={{ height: '40px', width: '200px' }}
            overlay={renderMenu()}
            placement='bottomLeft'
            trigger={['click']}
          >
            <a data-analyticsid='downloadCSVMappingTemplate'>
              <T id='international_template_download' />
            </a>
          </Dropdown>
        </StyledTextInline>
      </>
    </>
  )
}

BulkUploadZone.propTypes = {
  onSave: PropTypes.func
}

export { BulkUploadZone }
