import React, { useState } from 'react'
import { lowerCase } from 'lodash'
import { OCSubStep } from 'components/OCSubStep'
import { VolumePicker } from 'components/VolumePicker'
import { useIntl } from 'hooks/common'
import { T } from '@nv/react-commons/src/Components'
import { faHandHoldingBox } from '@fa-pro-light/faHandHoldingBox'
import { VolumesProps } from './types'
import { StyledVolumePicker } from './styles'

export const Volumes = ({ onCancel, onSave, onEdit, status, selectedVolume }: VolumesProps) => {
  const [internalSelectedVolume, setInternalSelectedVolume] = useState(selectedVolume)

  const intl = useIntl()

  const handleChangeVolumePicker = e => {
    setInternalSelectedVolume(e.target.value)
  }

  const handleSaveVolume = () => {
    onSave(internalSelectedVolume)
  }

  const renderActive = () => {
    return (
      <StyledVolumePicker
        onChange={handleChangeVolumePicker}
        defaultValue={selectedVolume}
        data-analyticsid='4plChangeOCPickupVolume'
      />
    )
  }

  const renderPreview = () => {
    const translatedVolume = lowerCase(intl.formatMessage({ id: VolumePicker.keyToProps[selectedVolume].label }))
    return <T id='boxes_fit_into_x' values={{ x: translatedVolume }} />
  }

  return (
    <OCSubStep
      status={status}
      title={intl.formatMessage({ id: 'volume' })}
      icon={faHandHoldingBox}
      renderActive={renderActive}
      renderPreview={renderPreview}
      onSave={handleSaveVolume}
      onCancel={onCancel}
      onEdit={onEdit}
    />
  )
}
