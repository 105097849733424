import { COUNTRIES } from '@nv/react-commons/src/Constants'
import Config from 'configs'

export const GUIDELINE_LINKS = {
  [COUNTRIES.SG]: {
    en: `${Config.WEBSITE_URL}/files/en-sg/packaging-guidelines.pdf`
  },
  [COUNTRIES.MY]: {
    en: `${Config.WEBSITE_URL}/files/en-my/packaging-guidelines.pdf`
  },
  [COUNTRIES.TH]: {
    en: `${Config.WEBSITE_URL}/files/en-th/packaging-guidelines.pdf`,
    th: `${Config.WEBSITE_URL}/files/th-th/packaging-guidelines.pdf`
  },
  [COUNTRIES.VN]: {
    en: `${Config.WEBSITE_URL}/files/en-vn/packaging-guidelines.pdf`,
    vi: `${Config.WEBSITE_URL}/files/vi-vn/packaging-guidelines.pdf`
  },
  [COUNTRIES.ID]: {
    en: `${Config.WEBSITE_URL}/files/en-id/packaging-guidelines.pdf`,
    id: `${Config.WEBSITE_URL}/files/id-id/packaging-guidelines.pdf`
  },
  [COUNTRIES.PH]: {
    en: `${Config.WEBSITE_URL}/files/en-ph/packaging-guidelines.pdf`,
    tl: `${Config.WEBSITE_URL}/files/tl-ph/packaging-guidelines.pdf`
  }
}
export const SUPPORTED_COUNTRIES = Array.from(Object.keys(GUIDELINE_LINKS))
