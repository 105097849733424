import { ApiHelper, nvCall } from '@nv/react-commons/src/Services'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { orderCreateCreators, orderCreateTypes } from 'containers/Base/redux'
import { ORDER_CREATE_STATUS, POLLING } from 'containers/OrderCreate/constants'
import { selectIsCreatingShopifyOrders } from 'containers/OrderCreate/selectors'
import _ from 'lodash'
import { delay } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import dashApi from 'services/api/dashApi'
import uuid from 'utils/uuid'

import { createOrderPayload } from 'utils/createOrderPayload'
import { ROUTES } from '../Base/constants'
import { DASH_ERRORS } from 'constants/errors'
import { toCamelCase } from 'utils/customConvertCase'
const { selector } = SelectorUtils

export function * createOrder (action) {
  const { payload, orders, isPrePaid, batchSize } = yield * createOrderPayload(action.payload)
  const { success, batchId: bid } = yield call(chunkOrderCreation, {
    payload,
    orders,
    shouldChunk: !isPrePaid,
    batchSize,
    navigate: action.payload.navigate
  })

  if (success) {
    yield call(postOrderCreation, { batchId: bid })
  }
}

const MAX_NON_CHUNK_SIZE = 300

export function * chunkOrderCreation ({ payload, orders, shouldChunk, batchSize, navigate }) {
  if (!shouldChunk && payload.orders.length > MAX_NON_CHUNK_SIZE) {
    yield put(orderCreateCreators.set('error', 'too_many_orders'))
    return { success: false }
  }
  const groupedOrders = _.groupBy(payload.orders, o => (_.isEmpty(o.errors) ? 'valid' : 'invalid'))
  const validOrders = groupedOrders?.valid
  const invalidOrders = groupedOrders?.invalid
  const isShopifyOrder = yield select(selectIsCreatingShopifyOrders())
  const orderCreateFn = isShopifyOrder ? dashApi.createShopifyOrders : dashApi.createOrder
  const { errors, asyncUuids } = getValidationErrors(invalidOrders)
  yield put(orderCreateCreators.set('status', ORDER_CREATE_STATUS.SUBMITTING))
  const [firstPayload = [], ...rest] = shouldChunk ? _.chunk(validOrders, batchSize) : [validOrders]

  const response = yield nvCall(orderCreateFn, { ...payload, orders: firstPayload })
  response.data = toCamelCase(response.data)
  if (!response.ok) {
    yield put(orderCreateCreators.set('status', ORDER_CREATE_STATUS.ERROR))
    const errCode = response?.data?.error?.code
    switch (errCode) {
      case DASH_ERRORS.PRICE_NOT_MATCH:
        yield put(orderCreateCreators.set('error', 'pricing_has_changed'))
        break
      case DASH_ERRORS.INVALID_INPUT:
        yield put(orderCreateCreators.set('error', 'types.error.invalid_input'))
        break
      case DASH_ERRORS.BAD_REQUEST:
        if (response?.data?.error?.message?.includes(DASH_ERRORS.INSUFFICIENT_BALANCE)) {
          yield put(orderCreateCreators.set('error', 'types.error.insufficient_balance'))
        } else {
          yield put(orderCreateCreators.set('error', response?.data?.error?.message ?? 'error'))
        }
        break
      default:
        yield put(orderCreateCreators.set('error', response?.data?.error?.message ?? 'error'))
    }
    return { success: false }
  }

  const newBatchId = response?.data?.batchId
  asyncUuids.push(...(response?.data?.asyncUuids || []))
  _.assign(errors, mapDashErrors(response?.data?.errors || {}))

  const numberOfTrial = yield select(selector('global', 'orderCreate', 'numberOfTrial')())
  let numberOfCreating = firstPayload.length
  yield put(
    orderCreateCreators.merge({
      numberOfTrial: (!numberOfTrial || _.isNaN(numberOfTrial) ? 0 : numberOfTrial) + 1,
      status: ORDER_CREATE_STATUS.POLLING,
      failedOrders: orders.map(o => _.omit(o, 'completed')),
      numberOfCreating
    })
  )
  yield put(orderCreateCreators.set('progress', {})) // or orderCreateCreators.remove?
  yield call(navigate, ROUTES.OC_PROCESS)

  for (let i = 0; i < rest.length; i++) {
    const resp = yield nvCall(orderCreateFn, { ...payload, batchId: newBatchId, orders: rest[i] })
    resp.data = toCamelCase(resp.data)
    if (resp.ok) {
      _.assign(errors, mapDashErrors(resp?.data?.errors || {}))
      asyncUuids.push(...(resp?.data?.asyncUuids || []))
      numberOfCreating += rest[i].length
      yield put(orderCreateCreators.set('numberOfCreating', numberOfCreating))
    }
  }
  yield put(orderCreateCreators.merge({ asyncUuids, batchError: errors }))
  return yield call(pollStatus, { batchId: newBatchId, batchSize: payload.orders.length })
}

export function * pollStatus ({ batchId, batchSize }) {
  let polling = POLLING.LONG
  if (batchSize < 100) {
    polling = POLLING.SHORT
  } else if (batchSize < 500) {
    polling = POLLING.MEDIUM
  }
  let batchStatus = {}
  const startTime = new Date().getTime()
  while (true) {
    const response = yield call(dashApi.getBatchStatus, batchId)
    if (response.ok && response.data) {
      batchStatus = response.data
      yield put(orderCreateCreators.set('progress', batchStatus))
      if (batchStatus?.numberOfProcessingOrders === 0) {
        break
      }
    }
    if (new Date().getTime() - startTime > polling.timeout) {
      const { successfulOrdersAsyncUuids } = batchStatus
      yield put(orderCreateCreators.transferSuccess(successfulOrdersAsyncUuids))
      yield call(submitAllOrdersInBatch, { batchId }) // save batch when timeout
      yield put(orderCreateCreators.set('status', ORDER_CREATE_STATUS.TIMEOUT))
      return { success: false }
    }
    yield delay(polling.interval)
  }

  const { successfulOrdersAsyncUuids } = batchStatus
  yield put(orderCreateCreators.transferSuccess(successfulOrdersAsyncUuids))
  yield call(getErrors)
  // Moved here for error.test.js to pass
  // Reason: OCProcess won't get updated for getErrors call because it's not subscribed to errors
  // Trigger 'status' update here will trigger the re-render in OCProcess
  yield put(orderCreateCreators.set('status', ORDER_CREATE_STATUS.DONE))

  if (!_.isEmpty(successfulOrdersAsyncUuids)) {
    // do postprocessing
    return { success: true, batchId }
  } else {
    return { success: false }
  }
}

export function * postOrderCreation ({ batchId }) {
  yield call(dashApi.completeBatch, batchId)
}

function * submitAllOrdersInBatch ({ batchId }) {
  const failed = yield select(selector('global', 'orderCreate', 'failedOrders')())
  const succeeded = yield select(selector('global', 'orderCreate', 'successOrders')())
  const data = []
  if (failed) {
    data.push(...failed)
  }
  if (succeeded) {
    for (const s of succeeded) {
      delete s.error
      data.push(s)
    }
  }
  yield put(ApiHelper.creators.request('createBatchErrors', dashApi.createBatchErrors, [batchId, { data }]))
}

function getValidationErrors (invalidOrders = []) {
  const errorStruct = { errors: {}, asyncUuids: [] }

  for (const order of invalidOrders) {
    const generatedUuid = uuid()
    errorStruct.errors[generatedUuid] = {
      uuid: generatedUuid,
      details: order.errors,
      batchSequenceNumber: order.internalRef.batchSequenceNumber
    }
    errorStruct.asyncUuids.push(generatedUuid)
  }

  return errorStruct
}

function mapDashErrors (errors) {
  return _.mapKeys(errors, 'uuid')
}

function toErrorsMap (errors) {
  return _.mapKeys(
    _.mapValues(errors, err => err?.body?.error),
    v => v.requestId
  )
}

export function * getErrors () {
  const { batchId } = yield select(selector('global', 'orderCreate', 'progress')())
  let batchError = yield select(selector('global', 'orderCreate', 'batchError')())
  let response = yield call(dashApi.getFailedOrders, { batchId })
  if (response.ok) {
    const { totalPages } = response.data
    // extend batch errors with new data
    batchError = { ...batchError, ...toErrorsMap(response.data.value) }
    // load errors for other pages
    for (let i = 1; i < totalPages; i++) {
      response = yield call(dashApi.getFailedOrders, { batchId, page: i + 1 })
      if (response.ok) {
        batchError = { ...batchError, ...toErrorsMap(response.data.value) }
      }
    }
    yield put(orderCreateCreators.set('batchError', batchError))
  } else {
    yield put(orderCreateCreators.set('batchError', {}))
  }
}

export default function * defaultSaga () {
  yield takeLatest(orderCreateTypes.SUBMIT, createOrder)
  yield takeLatest(orderCreateTypes.SUBMIT_ALL_ORDERS_IN_BATCH, submitAllOrdersInBatch)
}
