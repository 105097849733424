/**
 * The global state selectors
 */
import { createSelector } from 'reselect'
import _ from 'lodash'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import {
  SHIPPER_TYPE,
  OC_SERVICES_AVAILABLE,
  DASH_SHIPPER_TYPE,
  OC_SERVICE_TYPES,
  CAMPAIGN_ACTIVE_STATUS
} from './constants'
import { LOYALTY_HOSTNAMES } from 'components/LoyaltyCard/constants'
import { PICKUP_SERVICES_TYPES } from 'containers/Base/constants'
import { defaultServicesLevel, SUPPORTED_SERVICE_LEVELS } from 'containers/DeliveryType/constants'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import {
  masterOrderCreateServiceTypesMap,
  OCPickupOptions,
  orderCreateServiceTypesMap,
  PICKUP_TYPES_OPTIONS_KEYS
} from '../OrderCreate/constants'
import { SALES_CHANNEL } from 'enums/saleschannel'
import { FEE_PAYABLE_BY } from 'constants/payment/feePayableBy'
import { PAYMENT_SETTINGS } from 'constants/payment/paymentSettings'
import { IDENTITY_PROVIDER } from 'constants/identityProvider'
import { EKYC_STAGE } from 'enums/ekycStage'
import { COD_OFFSET_TYPE } from 'enums/codOffsetType'
import { ACCOUNT_DELETION_TYPE } from 'containers/DeleteAccountModal/DeleteAccountModal.constants'

const { selector } = SelectorUtils
const PREMIUM_SERVICE_LEVEL = 'Premium'
const STANDARD_SERVICE_LEVEL = 'Standard'

export const selectShipper = () =>
  createSelector(
    selector('global', 'shipper')(),
    selectIsMarketplaceSeller(),
    selectIsCorporateBranch(),
    selectSubShipper(),
    (shipperEntity = {}, isMarketplaceSeller, isCorporateBranch, subShipper) => {
      const { currentId, data } = shipperEntity

      let shipper = null
      if ((isMarketplaceSeller || isCorporateBranch) && subShipper) {
        shipper = subShipper
      } else {
        shipper = data && data[currentId] ? data[currentId] : null
      }

      if (!shipper) {
        // return skeleton of shipper
        shipper = {
          active: true,
          archived: false,
          billingAddress: '',
          billingContact: '',
          billingName: '',
          contact: '',
          country: '',
          email: '',
          liaisonAddress: '',
          name: '',
          salesPerson: '',
          shipperType: '',
          shortName: ''
        }
      }
      return shipper
    }
  )

export const selectAccountDeletionType = () =>
  createSelector(selectUser(), userState => {
    const isDeleted = userState?.isDeleted
    if (isDeleted === false) {
      return ACCOUNT_DELETION_TYPE.pending
    }

    if (isDeleted === true) {
      return ACCOUNT_DELETION_TYPE.deleted
    }

    return ACCOUNT_DELETION_TYPE.none
  })

export const selectCountry = () => createSelector(selectShipper(), shipperState => shipperState?.country)

export const selectMetadata = () => selector('entity', 'metadata', 'data')()

export const selectIsDvtEnabled = () =>
  createSelector(selectMetadata(), metadata => metadata?.isDeliveryVerificationToolEnabled === true)

export const selectIsEkycDone = () =>
  createSelector(selectShipper(), shipper => shipper?.ekycStage?.toLowerCase() === EKYC_STAGE.Done.toLowerCase())

export const selectIsEkycRejected = () =>
  createSelector(selectShipper(), shipper => shipper?.ekycStage?.toLowerCase() === EKYC_STAGE.Rejected.toLowerCase())

export const selectIsFullKyc = () =>
  createSelector(
    selectMetadata(),
    selectIsEkycRejected(),
    (metadata, isEkycRejected) => metadata?.isKycSubmitted === true && !isEkycRejected
  )

export const selectShipperBillingAddress = () =>
  createSelector(selectShipper(), ({ billingAddress, billingPostcode, billingContact, billingName, email }) => ({
    name: billingName,
    contact: billingContact,
    address1: _.compact([billingAddress, billingPostcode]).join(' '),
    email
  }))

export const selectIsSS = () =>
  createSelector(selectShipper(), shipperState => shipperState?.salesChannel === SALES_CHANNEL.SelfServe)

export const selectSalesChannel = () => createSelector(selectShipper(), shipperState => shipperState?.salesChannel)

export const selectUser = () => createSelector(selector('global', 'user')(), userState => userState?.data)
export const selectVerifiedPhone = () =>
  createSelector(selector('global', 'user', 'data', 'identities')(), userIdentities =>
    userIdentities?.some(identity => identity.provider === IDENTITY_PROVIDER.NV_OTP)
  )
export const selectSetupPending = () =>
  createSelector(selector('global', 'user')(), userState => userState?.setupPending)

export const selectIsAuthenticated = () =>
  createSelector(
    selector('global', 'user')(),
    userState => !_.isEmpty(userState?.data?.accessToken) && !userState.setupPending
  )

export const selectShippers = () =>
  createSelector(selector('global', 'shipper')(), shipperState => Object.values(shipperState?.data))

export const selectSubShippersCount = () =>
  createSelector(selector('global', 'shipper')(), shipperState => shipperState?.subShippersCount)

export const selectLinkShippers = () =>
  createSelector(selector('global', 'user')(), userState => Object.values(userState?.linkShippers))

export const selectSubShipper = () => createSelector(selector('global', 'user')(), userState => userState?.subShipper)

export const selectSubShipperError = () =>
  createSelector(selector('global', 'user')(), userState => userState?.subShipperError)

export const selectViewAsExternalRefValue = () =>
  createSelector(selector('global', 'user')(), userState => userState?.externalRef)

export const selectPremiumShipper = () =>
  createSelector(selector('entity', 'pickupSettings', 'data', 'pickup', 'services')(), serviceTypes => {
    return _.includes(_.map(serviceTypes, 'level'), PREMIUM_SERVICE_LEVEL)
  })

export const selectStandardShipper = () =>
  createSelector(selector('entity', 'pickupSettings', 'data', 'pickup', 'services')(), serviceTypes => {
    return _.includes(_.map(serviceTypes, 'level'), STANDARD_SERVICE_LEVEL)
  })

export const selectOcSettings = () =>
  createSelector(selector('entity', 'OCSettings', 'data', 'orderCreate')(), ocSettings => {
    const isMarketplaceSeller = ocSettings?.isMarketplaceSeller
    const isCorporateBranch = ocSettings?.isCorporateBranch
    if (isMarketplaceSeller || isCorporateBranch) {
      ocSettings.trackingType = ocSettings?.marketplaceTrackingType
      ocSettings.prefix = ocSettings?.canonicalPrefix
      ocSettings.prefixes = []
    }
    _.set(
      ocSettings,
      'availableServiceLevels',
      _.intersection(ocSettings?.availableServiceLevels, Object.values(SUPPORTED_SERVICE_LEVELS))
    )
    return ocSettings
  })

export const selectFPLTrackingType = () =>
  createSelector(selector('entity', 'OCSettings', 'data', 'orderCreate')(), ocSettings =>
    (ocSettings?.trackingType || '').toLowerCase()
  )

export const selectHideFieldsForMYFSshipper = () =>
  createSelector(
    selectCountry(),
    selectDashShipperSalesChannel(),
    (country, salesChannel) => country?.toLowerCase() === COUNTRIES.MY && salesChannel === SALES_CHANNEL.FieldSales
  )

export const selectHideCodFees = () =>
  createSelector(
    selectPricingSettings(),
    selectHideFieldsForMYFSshipper(),
    (pricingSettings, hideFieldsForMYFSshipper) =>
      hideFieldsForMYFSshipper &&
      pricingSettings?.pricing?.codOffsetType?.toLowerCase() !== COD_OFFSET_TYPE.OffsetPlus?.toLowerCase()
  )

export const selectMasterOcSettings = () =>
  createSelector(selector('entity', 'MasterOCSettings', 'data', 'orderCreate')(), ocSettings => {
    _.set(
      ocSettings,
      'availableServiceLevels',
      _.intersection(ocSettings?.availableServiceLevels, Object.values(SUPPORTED_SERVICE_LEVELS))
    )
    return ocSettings
  })

export const selectFulfillmentSettings = () =>
  createSelector(
    selector('entity', 'fulfillmentSettings', 'data', 'crossBorder')(),
    fulfillmentSettings => fulfillmentSettings?.allowFulfillmentService
  )

export const selectPrinterSettings = () => selector('entity', 'printerSettings', 'data', 'labelPrinter')()

export const selectWhitelistedDomainReportRecipient = () =>
  createSelector(
    selector('entity', 'notificationSettings', 'data', 'notification')(),
    notificationSettings => notificationSettings?.whitelistedDomainReportRecipient
  )

export const selectAvailableServiceLevels = () =>
  createSelector(selectOcSettings(), ocSettings => ocSettings?.availableServiceLevels || defaultServicesLevel)

export const selectAvailableServiceTypes = () =>
  createSelector(selectOcSettings(), ocSettings => ocSettings?.availableServiceTypes)

export const selectMasterAvailableServiceTypes = () =>
  createSelector(selectMasterOcSettings(), ocSettings => ocSettings?.availableServiceTypes)

export const selectAllowedDeliveryTimeslots = () =>
  createSelector(selectOcSettings(), ocSettings => ocSettings?.allowedDeliveryTimeslots)

export const selectIsSameDayOrderShipper = () =>
  createSelector(selectOcSettings(), ocSettings =>
    _.includes(ocSettings?.availableServicesLevel, OC_SERVICES_AVAILABLE.SAMEDAY_ORDER)
  )

export const selectDashShipperType = () =>
  createSelector(selectShipper(), shipper => {
    return shipper?.shipperType
  })

export const selectDashShipperSalesChannel = () =>
  createSelector(selectShipper(), shipper => {
    return shipper?.salesChannel
  })

export const selectIsProShipper = () =>
  createSelector(selectDashShipperType(), shipperType => {
    return shipperType && shipperType.toLowerCase() === DASH_SHIPPER_TYPE.PRO
  })

export const selectIsPrepaidShipper = () =>
  createSelector(selectOcSettings(), ocSettings => ocSettings?.isPrePaid ?? false)

export const selectCreditTerm = () =>
  createSelector(selectOcSettings(), ocSettings => _.get(ocSettings, 'creditTerm', 0))

export const selectAllowCodEdit = () =>
  createSelector(selectOcSettings(), ocSettings => ocSettings?.allowCodEdit ?? false)

export const selectIsLiteShipper = () => createSelector(selectIsProShipper(), isPro => !isPro)

export const selectIsSSShipper = () =>
  createSelector(selectDashShipperSalesChannel(), salesChannel => salesChannel === 'Self Serve')

export const selectWebsiteLocale = () =>
  createSelector(
    selector('language', 'locale')(),
    selectCountry(),
    (language, country) =>
      `${language ? language.substring(0, 2) : 'en'}-${country ? country.toLowerCase() : COUNTRIES.SG}`
  )

/**
 * FIXME: [ND-2018] (ZF) Come up with a better classification method to represent
 * SG & MY Lite shippers (Pro Shippers with Prepaid capability)
 */
export const selectIsMySgLiteShipper = () =>
  createSelector(
    selectCountry(),
    selectIsPrepaidShipper(),
    (country, isPrepaidShipper) =>
      (country?.toLowerCase() === COUNTRIES.MY || country?.toLowerCase() === COUNTRIES.SG) && isPrepaidShipper
  )

export const selectShipperType = () =>
  createSelector(selectPremiumShipper(), selectIsSameDayOrderShipper(), (isPremium, isSameDayOrderShipper) => {
    if (isPremium && isSameDayOrderShipper) {
      return SHIPPER_TYPE.PREMIUM_SAMEDAY
    }
    if (isPremium && !isSameDayOrderShipper) {
      return SHIPPER_TYPE.PREMIUM
    }
    if (!isPremium && isSameDayOrderShipper) {
      return SHIPPER_TYPE.STANDARD_SAMEDAY
    }
    return SHIPPER_TYPE.STANDARD
  })

export const selectPickupSettings = () => selector('entity', 'pickupSettings', 'data')()

export const selectPickupServices = () =>
  createSelector(selectPickupSettings(), pickupSettings => {
    return pickupSettings?.pickup?.services
  })

export const selectIsScheduledPickupAvailable = () =>
  createSelector(selectPickupServices(), pickupServices => {
    const pickupTypes = _.map(pickupServices, ({ type }) => type)
    return _.includes(pickupTypes, PICKUP_SERVICES_TYPES.SCHEDULED)
  })

export const selectIsScheduledPickUpSupportedForAnyAvailableServiceType = () =>
  createSelector(
    selectAvailableServiceTypes(),
    selectMasterAvailableServiceTypes(),
    selectIsSubShipperAccount(),
    selectCountry(),
    (availableServiceTypes, masterAvailableServiceTypes, isSubShipper, country) => {
      const whitelist = OCPickupOptions[country?.toLowerCase()] || {}
      const isSupported = Object.entries(whitelist).some(
        ([key, service]) =>
          service?.[PICKUP_TYPES_OPTIONS_KEYS.SCHEDULED] &&
          availableServiceTypes?.includes(orderCreateServiceTypesMap[key])
      )

      let isSupportedForSubshipper = false
      if (isSubShipper) {
        isSupportedForSubshipper = Object.entries(whitelist).some(
          ([key, service]) =>
            service?.[PICKUP_TYPES_OPTIONS_KEYS.SCHEDULED] &&
            (masterOrderCreateServiceTypesMap[key] || []).some(type => masterAvailableServiceTypes?.includes(type))
        )
      }

      return isSupported || isSupportedForSubshipper
    }
  )

export const selectIsScheduledPickupWhiteListedAndSupported = () =>
  createSelector(
    selectPickupServices(),
    selectIsScheduledPickUpSupportedForAnyAvailableServiceType(),
    (pickupServices, isScheduledPickUpSupported) => {
      const pickupTypes = _.map(pickupServices, ({ type }) => type)
      const isPickupWhitelisted = _.includes(pickupTypes, PICKUP_SERVICES_TYPES.SCHEDULED)
      return isPickupWhitelisted && isScheduledPickUpSupported
    }
  )

export const selectIsNinjaPackOCOnlySupported = () =>
  createSelector(selectAvailableServiceTypes(), availableServiceTypes => {
    return _.includes(availableServiceTypes, OC_SERVICE_TYPES.NINJA_PACKS) && availableServiceTypes?.length === 1
  })

//Used to prevent PH lite shippers (where only NINJA PACKS is supported from them) from accessing pickup page
export const selectIsPickupPageSupported = () =>
  createSelector(
    selectIsScheduledPickupWhiteListedAndSupported(),
    selectIsNinjaPackOCOnlySupported(),
    (isSupported, isNinjaPackOnlySupported) => isSupported && !isNinjaPackOnlySupported
  )

export const selectMessageAcks = () =>
  createSelector(selector('global', 'message')(), selectShipper(), (message, { id }) => (message?.acks ?? {})[id])

export const selectMessages = () =>
  createSelector(
    selector('global', 'message')(),
    selectShipper(),
    (message, { id }) => (message?.messages ?? {})?.[id] ?? []
  )

export const selectHasShopifyStore = () =>
  createSelector(
    selector('entity', 'integrationSettings')(),
    selectIsProShipper(),
    selectIsPrepaidShipper(),
    (setting, isPro, isPrepaid) => {
      return isPro && !isPrepaid && (setting?.data?.integration?.hasShopifyStore ?? false)
    }
  )

export const selectIsLoyaltyEnabled = () =>
  createSelector(selector('entity', 'loyaltyInfo')(), selectCountry(), (loyaltyInfo, country) => {
    const loyaltyTier = loyaltyInfo?.data?.memberTiers?.[0]?.name
    return loyaltyTier && Object.keys(LOYALTY_HOSTNAMES).includes(country.toLowerCase())
  })

// Corporate settings
export const selectIsCorporateHQ = () => createSelector(selectOcSettings(), ocSettings => ocSettings?.isCorporateHq)

export const selectIsCorporateBranch = () =>
  createSelector(selectOcSettings(), ocSettings => ocSettings?.isCorporateBranch)

export const selectIsMarketplace = () => createSelector(selectOcSettings(), ocSettings => ocSettings?.isMarketplace)

export const selectIsMarketplaceSeller = () =>
  createSelector(selectOcSettings(), ocSettings => ocSettings?.isMarketplaceSeller)

export const selectIsEnterpriseParent = () =>
  createSelector(
    selectIsCorporateHQ(),
    selectIsMarketplace(),
    (isCorporateHq, isMarketPlace) => isCorporateHq || isMarketPlace
  )

export const selectIsEnterprise = () =>
  createSelector(
    selectIsCorporateHQ(),
    selectIsCorporateBranch(),
    selectIsMarketplace(),
    selectIsMarketplaceSeller(),
    (isCorporateHq, isCorporateBranch, isMarketPlace, isMarketPlaceSeller) =>
      isCorporateHq || isCorporateBranch || isMarketPlace || isMarketPlaceSeller
  )

export const selectMasterShipper = selector('global', 'user', 'data', 'masterShipper')

export const selectMasterShipperId = () => createSelector(selectMasterShipper(), shipper => shipper?.id ?? null)

export const selectIsMasterAccount = () =>
  createSelector(selectMasterShipperId(), selectShippers(), (masterShipperId, shippers) => {
    return masterShipperId && masterShipperId === shippers?.[0]?.id
  })

export const selectIsSubShipperAccount = () =>
  createSelector(
    selectIsCorporateBranch(),
    selectIsMarketplaceSeller(),
    (isCorporateBranch, isMarketplaceSeller) => isCorporateBranch || isMarketplaceSeller
  )

export const selectIsImpersonating = () =>
  createSelector(
    selectIsMasterAccount(),
    selectMasterShipper(),
    selectShipper(),
    (isMasterAccount, masterShipper, shipper) => {
      return isMasterAccount && masterShipper && masterShipper.id !== shipper.id
    }
  )

export const selectMarketplaceShipperViewFilter = () =>
  createSelector(
    selectIsMarketplace(),
    selector('global', 'shipper', 'currentId')(),
    selectShipper(),
    selector('search', 'ordersViewType')(),
    (isMarketplace, globalShipperId, currentShipper, ordersViewType) => {
      if (isMarketplace) {
        return {
          field: ordersViewType === 'parentOrders' ? 'shipper_id' : 'parent_shipper_id',
          values: [ordersViewType === 'parentOrders' ? currentShipper.legacyId : globalShipperId]
        }
      }
      return null
    }
  )

export const selectAuthClientName = selector('entity', 'oAuth2ClientName', 'data')

export const loyaltyInfoSelector = () =>
  createSelector(selector('entity', 'loyaltyInfo')(), loyaltyInfo => loyaltyInfo?.data)
export const selectHmac = () => createSelector(selector('entity', 'loyalty', 'data', 'hmac')(), hmac => hmac)
export const selectHmacEmail = () => createSelector(selector('entity', 'loyalty', 'data', 'email')(), email => email)
export const loyaltyErrorSelector = () =>
  createSelector(selector('entity', 'loyalty', 'error')(), error => error?.message)

export const selectPricingSettings = () => selector('entity', 'pricing', 'data')()

export const selectAreRatesDisplayed = () =>
  createSelector(selectPricingSettings(), pricingSettings => {
    return pricingSettings?.pricing?.areRatesDisplayed ?? true
  })

export const selectSGSSPostpaid = () =>
  createSelector(selectCountry(), selectIsSS(), selectPaymentSettings(), (country, isSS, paymentSettings) => {
    return country?.toLowerCase() === COUNTRIES.SG && isSS && paymentSettings === PAYMENT_SETTINGS.payAtEndOfMonth
  })

export const selectPaymentSettings = () =>
  createSelector(selectOcSettings(), ocSettings => {
    if (ocSettings?.allowUseAccountBalance === true && ocSettings?.feePayableBy === FEE_PAYABLE_BY.customer) {
      return PAYMENT_SETTINGS.nettCod
    }

    if (
      ocSettings?.allowUseAccountBalance === true &&
      ocSettings?.feePayableBy === FEE_PAYABLE_BY.shipper &&
      ocSettings?.allowTopup === true
    ) {
      return PAYMENT_SETTINGS.topupCredit
    }

    if (
      ocSettings?.allowUseAccountBalance === false &&
      ocSettings?.feePayableBy === FEE_PAYABLE_BY.shipper &&
      ocSettings?.creditTerm >= 10
    ) {
      return PAYMENT_SETTINGS.payAtEndOfMonth
    }

    return PAYMENT_SETTINGS.pad
  })

export const selectHasPrepaidBalance = () =>
  createSelector(
    selector('entity', 'prepaidAccountBalance', 'data', 'overallBalance')(),
    overallBalance => overallBalance !== undefined
  )

export const selectShipperCampaigns = () =>
  createSelector(selector('entity', 'shipperCampaigns')(), shipperCampaigns => shipperCampaigns?.data)

export const selectCmsCampaings = () => createSelector(selector('entity', 'campaigns')(), campaigns => campaigns?.data)

export const selectActiveShipperCampaign = () =>
  createSelector(selectShipperCampaigns(), shipperCampaigns =>
    shipperCampaigns?.find(campaign => {
      const campaignProfile = campaign.campaignProfile
      const isActive = campaignProfile.status === CAMPAIGN_ACTIVE_STATUS
      const isNotExpired = !campaignProfile.endDate || new Date(campaignProfile.endDate).getTime() > Date.now()
      return isActive && isNotExpired
    })
  )

export const selectActiveCmsCampaign = () =>
  createSelector(selectCmsCampaings(), selectActiveShipperCampaign(), (cmsCampaigns, activeShipperCampaign) => {
    if (!activeShipperCampaign) {
      return null
    }
    return cmsCampaigns?.find(campaign => campaign.campaignId === String(activeShipperCampaign.id))
  })

export const selectActiveCampaign = () =>
  createSelector(selectActiveShipperCampaign(), selectActiveCmsCampaign(), (shipperCampaign, cmsCampaign) => {
    if (!shipperCampaign || !cmsCampaign) {
      return null
    }
    const rules = shipperCampaign.rules
    const shipXCampaignRules = rules?.filter(rule => {
      const discountValue = rule.discountValue ?? 0
      return rule.condition.endsWith(OC_SERVICE_TYPES.PARCEL) && discountValue === 0
    })
    return { shipperCampaign, cmsCampaign, shipXCampaignRules }
  })

export const selectWebhookSubscriptions = () =>
  createSelector(selector('entity', 'webhooksV2', 'data')(), webhooks => {
    // NoopOnRequestGenerateHook is used for Tracking API and should not be exposed to users for view or edit.
    // FPLOnRequestGenerateHook / Unification is used for FPL migration flows
    // and should not be exposed for view or edit.
    let hiddenHooks = ['NoopOnRequestGenerateHook', 'FPLOnRequestGenerateHook', 'FPLUnificationOnRequestGenerateHook']
    return webhooks?.filter?.(webhook => !hiddenHooks.includes(webhook?.lifecycleHooks?.onRequestGenerateHook))
  })

export const selectMissingEInvoiceDetails = () =>
  createSelector(
    selectMetadata(),
    selectCountry(),
    (metadata, country) => country?.toLowerCase() === COUNTRIES.MY && !metadata?.isAccountDetailsSubmitted
  )
