import React, { useMemo, useState } from 'react'
import { T, Row, Col } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Vspace } from 'components/Vspace'
import { PackingAlert } from 'components/PackingAlert'
import { faCloudUpload } from '@fa-pro-light/faCloudUpload'
import { faKeyboard } from '@fa-pro-light/faKeyboard'
import { INPUT_METHODS } from 'containers/FPLOrderCreate/constants'
import { StyledCard } from 'containers/FPLOrderCreate/styles'
import { Colors } from 'themes'
import { Images } from 'images'
import { StyledImage } from './styles'
import { LargeOptionButton } from './LargeOptionButton'
import { CCType } from './constants'

export const OCMethodSelection = ({ ccType, onMethodChange }) => {
  const [showKeyboardNotSupported, setShowKeyboardNotSupported] = useState(false)
  const [showUploadFileNotSupported, setShowUploadFileNotSupported] = useState(false)

  const isKeyboardMethod = useMemo(() => {
    return ccType === CCType.B2B
  }, [ccType])

  const isUploadFileMethod = useMemo(() => {
    return ccType === CCType.B2C
  }, [ccType])

  const renderKeyboardButton = useMemo(() => {
    if (isUploadFileMethod && showKeyboardNotSupported) {
      return (
        <LargeOptionButton data-analyticsid='createMMCCOrdersManually' icon={null}>
          <StyledImage src={Images.ryo4XX} />
          <Text id='not_support_method' size={16} color={Colors.cherryRed} />
          <Text id='use_upload_file' size={12} className='pt-2' />
        </LargeOptionButton>
      )
    }
    return (
      <LargeOptionButton
        icon={faKeyboard}
        onClick={() => {
          if (isUploadFileMethod) {
            setShowKeyboardNotSupported(true)
          } else {
            onMethodChange(INPUT_METHODS.KEYBOARD)
          }
        }}
        data-analyticsid='createMMCCOrdersManually'
      >
        <T id='type_orders' />
      </LargeOptionButton>
    )
  }, [isUploadFileMethod, showKeyboardNotSupported, onMethodChange])

  const renderUploadButton = useMemo(() => {
    if (isKeyboardMethod && showUploadFileNotSupported) {
      return (
        <LargeOptionButton data-analyticsid='createMMCCOrdersManually' icon={null}>
          <StyledImage src={Images.ryo4XX} />
          <Text id='upload_order_not_support' size={16} color={Colors.cherryRed} />
          <Text id='use_keyboard_input' size={12} className='pt-2' />
        </LargeOptionButton>
      )
    }
    return (
      <LargeOptionButton
        icon={faCloudUpload}
        onClick={() => {
          if (isKeyboardMethod) {
            setShowUploadFileNotSupported(true)
          } else {
            onMethodChange(INPUT_METHODS.FILE)
          }
        }}
        data-analyticsid='createMMCCOrdersManually'
      >
        <T id='upload_file' />
      </LargeOptionButton>
    )
  }, [isKeyboardMethod, onMethodChange, showUploadFileNotSupported])

  return (
    <>
      <StyledCard data-testid={'oc-method-selection'} hoverable={false}>
        <Row type='flex' justify='center'>
          <Text size={18} type='bold'>
            <T id='choose_input' />
          </Text>
        </Row>
        <Vspace height={16} />
        <PackingAlert country='sg' intl={{ locale: 'en' }} />
        <Vspace height={16} />
        <Row gutter={16}>
          <Col span={12}>{renderKeyboardButton}</Col>
          <Col span={12}>{renderUploadButton}</Col>
        </Row>
      </StyledCard>
    </>
  )
}
