import {
  AccountCreationEvents,
  GeneralEvents,
  OrderCreationEvents,
  OrderCreationNinjaPackEvents,
  pickupTypesMappings,
  volumeVehicleMappings,
  accountSettingsEvents,
  billingSettingsEvents,
  notificationsSettingsEvents,
  myAddressSettingsEvents,
  orderCreateSettingsEvents,
  ITSettingsEvents,
  ManageOrdersEvents,
  getMappedEditedField,
  ReportsEvents,
  deliveryReportsTypesMappings,
  ManagePickups,
  DashboardTour,
  PrintingEvent,
  SearchOrders,
  advancedSearchTypesMappings,
  DATE_FORMAT,
  ReturnOrderCreationEvents,
  deliveryTypeMapping,
  walletEvents,
  InternationalOrderEvents,
  ScheduledReportEvents,
  REPORTS_EXTRA_FILTERS_PROPS_NAMES,
  REPORTS_EXTRA_FILTERS_KEYS,
  SERVICE_LEVEL_FILTER_TEXT,
  BankAccountEvents,
  DisproEvents,
  PaymentsEvents,
  HelpdeskEvents,
  Source,
  ShipperSupportEvents,
  EntryPoint,
  orderTypeFilter,
  INPUT_TYPE
} from './constants'
import {
  mpActions,
  mpTrackEventWithUserProperties,
  mpIncrementalUserProp,
  numOrDefault,
  strOrDefault,
  formatISODate,
  boolOf
} from './utils'
import moment from 'moment'
import { ORDER_CREATE_TYPES } from '../../containers/OrderTypeModal/constants'
import { PICKUP_TYPES } from '../../containers/PickupType/constants'
import { ROUTES, BASE_ROUTES, EVENTS } from '../../containers/Base/constants'
import { NotificationContent } from 'components/NotificationContent'
import _ from 'lodash'
import packageJson from '../../../package.json'
import { DELIVERY_TIME_PARAMS } from '../../containers/FPLOrderRequestList/constants'
import countryUtils from '@nv/react-commons/src/Utils/CountryUtils'
import {
  MAP_COD_DATE_TYPES_TRACKING_NAME,
  MAP_DAY_OF_WEEK_TRACKING_NAME,
  MAP_REPORT_SCHEDULES_TYPE_TRACKING_NAME,
  MAP_REPORT_TYPE_TRACKING_NAME
} from 'containers/ReportsV3/ReportsV3.consts'
import { REPORT_SCHEDULES_TYPE, REPORT_TYPE } from 'containers/ReportsV3/ReportsV3.enums'
import { isMPSOrder } from 'utils/createOrderPayload'
import { mapBool } from 'utils/csvMappingFields'

export const mixpanelTrackFirstSignUpPageVisit = () => {
  mpActions.track(AccountCreationEvents.FIRST_SIGNUP_PAGE_VISIT.name)
}

export const mixpanelTrackSignUpDetailsAdded = method => {
  mpActions.track(AccountCreationEvents.ADDED_SIGNUP_DETAILS.name, {
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.METHOD]: method
  })
}

export const mixpanelTrackSignUpSubmitted = method => {
  mpActions.track(AccountCreationEvents.SUBMITTED_SIGNUP.name, {
    [AccountCreationEvents.SUBMITTED_SIGNUP.props.METHOD]: method
  })
}

export const mixpanelTrackAddedReferrerNumber = number => {
  mpActions.track(AccountCreationEvents.ADDED_REFERRER_NUMBER.name, {
    [AccountCreationEvents.ADDED_REFERRER_NUMBER.props.REFERRER_NUMBER]: number
  })
}

export const mixpanelTrackAddedBusinessDetails = ({ name, contactNo, storeName }) => {
  mpActions.track(AccountCreationEvents.ADDED_BUSINESS_DETAILS.name)
  mpActions.people.set({
    [AccountCreationEvents.ADDED_BUSINESS_DETAILS.props.SHIPPER_NAME]: name,
    [AccountCreationEvents.ADDED_BUSINESS_DETAILS.props.SHIPPER_CONTACT_NUMBER]: contactNo,
    [AccountCreationEvents.ADDED_BUSINESS_DETAILS.props.SHIPPER_BUSINESS_NAME]: storeName,
    [AccountCreationEvents.ADDED_BUSINESS_DETAILS.props.ADDED_BUSINESS_DETAILS_DATE]: moment(Date.now()).format(
      DATE_FORMAT
    )
  })
}

export const mixpanelTrackAddedBankAccountDetails = () => {
  mpActions.track(BankAccountEvents.ADDED_BANK_ACCOUNT_DETAILS.name)
}

export const mixpanelTrackAddedKyc = () => {
  mpActions.track(AccountCreationEvents.ADDED_KYC.name)
  mpActions.people.set({
    [AccountCreationEvents.ADDED_KYC.props.ADDED_KYC_DATE]: moment(Date.now()).format(DATE_FORMAT)
  })
}

export const mixpanelTrackSignUpDetails = user => {
  if (user?.error) {
    return
  }
  const shipper = getShipperFromUser(user)
  mpActions.identify(shipper.id)
  mpActions.people.set({
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.SHIPPER_ID]: shipper.id,
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.LEGACY_SHIPPER_ID]: shipper.legacyId,
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.EMAIL_ADDRESS]: user?.email,
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.COUNTRY]: user?.locale,
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.SHIPPER_CONTACT_NUMBER]: user?.contactNo,
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.SHIPPER_BUSINESS_NAME]: user?.account?.name,
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.PASSWORD_SET]: true,
    [AccountCreationEvents.COMPLETED_ACCOUNT_CREATION.props.ACCOUNT_CREATED_DATE]: moment(
      user?.account?.createdAt
    )?.format(DATE_FORMAT)
  })
  mpActions.register({
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.SHIPPER_ID]: shipper.id,
    [AccountCreationEvents.ADDED_SIGNUP_DETAILS.props.LEGACY_SHIPPER_ID]: shipper.legacyId
  })
  mpActions.track(AccountCreationEvents.COMPLETED_ACCOUNT_CREATION.name)
}

export const mixpanelTrackSignUpProDetails = user => {
  if (!user) {
    return
  }
  mpActions.track(AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.name, {
    [AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.props.EMAIL_ADDRESS]: user?.email,
    [AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.props.COUNTRY]: countryUtils.getCountryNames(user?.country),
    [AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.props.SHIPPER_CONTACT_NUMBER]: user?.contactNo,
    [AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.props.SHIPPER_BUSINESS_NAME]: user?.name,
    [AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.props.MONTHLY_VOLUME]: user?.monthlyVolume,
    [AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.props.SALES_CHANNEL]: user?.salesChannel,
    [AccountCreationEvents.ADDED_PRO_SIGNUP_DETAILS.props.INDUSTRY]: user?.industry
  })
}

export const mixpanelIdentifyAndLogAppVersion = () => {
  mpActions.people.set({
    [GeneralEvents.VISITED_PAGE.props.APP_VERSION]: packageJson.version
  })
}

export const mixpanelTrackResendOTP = () => {
  mpIncrementalUserProp(
    AccountCreationEvents.RESET_VERIFICATION_EMAIL.name,
    AccountCreationEvents.RESET_VERIFICATION_EMAIL.props.NUMBER_OF_RESEND_VERIFICATION_EMAIL_ATTEMPTS
  )
}

export const mixpanelTrackLogOut = () => {
  mpActions.track(GeneralEvents.LOGGED_OUT.name, {
    [GeneralEvents.LOGGED_OUT.props.LOG_OUT]: true
  })
  mpActions.reset()
}

export const mixpanelTrackLogIn = (method, user) => {
  const shipper = getShipperFromUser(user)
  mpActions.identify(shipper.id)
  mpActions.people.set({
    [GeneralEvents.LOGGED_IN.props.SALES_CHANNEL]: shipper.salesChannel,
    [GeneralEvents.LOGGED_IN.props.SHIPPER_ID]: shipper.id,
    [GeneralEvents.LOGGED_IN.props.LEGACY_SHIPPER_ID]: shipper.legacyId
  })
  mpActions.register({
    [GeneralEvents.LOGGED_IN.props.SHIPPER_ID]: shipper.id,
    [GeneralEvents.LOGGED_IN.props.LEGACY_SHIPPER_ID]: shipper.legacyId
  })
  mpActions.track(GeneralEvents.LOGGED_IN.name, { [GeneralEvents.LOGGED_IN.props.LOGIN_METHOD]: method })
}

export const mixpanelTrackOpenedLiveChat = () => {
  mpActions.track(GeneralEvents.OPENED_LIVE_CHAT.name, {
    [GeneralEvents.OPENED_LIVE_CHAT.props.OPEN_LIVE_CHAT]: true
  })
}

export const mixpanelTrackCompletedTermsAndConditions = () => {
  mpTrackEventWithUserProperties(AccountCreationEvents.COMPLETED_TERMS_AND_CONDITIONS.name, {
    [AccountCreationEvents.COMPLETED_TERMS_AND_CONDITIONS.props.TERMS_AND_CONDITIONS]: true
  })
}

export const mixpanelTrackSelectedPickupSelection = (orderCreate, orderType) => {
  const events = orderType === ORDER_CREATE_TYPES.REGULAR ? OrderCreationEvents : ReturnOrderCreationEvents
  mpActions.track(
    events.SELECTED_PICKUP_SELECTION.name,
    getPickupProps(orderCreate?.pickupType, orderCreate?.reservation, strOrDefault(orderCreate?.volume))
  )
}

export const mixpanelTrackPriceEstimatedEnable = isEnabled => {
  mpActions.track(OrderCreationEvents.PRICE_ESTIMATED_TOGGLE.name, {
    [OrderCreationEvents.PRICE_ESTIMATED_TOGGLE.props.PRICE_ESTIMATE_TOGGLE_ENABLED]: isEnabled
  })
}

export const mixpanelTrackSelectedReturnShipperDetails = orderCreate => {
  mpActions.track(ReturnOrderCreationEvents.SELECTED_RETURN_TO_SHIPPER_DETAILS.name, {
    [ReturnOrderCreationEvents.SELECTED_RETURN_TO_SHIPPER_DETAILS.props.RETURN_ADDRESS]:
      orderCreate.returnAddresses !== null,
    [ReturnOrderCreationEvents.SELECTED_RETURN_TO_SHIPPER_DETAILS.props.DELIVER_TIME]:
      DELIVERY_TIME_PARAMS[orderCreate?.returnTime?.returnTimeslot?.deliveryTimeslot],
    [ReturnOrderCreationEvents.SELECTED_RETURN_TO_SHIPPER_DETAILS.props.DELIVERY_TYPE]:
      deliveryTypeMapping[orderCreate?.returnTime?.returnTimeslot?.deliveryType],
    [ReturnOrderCreationEvents.SELECTED_RETURN_TO_SHIPPER_DETAILS.props.INSTRUCTIONS_FOR_DRIVER]: strOrDefault(
      orderCreate?.reservation?.comments
    )
  })
}

export const mixpanelTrackAddedOrderDetails = (isBulkUpload, orders, isReturnOrder) => {
  if (isReturnOrder) {
    mpActions.track(ReturnOrderCreationEvents.ADDED_ORDER_DETAILS.name, setReturnOrderDetailsProps({}, orders))
    return
  }
  orders?.map(order => {
    let props = {
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.INSTRUCTIONS_FOR_DRIVER]: !isBulkUpload
        ? strOrDefault(order?.instructions)
        : null,
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.RECIPIENT_DETAILS]: true,
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.DELIVERY_TYPE]: strOrDefault(
        deliveryTypeMapping[order?.deliveryType]
      )
    }
    props = setOrderDetailsProps(props, isBulkUpload, order)

    mpActions.track(OrderCreationEvents.ADDED_ORDER_DETAILS.name, props)
  })
}

export const mixpanelTrackAddedReturnOrderDetails = orders => {
  mpActions.track(ReturnOrderCreationEvents.ADDED_ORDER_DETAILS.name, setReturnOrderDetailsProps({}, orders))
}

export const mixpanelTrackStartOrderCreation = (orderType, origination) => {
  const events = orderType === ORDER_CREATE_TYPES.REGULAR ? OrderCreationEvents : ReturnOrderCreationEvents
  mpActions.track(events.STARTED_ORDER_CREATION.name, {
    [events.STARTED_ORDER_CREATION.props.TYPE]: orderType,
    [events.STARTED_ORDER_CREATION.props.ORIGINATION]: origination
  })
  mpActions.people.increment({
    [events.STARTED_ORDER_CREATION.props.NUMBER_OF_ORDER_CREATION_ATTEMPTS]: 1
  })
}

export const mixpanelTrackStartBulkOrderCreation = () => {
  mpActions.track(OrderCreationEvents.STARTED_BULK_ORDER_CREATION.name)
  mpActions.people.increment({
    [OrderCreationEvents.STARTED_BULK_ORDER_CREATION.props.NUMBER_OF_BULK_ORDER_CREATION_ATTEMPTS]: 1
  })
}

export const mixpanelTrackUploadBulkOrderData = () => {
  mpActions.track(OrderCreationEvents.UPLOADED_BULK_ORDER_DATA.name)
  mpActions.people.increment({
    [OrderCreationEvents.UPLOADED_BULK_ORDER_DATA.props.NUMBER_OF_BULK_ORDER_UPLOAD_ATTEMPTS]: 1
  })
}

export const mixpanelTrackSavedBulkOrderData = () => {
  mpActions.track(OrderCreationEvents.SAVED_BULK_ORDER_DATA.name)
  mpActions.people.increment({
    [OrderCreationEvents.SAVED_BULK_ORDER_DATA.props.NUMBER_OF_SAVED_BULK_ORDER_UPLOAD_ATTEMPTS]: 1
  })
}

export const mixpanelTrackReviewedPrice = (totalPrice, hasEnoughBalance) => {
  mpActions.track(OrderCreationEvents.REVIEWED_PRICE.name, {
    [OrderCreationEvents.REVIEWED_PRICE.props.SHIPPING_FEE]: totalPrice,
    [OrderCreationEvents.REVIEWED_PRICE.props.SUFFICIENT_BALANCE]: hasEnoughBalance
  })
}

export const mixpanelTrackCompletedOrderCreation = (orderInfo, upload, events = OrderCreationEvents) => {
  mpActions.people.set({
    [events.COMPLETED_ORDER_CREATION.props.LAST_COMPLETED_ORDER_DATE]: moment(Date.now()).format(DATE_FORMAT)
  })

  mpActions.people.increment({
    [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_ORDER_CREATED]: orderInfo?.orders.length
  })

  let orderObj = {}
  if (orderInfo?.orders.length === 1) {
    const order = orderInfo.orders[0]
    if (order?.insuredValue) {
      mpActions.people.increment({
        [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_INSURANCE_ADDED]: 1
      })
    }

    if (order?.parcelDescription) {
      mpActions.people.increment({
        [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_PARCEL_DESCRIPTION_ADDED]: 1
      })
    }

    orderObj[events.COMPLETED_ORDER_CREATION.props.INSURANCE_FEE] = parseInt(numOrDefault(order?.insuredValue))
    orderObj[events.COMPLETED_ORDER_CREATION.props.PARCEL_WEIGHT] = parseInt(numOrDefault(order?.weight))
    orderObj[events.COMPLETED_ORDER_CREATION.props.PARCEL_SIZE] = strOrDefault(order?.size)
    orderObj[events.COMPLETED_ORDER_CREATION.props.PARCEL_DESCRIPTION] = strOrDefault(order?.parcelDescription)
  }

  const incrementData = orderInfo?.orders?.reduce(
    (r, order) => {
      if (order?.insuredValue) {
        ++r[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_INSURANCE_ADDED]
      }
      if (order?.parcelDescription) {
        ++r[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_PARCEL_DESCRIPTION_ADDED]
      }
      if (isMPSOrder(order)) {
        ++r[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_MPS_ORDER_CREATED]
        r[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_CARTONS_REQUIRED] += parseInt(order.noOfCartons)
        if (mapBool(order.grnRequired)) {
          ++r[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_GRN_REQUIRED]
        }
        if (mapBool(order.rdoRequired)) {
          ++r[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_RDO_REQUIRED]
        }
      }
      return r
    },
    {
      [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_INSURANCE_ADDED]: 0,
      [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_PARCEL_DESCRIPTION_ADDED]: 0,
      [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_MPS_ORDER_CREATED]: 0,
      [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_CARTONS_REQUIRED]: 0,
      [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_GRN_REQUIRED]: 0,
      [events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_RDO_REQUIRED]: 0
    }
  )

  mpActions.people.increment(incrementData)

  orderObj[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_ORDER_CREATED] = orderInfo?.orders.length
  orderObj[events.COMPLETED_ORDER_CREATION.props.INPUT_TYPE] = upload ? INPUT_TYPE.BULK : INPUT_TYPE.MANUAL

  if (incrementData[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_MPS_ORDER_CREATED] > 0) {
    orderObj[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_MPS_ORDER_CREATED] =
      incrementData[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_MPS_ORDER_CREATED]
    orderObj[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_CARTONS_REQUIRED] =
      incrementData[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_CARTONS_REQUIRED]
    if (incrementData[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_GRN_REQUIRED] > 0) {
      orderObj[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_GRN_REQUIRED] =
        incrementData[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_GRN_REQUIRED]
    }
    if (incrementData[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_RDO_REQUIRED] > 0) {
      orderObj[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_RDO_REQUIRED] =
        incrementData[events.COMPLETED_ORDER_CREATION.props.NUMBER_OF_RDO_REQUIRED]
    }
  }

  mpActions.track(events.COMPLETED_ORDER_CREATION.name, orderObj)
}

export const mixpanelTrackAbandonedOrderCreation = props => {
  const {
    ocType,
    location,
    pickupType,
    volume,
    reservation,
    orders,
    isBulkUpload,
    totalPrice,
    hasEnoughBalance
  } = props

  const isReturn = ocType !== ORDER_CREATE_TYPES.REGULAR

  let mpProps = getPickupProps(pickupType, reservation, strOrDefault(volume))

  mpProps = !isReturn
    ? setOrderDetailsProps(mpProps, isBulkUpload, orders[0])
    : setReturnOrderDetailsProps(mpProps, orders)

  if (!isReturn) {
    mpProps = {
      ...mpProps,
      [OrderCreationEvents.ABANDONED_ORDER_CREATION.props.SHIPPING_FEE]: numOrDefault(totalPrice),
      [OrderCreationEvents.ABANDONED_ORDER_CREATION.props.SUFFICIENT_BALANCE]: hasEnoughBalance ?? null,
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.RECIPIENT_DETAILS]: true
    }
  }

  const events = !isReturn ? OrderCreationEvents : ReturnOrderCreationEvents

  mpActions.people.increment({
    [events.ABANDONED_ORDER_CREATION.props.NUMBER_OF_ORDER_ABANDONED]: 1
  })

  mpActions.people.set({
    [events.ABANDONED_ORDER_CREATION.props.LAST_ABANDONED_ORDER_DETAILS]: moment(Date.now()).format(DATE_FORMAT)
  })

  // detect what steps are completed in order to updated user properties accordingly
  let completedPickUpDetails, completedOrderDetails, reviewedPriceDetails, completedShipperDetails
  if (location?.pathname.includes(BASE_ROUTES.OC_2) || location?.pathname.includes(`${BASE_ROUTES.ORDER_RETURN}/2`)) {
    completedPickUpDetails = true
  }
  if (location?.pathname.includes(BASE_ROUTES.OC_3) || location?.pathname.includes(`${BASE_ROUTES.ORDER_RETURN}/3`)) {
    completedPickUpDetails = true
    if (!isReturn) {
      completedOrderDetails = true
    } else {
      completedShipperDetails = true
    }
  }
  if (
    location?.pathname.includes(`${BASE_ROUTES.OC}/4`) ||
    location?.pathname.includes(`${BASE_ROUTES.ORDER_RETURN}/4`)
  ) {
    completedPickUpDetails = true
    completedOrderDetails = true
    if (!isReturn) {
      reviewedPriceDetails = true
    }
    completedShipperDetails = true
  }

  if (location?.pathname.includes(`${BASE_ROUTES.ORDER_RETURN}/5`)) {
    completedPickUpDetails = true
    completedOrderDetails = true
    reviewedPriceDetails = true
    completedShipperDetails = true
  }

  if (completedPickUpDetails) {
    mpActions.people.increment({
      [events.ABANDONED_ORDER_CREATION.props.NUMBER_OF_PICKUP_DETAILS_ADDED]: 1
    })
  }
  if (completedOrderDetails) {
    mpActions.people.increment({
      [events.ABANDONED_ORDER_CREATION.props.NUMBER_OF_ORDER_DETAILS_ADDED]: 1
    })
    if (!isReturn) {
      mpProps = {
        ...mpProps,
        [OrderCreationEvents.ADDED_ORDER_DETAILS.props.RECIPIENT_DETAILS]: true
      }
    }
  }
  if (!isReturn && reviewedPriceDetails) {
    mpActions.people.increment({
      [events.ABANDONED_ORDER_CREATION.props.NUMBER_OF_REVIEWED_PRICE_ADDED]: 1
    })
  }
  if (isReturn && completedShipperDetails) {
    mpActions.people.increment({
      [events.ABANDONED_ORDER_CREATION.props.NUMBER_OF_SHIPPER_DETAILS_ADDED]: 1
    })
  }

  mpActions.track(events.ABANDONED_ORDER_CREATION.name, mpProps)
}

export const setReturnOrderDetailsProps = (props, orders) => {
  const order = orders[0]
  props = {
    ...props,
    [ReturnOrderCreationEvents.ADDED_ORDER_DETAILS.props.PARCEL_WEIGHT]: parseInt(numOrDefault(order?.weight)),
    [ReturnOrderCreationEvents.ADDED_ORDER_DETAILS.props.PARCEL_SIZE]: strOrDefault(order?.size),
    [ReturnOrderCreationEvents.ADDED_ORDER_DETAILS.props.REQUESTED_TRACKING_ID]: boolOf(order?.requestedTrackingNumber),
    [ReturnOrderCreationEvents.ADDED_ORDER_DETAILS.props.INSURANCE_FEE]: parseInt(numOrDefault(order?.insuredValue)),
    [ReturnOrderCreationEvents.ADDED_ORDER_DETAILS.props.PARCEL_DESCRIPTION]: strOrDefault(order?.parcelDescription)
  }
  return props
}

export const mixpanelTrackNinjaPackOrderCreationEvents = () => {
  const {
    STARTED_NP_ORDER_CREATION,
    SELECETD_NP_PICKUP_OPTIONS,
    ADDED_NP_DETAILS,
    COMPLETED_NP_CREATION,
    ABANDONED_NP_CREATION
  } = OrderCreationNinjaPackEvents

  const trackNPCreationStarted = () => {
    mpIncrementalUserProp(STARTED_NP_ORDER_CREATION.name, STARTED_NP_ORDER_CREATION.props.NUM_OF_NP_CREATION_ATTEMPTS, {
      [STARTED_NP_ORDER_CREATION.props.ORDER_TYPE]: 'Ninja Pack'
    })
  }

  const trackSelectedNPPickupOptions = orderCreate => {
    const pickupType = strOrDefault(orderCreate?.pickupType)
    const returnAddress = boolOf(orderCreate?.addresses?.address1)
    mpActions.track(SELECETD_NP_PICKUP_OPTIONS.name, {
      [SELECETD_NP_PICKUP_OPTIONS.props.PICKUP_TYPE]: pickupType,
      [SELECETD_NP_PICKUP_OPTIONS.props.RETURN_ADDRESS]: returnAddress
    })
  }

  const trackAddedNPDetails = (isBulkUpload, orders) => {
    const mpProps = setNPOrderDetailsProps({}, isBulkUpload, orders)
    mpActions.track(ADDED_NP_DETAILS.name, mpProps)
  }

  const trackCompletedNPCreation = orderInfo => {
    const parcelDescription = orderInfo?.orders[0]?.parcelDescription
    mpIncrementalUserProp(COMPLETED_NP_CREATION.name, [COMPLETED_NP_CREATION.props.NUM_OF_NP_CREATED])
    if (parcelDescription) {
      mpActions.people.increment({ [COMPLETED_NP_CREATION.props.NUM_OF_NP_ORDER_DESCRIPTION_ADDED]: 1 })
    }
    mpActions.people.set({
      [COMPLETED_NP_CREATION.props.LAST_COMPLETED_NP_ORDER_DATE]: moment(Date.now()).format(DATE_FORMAT)
    })
  }

  const trackAbandonedNPCreation = props => {
    const { location, isBulkUpload, orders, orderCreate } = props

    let completedPickUpDetails, completedOrderDetails
    if (location?.pathname.includes(BASE_ROUTES.OC_PACKS + '/2')) {
      completedPickUpDetails = true
    }
    if (location?.pathname.includes(BASE_ROUTES.OC_PACKS + '/3')) {
      completedPickUpDetails = true
      completedOrderDetails = true
    }

    if (completedPickUpDetails) {
      mpActions.people.increment({
        [ABANDONED_NP_CREATION.props.NUM_OF_NP_PICKUP_OPTIONS_DETAILS_ADDED_DURING_ABANDONMENT]: 1
      })
    }
    if (completedOrderDetails) {
      mpActions.people.increment({
        [ABANDONED_NP_CREATION.props.NUM_OF_NP_DETAILS_ADDED_DURING_ABANDONMENT]: 1
      })
    }

    const pickupType = strOrDefault(orderCreate?.pickupType)
    const deliveryType = strOrDefault(orderCreate?.deliveryType)
    const returnAddress = boolOf(orderCreate?.addresses?.address1)

    const mpProps = setNPOrderDetailsProps(
      {
        [ABANDONED_NP_CREATION.props.PICKUP_TYPE]: pickupType,
        [ABANDONED_NP_CREATION.props.RETURN_ADDRESS]: returnAddress,
        [ABANDONED_NP_CREATION.props.DELIVERY_TYPE]: deliveryType
      },
      isBulkUpload,
      orders
    )

    mpIncrementalUserProp(ABANDONED_NP_CREATION.name, ABANDONED_NP_CREATION.props.NUM_OF_NP_ORDERS_ABANDONED, mpProps)
    mpActions.people.set({
      [ABANDONED_NP_CREATION.props.LAST_ABANDONED_NP_ORDER_DATE]: moment(Date.now()).format(DATE_FORMAT)
    })
  }

  return {
    trackNPCreationStarted,
    trackSelectedNPPickupOptions,
    trackAddedNPDetails,
    trackCompletedNPCreation,
    trackAbandonedNPCreation
  }
}

export const mixpanelTrackGeneralOrder = () => {
  const trackOrderCreationAddedDetails = (isBulkUpload, orders, isNinjaPackOrder, isReturnOrder) => {
    if (isNinjaPackOrder) {
      mixpanelTrackNinjaPackOrderCreationEvents().trackAddedNPDetails(isBulkUpload, orders)
    } else {
      mixpanelTrackAddedOrderDetails(isBulkUpload, orders, isReturnOrder)
    }
  }

  const trackOrderCreationCompleted = (ocType, orderInfo) => {
    if (ocType === ORDER_CREATE_TYPES.REGULAR || ocType === ORDER_CREATE_TYPES.RETURN) {
      const isReturn = ocType === ORDER_CREATE_TYPES.RETURN
      const events = isReturn ? ReturnOrderCreationEvents : OrderCreationEvents
      mixpanelTrackCompletedOrderCreation(orderInfo, false, events)
    }
    if (ocType === ORDER_CREATE_TYPES.NINJA_PACK) {
      mixpanelTrackNinjaPackOrderCreationEvents().trackCompletedNPCreation(orderInfo)
    }
  }

  return {
    trackOrderCreationAddedDetails,
    trackOrderCreationCompleted
  }
}

export const mixpanelTrackSettingsClick = toRoute => {
  let mappedSettingsName
  let mappedSettingsProp
  switch (toRoute) {
    case ROUTES.ACCOUNT: {
      mappedSettingsName = accountSettingsEvents.VIEWED_ACCOUNT_SETTINGS.name
      mappedSettingsProp = accountSettingsEvents.VIEWED_ACCOUNT_SETTINGS.props.NUM_OF_VIEW_ACCOUNT_SETTINGS
      break
    }
    case ROUTES.BILLING: {
      mappedSettingsName = billingSettingsEvents.VIEWED_BILLING_SETTINGS.name
      mappedSettingsProp = billingSettingsEvents.VIEWED_BILLING_SETTINGS.props.NUM_OF_VIEW_BILLING_SETTINGS
      break
    }
    case ROUTES.ADDRESS: {
      mappedSettingsName = myAddressSettingsEvents.VIEWED_PICKUP_ADDRESSES_SETTINGS.name
      mappedSettingsProp = myAddressSettingsEvents.VIEWED_PICKUP_ADDRESSES_SETTINGS.props.NUM_OF_VIEW_ADDRESSES_SETTINGS
      break
    }
    case ROUTES.S_ORDER: {
      mappedSettingsName = orderCreateSettingsEvents.VIEWED_ORDER_CREATE_SETTINGS.name
      mappedSettingsProp =
        orderCreateSettingsEvents.VIEWED_ORDER_CREATE_SETTINGS.props.NUM_OF_VIEW_ORDER_CREATE_SETTINGS
      break
    }
    case ROUTES.NOTIFICATION: {
      mappedSettingsName = notificationsSettingsEvents.VIEWED_NOTIFICATIONS_SETTINGS.name
      mappedSettingsProp =
        notificationsSettingsEvents.VIEWED_NOTIFICATIONS_SETTINGS.props.NUM_OF_VIEW_NOTIFICATIONS_SETTINGS
      break
    }
    case ROUTES.IT: {
      mappedSettingsName = ITSettingsEvents.VIEWED_IT_SETTINGS.name
      mappedSettingsProp = ITSettingsEvents.VIEWED_IT_SETTINGS.props.NUM_OF_VIEW_IT_SETTINGS
      break
    }
  }
  mpIncrementalUserProp(mappedSettingsName, mappedSettingsProp)
}

export const mixpanleTrackLanguageChange = language => {
  const { CHANGED_LANGUAGE } = accountSettingsEvents
  mpIncrementalUserProp(CHANGED_LANGUAGE.name, CHANGED_LANGUAGE.props.NUM_OF_LANGUAGE_CHANGE, {
    [CHANGED_LANGUAGE.props.LANGUAGE_CHANGED]: language
  })
}

export const mixpanelTrackChangePasswordEvent = () => {
  mpIncrementalUserProp(
    accountSettingsEvents.CHANGED_PASSWORD.name,
    accountSettingsEvents.CHANGED_PASSWORD.props.NUM_OF_PASSWORD_CHANGED
  )
}

export const mixpanelTrackNotificationSettingsToggleEvents = () => {
  const {
    FY_PARCEL_PICKUP_SUCCESS,
    FY_DELIVER_SUCCESS,
    FYC_PICKUP_SUCCESS,
    FYC_FAILED_PICKUP,
    FYC_DELIVER_SUCCESS,
    FYC_RESCHEDULE_DELIVERY_AFTER_FAILED
  } = notificationsSettingsEvents.CONFIGURED_NOTIFICATIONS.props

  const mappedNotifications = {
    [NotificationContent.TRANSIT_SHIPPER]: FY_PARCEL_PICKUP_SUCCESS,
    [NotificationContent.TRANSIT_CUSTOMER]: FYC_PICKUP_SUCCESS,
    [NotificationContent.COMPLETED_SHIPPER]: FY_DELIVER_SUCCESS,
    [NotificationContent.COMPLETED_CUSTOMER]: FYC_DELIVER_SUCCESS,
    [NotificationContent.PICKUP_FAIL_CUSTOMER]: FYC_FAILED_PICKUP,
    [NotificationContent.DELIVERY_FAIL_CUSTOMER]: FYC_RESCHEDULE_DELIVERY_AFTER_FAILED
  }
  const mixpanelTrackNotificationsSettingsEventOnChange = type => {
    mpIncrementalUserProp(
      notificationsSettingsEvents.CONFIGURED_NOTIFICATIONS.name,
      notificationsSettingsEvents.CONFIGURED_NOTIFICATIONS.props.NUM_OF_NOTIFICATION_CONFIGURED,
      {
        [notificationsSettingsEvents.CONFIGURED_NOTIFICATIONS.props.TYPE_OF_NOTIFICATION_TOGGLED]:
          mappedNotifications[type]
      }
    )
  }
  const mixpanelTrackAllNotificationsSettings = allValues => {
    const props = Object.keys(allValues).reduce((acc, key) => {
      return mappedNotifications[_.snakeCase(key)]
        ? { ...acc, [mappedNotifications[_.snakeCase(key)]]: allValues[key] }
        : acc
    }, {})
    mpActions.people.set(props)
  }

  return {
    mixpanelTrackNotificationsSettingsEventOnChange,
    mixpanelTrackAllNotificationsSettings
  }
}

export const mixpanelTrackMyAddressesSettingEvents = () => {
  const {
    ADDED_PICKUP_ADDRESS,
    EDITED_PICKUP_ADDRESS,
    DELETED_PICKUP_ADDRESS,
    TOGGLED_SHOW_ADDRESS_ON_AIRWAY_BILL
  } = myAddressSettingsEvents
  const trackAddedPickupAddress = () => {
    mpIncrementalUserProp(ADDED_PICKUP_ADDRESS.name, ADDED_PICKUP_ADDRESS.props.NUM_OF_ADDED_ADDRESSES)
  }

  const trackEditedPickupAddress = () => {
    mpIncrementalUserProp(EDITED_PICKUP_ADDRESS.name, EDITED_PICKUP_ADDRESS.props.NUM_OF_LOCATION_EDITS)
  }

  const trackDeletedPickupAddress = () => {
    mpIncrementalUserProp(DELETED_PICKUP_ADDRESS.name, DELETED_PICKUP_ADDRESS.props.NUM_OF_REMOVED_LOCATION)
  }
  const trackToggleShowAddressOnAWB = value => {
    mpTrackEventWithUserProperties(TOGGLED_SHOW_ADDRESS_ON_AIRWAY_BILL.name, {
      [TOGGLED_SHOW_ADDRESS_ON_AIRWAY_BILL.props.SHOW_ADDRESS_ON_AIRWAY_BILL_ENABLED]: value
    })
  }

  return {
    trackAddedPickupAddress,
    trackEditedPickupAddress,
    trackDeletedPickupAddress,
    trackToggleShowAddressOnAWB
  }
}

export const mixpanelTrackOrderCreateSettingsEvents = () => {
  const { ADDED_NEW_TEMPLATE, EDITED_EXISTING_TEMPLATE, DELETED_EXISTING_TEMPLATE } = orderCreateSettingsEvents

  const trackAddNewTemplate = () => {
    mpIncrementalUserProp(ADDED_NEW_TEMPLATE.name, ADDED_NEW_TEMPLATE.props.NUM_OF_ADDED_TEMPLATES)
  }

  const trackEditExistingTemplate = () => {
    mpIncrementalUserProp(EDITED_EXISTING_TEMPLATE.name, EDITED_EXISTING_TEMPLATE.props.NUM_OF_EDITED_TEMPLATE)
  }

  const trackDeleteExistingTemplate = () => {
    mpIncrementalUserProp(DELETED_EXISTING_TEMPLATE.name, DELETED_EXISTING_TEMPLATE.props.NUM_OF_REMOVED_TEMPLATES)
  }

  return {
    trackAddNewTemplate,
    trackEditExistingTemplate,
    trackDeleteExistingTemplate
  }
}

export const mixpanelTrackITSettingsEvents = () => {
  const { SELECTED_PRINTER, DOWNLOADED_PRINTER_APP } = ITSettingsEvents

  const trackSelectedPrinter = printerName => {
    mpIncrementalUserProp(SELECTED_PRINTER.name, SELECTED_PRINTER.props.NUM_OF_PRINTER_SELECTED)
    mpActions.track(SELECTED_PRINTER.name, {
      [SELECTED_PRINTER.props.PRINTER_NAME]: printerName
    })
  }

  const trackDownloadedPrinterApp = () => {
    mpIncrementalUserProp(DOWNLOADED_PRINTER_APP.name, DOWNLOADED_PRINTER_APP.props.NUM_OF_DOWNLOADED_PRINTER_APP)
  }

  return {
    trackSelectedPrinter,
    trackDownloadedPrinterApp
  }
}

export const mixpanelTrackBillingSettingsEvents = () => {
  const {
    TOPPED_UP_WALLET,
    VIEWED_BILLING_HISTORY,
    DOWNLOADED_TRANSACTION_REPORT,
    SENT_INVOICE_DETAILS
  } = billingSettingsEvents

  const trackWalletTopUp = ammount => {
    mpIncrementalUserProp(TOPPED_UP_WALLET.name, TOPPED_UP_WALLET.props.NUM_OF_TOP_UPS_TO_WALLET, {
      [TOPPED_UP_WALLET.props.AMMOUNT_TOPPED_UP]: ammount
    })
  }

  const trackViewBillingHistory = () => {
    mpIncrementalUserProp(VIEWED_BILLING_HISTORY.name, VIEWED_BILLING_HISTORY.props.NUM_OF_VIEW_BILLING_HISTORY)
  }

  const trackDownloadTransactionHistory = () => {
    mpIncrementalUserProp(
      DOWNLOADED_TRANSACTION_REPORT.name,
      DOWNLOADED_TRANSACTION_REPORT.props.NUM_OF_DOWNLOADED_TRANSACTION_REPORTS
    )
  }

  const trackSentInvoiceDetails = () => {
    mpIncrementalUserProp(SENT_INVOICE_DETAILS.name, SENT_INVOICE_DETAILS.props.NUM_OF_SENT_INVOICE_DETAILS)
  }

  return {
    trackWalletTopUp,
    trackViewBillingHistory,
    trackDownloadTransactionHistory,
    trackSentInvoiceDetails
  }
}
export const mixpanelViewedOrderDetails = () =>
  mpIncrementalUserProp(
    ManageOrdersEvents.VIEWED_ORDER.name,
    ManageOrdersEvents.VIEWED_ORDER.props.NUMBER_OF_VIEW_ORDER_DETAILS
  )

export const mixpanelViewedWallet = () =>
  mpIncrementalUserProp(
    walletEvents.VIEWED_WALLET.name,
    walletEvents.VIEWED_WALLET.props.NUM_OF_USERS_VIEWED_NV_WALLETS
  )

export const mixpanelWithdrawalRequest = () =>
  mpIncrementalUserProp(
    walletEvents.WITHDRAWAL_REQUEST.name,
    walletEvents.WITHDRAWAL_REQUEST.props.NUM_OF_WITHDRAWAL_REQUEST_MADE
  )

export const mixpanelViewedWithdrawal = () =>
  mpIncrementalUserProp(
    walletEvents.VIEWED_WITHDRAWAL.name,
    walletEvents.VIEWED_WITHDRAWAL.props.NUM_OF_VIEW_WITHDRAWAL_HISTORY
  )

export const mixpanelViewedStatements = () =>
  mpIncrementalUserProp(
    walletEvents.VIEWED_STATEMENTS.name,
    walletEvents.VIEWED_STATEMENTS.props.NUM_OF_VIEWS_NV_WALLET_STATEMENTS
  )

export const mixpanelViewedAllOrders = () =>
  mpIncrementalUserProp(
    ManageOrdersEvents.VIEWED_ALL_ORDERS.name,
    ManageOrdersEvents.VIEWED_ALL_ORDERS.props.NUMBER_OF_VIEW_ORDERS
  )

export const mixpanelEditedOrder = changedFields => {
  if (changedFields.length === 0) {
    return
  }
  const editedField = getMappedEditedField(changedFields)

  mpActions.people.increment({
    [ManageOrdersEvents.EDITED_ORDERS.props.NUMBER_OF_ORDERS_EDITED]: 1
  })

  mpActions.track(ManageOrdersEvents.EDITED_ORDERS.name, {
    [ManageOrdersEvents.EDITED_ORDERS.props.EDITED_FIELD]: editedField
  })
}

export const mixpanelCancelledOrder = data => {
  mpActions.people.increment({
    [ManageOrdersEvents.CANCELLED_ORDER.props.NUMBER_OF_ORDERS_CANCELLED]: 1
  })

  const pickUpStatus =
    _.filter(data.events, event => event.type === EVENTS.DRIVER_PICKUP_SCAN).length === 0
      ? 'Before Pickup'
      : 'After Pickup'

  mpActions.track(ManageOrdersEvents.CANCELLED_ORDER.name, {
    [ManageOrdersEvents.CANCELLED_ORDER.props.BEFORE_AFTER_PICKUP]: pickUpStatus
  })
}

export const mixpanelSubmittedEditedCod = error => {
  mpActions.track(ManageOrdersEvents.SUBMITTED_EDITED_COD.name, {
    [ManageOrdersEvents.SUBMITTED_EDITED_COD.props.ERROR_RETURNED]: error || '-'
  })
}

export const mixpanelEditedCodAmount = parcelStatus => {
  mpActions.track(ManageOrdersEvents.EDITED_COD_AMOUNT.name, {
    [ManageOrdersEvents.EDITED_COD_AMOUNT.props.PARCEL_STATUS]: parcelStatus
  })

  mpActions.people.increment({
    [ManageOrdersEvents.EDITED_COD_AMOUNT.props.NUMBER_OF_ATTEMPTS]: 1
  })
}

export const mixpanelViewedCodRevisionLog = number => {
  mpActions.track(ManageOrdersEvents.VIEWED_COD_REVISION_LOG.name, {
    [ManageOrdersEvents.VIEWED_COD_REVISION_LOG.props.NUMBER_OF_REVISIONS]: number
  })
}

export const mixpanelPrintedReport = (type, by, extraFilters) => {
  let event = ReportsEvents.PRINTED_COD_RECONCILIATION_REPORT
  if (type === 'deliveryStatus') {
    event = ReportsEvents.PRINTED_DELIVERY_STATUS_REPORT
  } else if (type === 'deliveryFailure') {
    event = ReportsEvents.PRINTED_DELIVERY_FAILURE_REPORT
  }

  mpActions.people.increment({
    [event.props.NUMBER]: 1
  })

  let props = {
    [event.props.TYPE]: deliveryReportsTypesMappings[by] ?? 'By CSV Upload'
  }

  if (Object.values(event.props).includes(REPORTS_EXTRA_FILTERS_PROPS_NAMES.SERVICE_LEVEL_FILTER)) {
    props = {
      ...props,
      [event.props.SERVICE_LEVEL_FILTER]: strOrDefault(
        extraFilters?.[REPORTS_EXTRA_FILTERS_KEYS.SERVICE_LEVEL]?.map(key => SERVICE_LEVEL_FILTER_TEXT[key])?.toString()
      )
    }
  }

  if (Object.values(event.props).includes(REPORTS_EXTRA_FILTERS_PROPS_NAMES.PARCEL_STATUS_FILTER)) {
    props = {
      ...props,
      [event.props.PARCEL_STATUS_FILTER]: strOrDefault(
        extraFilters?.[REPORTS_EXTRA_FILTERS_KEYS.GRANULAR_STATUS]?.toString()
      )
    }
  }

  mpActions.track(event.name, props)
}

export const mixpanelCanceledPickup = () =>
  mpIncrementalUserProp(
    ManagePickups.CANCELLED_PICKUP.name,
    ManagePickups.CANCELLED_PICKUP.props.NUMBER_OF_CANCELLED_PICKUPS
  )

export const mixpanelViewedPickup = () =>
  mpIncrementalUserProp(ManagePickups.VIEWED_PICKUP.name, ManagePickups.VIEWED_PICKUP.props.NUMBER_OF_VIEWED_PICKUPS)

export const mixpanelStartedDashboardTour = () =>
  mpIncrementalUserProp(DashboardTour.TOUR_STARTED.name, DashboardTour.TOUR_STARTED.props.NUMBER_OF_TOURS)

export const mixpanelPrintedAirwayBill = (status, numberOfBills) => {
  const format = numberOfBills !== null ? numberOfBills + ' Bill Per Page' : 'Thermal Print Format'

  mpActions.people.increment({
    [PrintingEvent.PRINTED_AIRWAY_BILL.props.NUMBER_OF_ATTEMPTS]: 1
  })

  mpActions.track(PrintingEvent.PRINTED_AIRWAY_BILL.name, {
    [PrintingEvent.PRINTED_AIRWAY_BILL.props.STATUS]: strOrDefault(status),
    [PrintingEvent.PRINTED_AIRWAY_BILL.props.FORMAT]: strOrDefault(format)
  })
}

export const mixpanelSearchOrder = (searchValueLength, totalResults) => {
  mpActions.people.increment({
    [SearchOrders.SEARCHED_BASIC_ORDER.props.NUMBER_OF_SEARCHES]: 1
  })

  mpActions.track(SearchOrders.SEARCHED_BASIC_ORDER.name, {
    [SearchOrders.SEARCHED_BASIC_ORDER.props.NUMBER_OF_SEARCH_RESULT]: numOrDefault(totalResults),
    [SearchOrders.SEARCHED_BASIC_ORDER.props.CHARACTER_LENGTH]: numOrDefault(searchValueLength)
  })
}

export const mixpanelSearchAdvancedOrder = (searchFilters, searchRange, totalResults, searchType) => {
  mpActions.people.increment({
    [SearchOrders.ADVANCE_SEARCH.props.NUMBER_OF_SEARCHES]: 1
  })

  let numOfDays
  if (searchRange.startTime && searchRange.endTime) {
    const startMoment = moment(searchRange.startTime)
    const endMoment = moment(searchRange.endTime)
    numOfDays = endMoment.diff(startMoment, 'days')
  }

  const parcelStatusList = searchFilters.filter(filter => filter.field === 'granular_status')?.[0]?.values

  mpActions.track(SearchOrders.ADVANCE_SEARCH.name, {
    [SearchOrders.ADVANCE_SEARCH.props.NUMBER_OF_SEARCH_RESULT]: numOrDefault(totalResults),
    [SearchOrders.ADVANCE_SEARCH.props.SEARCH_PERIOD]: numOrDefault(numOfDays),
    [SearchOrders.ADVANCE_SEARCH.props.SEARCH_TYPE]:
      searchType !== null ? advancedSearchTypesMappings[searchType] : strOrDefault(null),
    [SearchOrders.ADVANCE_SEARCH.props.PARCEL_STATUS]: parcelStatusList ?? []
  })
}

export const mixpanelTrackInternationalOrder = () => {
  const { USER_SAVES_DETAILS_FOR_INTR_ORDER } = InternationalOrderEvents

  const trackSaveOrderDetails = () => {
    mpActions.track(USER_SAVES_DETAILS_FOR_INTR_ORDER.name, {
      [USER_SAVES_DETAILS_FOR_INTR_ORDER.props.INTERNATIONAL_ORDER]: true
    })
  }

  const trackNumberOfOrderCreation = parcelNum => {
    const { COMPLETED_INTR_ORDER_CREATION } = InternationalOrderEvents
    mpIncrementalUserProp(
      COMPLETED_INTR_ORDER_CREATION.name,
      COMPLETED_INTR_ORDER_CREATION.props.NUM_OF_INTR_ORDER_CREATED,
      { [COMPLETED_INTR_ORDER_CREATION.props.NUM_OF_INTER_PARCEL_CREATED]: parcelNum }
    )
  }

  return {
    trackSaveOrderDetails,
    trackNumberOfOrderCreation
  }
}

export const mixpanelTrackGenerateScheduledReport = (
  reportType,
  orderStatus,
  serviceTypes,
  parcelStatuses,
  frequency,
  frequencyValue,
  numOfEmails
) => {
  const { GENERATE_NEW_REPORT } = ScheduledReportEvents
  const map = {
    [GENERATE_NEW_REPORT.props.REPORT_TYPE]: MAP_REPORT_TYPE_TRACKING_NAME[reportType],
    [GENERATE_NEW_REPORT.props.FREQUENCY]: MAP_REPORT_SCHEDULES_TYPE_TRACKING_NAME[frequency],
    [GENERATE_NEW_REPORT.props.NO_EMAIL_ADDRESSES]: numOfEmails
  }
  if (reportType === REPORT_TYPE.codReconciliation) {
    map[GENERATE_NEW_REPORT.props.ORDER_STATUS] = MAP_COD_DATE_TYPES_TRACKING_NAME[orderStatus]
  }
  if (serviceTypes?.length) {
    map[GENERATE_NEW_REPORT.props.SERVICE_LEVEL] = serviceTypes.map(serviceType => serviceType.value)
  }
  if (parcelStatuses?.length) {
    map[GENERATE_NEW_REPORT.props.PARCEL_STATUS] = parcelStatuses.map(parcelStatus => parcelStatus.text)
  }
  if (frequency === REPORT_SCHEDULES_TYPE.MONTHLY) {
    map[GENERATE_NEW_REPORT.props.DAY_SELECTED] = frequencyValue
  } else if (frequency === REPORT_SCHEDULES_TYPE.WEEKLY) {
    map[GENERATE_NEW_REPORT.props.DAY_SELECTED] = MAP_DAY_OF_WEEK_TRACKING_NAME[frequencyValue]
  }
  mpActions.track(GENERATE_NEW_REPORT.name, map)
}

export const mixpanelTrackDeleteScheduledReport = () => {
  mpActions.track(ScheduledReportEvents.DELETE_SCHEDULED_REPORT.name)
}

export const mixpanelTrackCloseDisproPopup = origination => {
  mpActions.track(DisproEvents.CLOSED_DISPRO_POP_UP.name, {
    [DisproEvents.CLOSED_DISPRO_POP_UP.props.ORIGINATION]: origination
  })
}

export const mixpanelTrackViewDisproTAndC = () => {
  mpActions.track(DisproEvents.VIEWED_DISPRO_T_AND_C.name)
}

export const mixpanelTrackViewPaymentFaq = () => {
  mpActions.track(PaymentsEvents.VIEWED_PAYMENTS_FAQ.name, {
    [PaymentsEvents.VIEWED_PAYMENTS_FAQ.props.SOURCE]: Source.PAYMENT
  })
}

export const mixpanelTrackViewCodFaq = () => {
  mpActions.track(PaymentsEvents.VIEWED_COD_FAQ.name, {
    [PaymentsEvents.VIEWED_COD_FAQ.props.SOURCE]: Source.PAYMENT
  })
}

export const mixpanelTrackViewPayments = isHomePage =>
  mpIncrementalUserProp(
    PaymentsEvents.VIEWED_PAYMENTS.name,
    PaymentsEvents.VIEWED_PAYMENTS.props.NUMBER_OF_VIEW_PAYMENTS,
    { [PaymentsEvents.VIEWED_PAYMENTS.props.ENTRY_POINT]: isHomePage ? EntryPoint.HOMEPAGE : EntryPoint.SIDEBAR }
  )

export const mixpanelTrackViewOrdersWithinCOD = () => {
  mpActions.track(PaymentsEvents.VIEWED_ORDERS_WITHIN_COD_COLLECTED.name)
}

export const mixpanelTrackViewOrdersWithinFees = () => {
  mpActions.track(PaymentsEvents.VIEWED_ORDERS_WITHIN_FEES.name)
}

export const mixpanelTrackViewHelpdesk = () => {
  mpActions.track(HelpdeskEvents.VIEWED_HELPDESK.name)
}

export const mixpanelTrackViewHelpCentre = () => {
  mpActions.track(HelpdeskEvents.VIEWED_FAQS.name)
}

export const mixpanelTrackSelectIssueType = issueType => {
  mpActions.track(ShipperSupportEvents.SELECTED_ISSUE_TYPE.name, {
    [ShipperSupportEvents.SELECTED_ISSUE_TYPE.props.ISSUE_TYPE]: issueType
  })
}

export const mixpanelTrackSelectSubIssue = subIssueType => {
  mpActions.track(ShipperSupportEvents.SELECTED_SUB_ISSUE.name, {
    [ShipperSupportEvents.SELECTED_SUB_ISSUE.props.SUB_ISSUE_TYPE]: subIssueType
  })
}

export const mixpanelTrackClickSubmitHelpdeskTicket = status => {
  mpActions.track(ShipperSupportEvents.CLICKED_SUBMIT_HELPDESK_TICKET.name, {
    [ShipperSupportEvents.CLICKED_SUBMIT_HELPDESK_TICKET.props.STATUS]: status
  })
}

export const mixpanelTrackEditedHelpdeskDeliveryInstructions = () => {
  mpActions.track(ShipperSupportEvents.EDITED_HELPDESK_DELIVERY_INSTRUCTIONS.name)
}

export const mixpanelTrackSelectedOrderTypeFilter = orderType => {
  mpActions.track(orderTypeFilter.SELECTED_ORDER_TYPE.name, {
    [orderTypeFilter.SELECTED_ORDER_TYPE.props.ORDER_TYPE]: orderType
  })
}

export const setNPOrderDetailsProps = (props, isBulkUpload, orders) => {
  const { ADDED_NP_DETAILS } = OrderCreationNinjaPackEvents
  if (!isBulkUpload) {
    const order = orders[0]
    props = {
      ...props,
      [ADDED_NP_DETAILS.props.INPUT_TYPE]: 'Single',
      [ADDED_NP_DETAILS.props.RECIPIENTS_DETAILS]: true,
      [ADDED_NP_DETAILS.props.RECIPIENT_EMAIL_ADDRESS]: boolOf(order?.email),
      [ADDED_NP_DETAILS.props.PARCEL_DESCRIPTION]: strOrDefault(order?.parcelDescription),
      [ADDED_NP_DETAILS.props.COD_AMOUNT]: numOrDefault(order?.cod),
      [ADDED_NP_DETAILS.props.INSTRUCTIONS_FOR_DRIVER]: strOrDefault(orders[0]?.instructions),
      [ADDED_NP_DETAILS.props.NP_ID]: boolOf(order?.requestedTrackingNumber)
    }
  } else {
    props = {
      ...props,
      [ADDED_NP_DETAILS.props.INPUT_TYPE]: 'Bulk'
    }
  }
  return props
}

export const setOrderDetailsProps = (props, isBulkUpload, order) => {
  if (!isBulkUpload) {
    props = {
      ...props,
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.INPUT_TYPE]: 'Single',
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.RECIPIENT_EMAIL_ADDRESS]: boolOf(order?.email),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.PARCEL_WEIGHT]: parseInt(numOrDefault(order?.weight)),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.PARCEL_SIZE]: strOrDefault(order?.size),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.REQUESTED_TRACKING_ID]: boolOf(order?.requestedTrackingNumber),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.INSURANCE_FEE]: parseInt(numOrDefault(order?.insuredValue)),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.PARCEL_DESCRIPTION]: strOrDefault(order?.parcelDescription),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.COD_AMOUNT]: parseInt(numOrDefault(order?.cod)),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.NUMBER_OF_CARTONS_REQUIRED]: parseInt(order?.noOfCartons),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.NUMBER_OF_GRN_REQUIRED]: boolOf(order?.grnRequired),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.NUMBER_OF_RDO_REQUIRED]: boolOf(order?.rdoRequired),
      [OrderCreationEvents.ADDED_ORDER_DETAILS.props.SHIPPER_ORDER_NUMBER]: boolOf(order?.shipperOrderNumber)
    }
  } else {
    props = {
      ...props,
      [OrderCreationEvents.ABANDONED_ORDER_CREATION.props.INPUT_TYPE]: 'Bulk'
    }
  }
  return props
}

export const getPickupProps = (pickupType, reservation, volume) => {
  let props = {
    [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.PICKUP_TYPE]: strOrDefault(pickupTypesMappings[pickupType])
  }
  if (pickupType === PICKUP_TYPES.SCHEDULED) {
    props = {
      ...props,
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.PICKUP_ADDRESS]: true,
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.RETURN_ADDRESS]: false,
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.PICKUP_DATE]: strOrDefault(
        formatISODate(reservation?.readyDatetime?.split(' ')[0])
      ),
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.PICKUP_TIME]: getPickupTimeRange(reservation),
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.INSTRUCTIONS_FOR_DRIVER]: strOrDefault(
        reservation?.comments
      ),
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.VEHICLE_TYPE]: strOrDefault(volumeVehicleMappings[volume])
    }
  } else {
    props = {
      ...props,
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.PICKUP_ADDRESS]: false,
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.RETURN_ADDRESS]: true,
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.PICKUP_DATE]: strOrDefault(null),
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.PICKUP_TIME]: strOrDefault(null),
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.INSTRUCTIONS_FOR_DRIVER]: strOrDefault(null),
      [OrderCreationEvents.SELECTED_PICKUP_SELECTION.props.VEHICLE_TYPE]: strOrDefault(null)
    }
  }

  return props
}

const getPickupTimeRange = rsv => {
  if (rsv?.readyDatetime?.split(' ')[1] && rsv?.latestDatetime?.split(' ')[1]) {
    return rsv?.readyDatetime?.split(' ')[1] + ' - ' + rsv?.latestDatetime?.split(' ')[1]
  }
  return strOrDefault(null)
}

const getShipperFromUser = user => user.shippers.find(u => u.id === user.defaultShipperId) || user.shippers[0] || {}
