import React from 'react'
import { PropTypes } from 'prop-types'
import { Alert } from 'components/Alert'
import { Colors } from 'themes'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { Icon } from '@nv/react-commons/src/Components'
import { GUIDELINE_LINKS, SUPPORTED_COUNTRIES } from './constants'
import Config from 'configs'
import { FormattedMessage } from 'react-intl'

// Displays an alert with <PackingMessage/> to check packing guidelines for all shippers in select countries
const PackingAlert = ({ intl, country }) => {
  if (!isAlertActive(country)) {
    return null
  }
  const locale = intl.locale || intl.defaultLocale
  const link = getLink(locale, country)
  const msg = <PackingMessage link={link} />
  const icon = <Icon icon={faExclamationCircle} style={{ color: '#5c6166' }} />

  return <Alert type='info' message={msg} closable={false} showIcon icon={icon} />
}

const PackingMessage = ({ link }) => {
  const style = {
    color: Colors.cherryRed
  }

  return (
    /*
    MDN: Linking to another page with target="_blank" will run the new page in the same process as your page.
    If the new page executes JavaScript, your page's performance may suffer.
    This can also be avoided by using rel="noreferrer noopener".
     */
    <>
      <FormattedMessage id='have_you_read' />
      <a style={style} target='_blank' rel='noreferrer noopener' href={link}>
        <FormattedMessage id='packaging_guidelines' />
      </a>
      <FormattedMessage id='packaging_guidelines_postamble' />
    </>
  )
}

// Returns a country's packing guideline string url. Note: country is guaranteed to be in SUPPORTED_COUNTRIES.
function getLink (locale, country) {
  const ctry = country.toLowerCase()
  const regx = /^(\w{2})([-_](\w{2}))?$/
  const match = locale.match(regx)

  if (!match) {
    throw new Error(`Unknown locale: ${locale}`)
  }

  const lang = match[1]

  // First match by country and language
  if (lang && ctry && GUIDELINE_LINKS[ctry]) {
    const link = GUIDELINE_LINKS[ctry][lang]
    if (link) {
      return link
    }
  }

  return GUIDELINE_LINKS[ctry].en
}

export function isAlertActive (country) {
  const c = country.toLowerCase()
  return Config.ENVIRONMENT !== 'cd' && SUPPORTED_COUNTRIES.includes(c)
}

PackingAlert.propTypes = {
  intl: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired
}

PackingMessage.propTypes = {
  link: PropTypes.string
}

export { PackingAlert }
