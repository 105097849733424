import React from 'react'
import { Text } from 'components/Text'
import { InlineText, ListHeader, PaddingText, StyledRollDiv } from './styles'
import { T } from '@nv/react-commons/src/Components'

export const ListBoxSummary = ({ orders }) => {
  return (
    <div>
      <ListHeader>
        <Text id='total_boxes_uppercase' type='bold' size={16} />
        <Text type='bold' size={16}>
          {orders.length}
        </Text>
      </ListHeader>
      <StyledRollDiv>
        {!orders.length ? (
          <InlineText>
            <T id='key_in_box' />
          </InlineText>
        ) : (
          orders.map((order, index) => (
            <PaddingText key={order.boxNo} type='bold'>
              <T id='box_uppercase' /> {index + 1}
            </PaddingText>
          ))
        )}
      </StyledRollDiv>
    </div>
  )
}
