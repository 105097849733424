/* eslint-disable max-len */
import { PICKUP_TYPES } from 'containers/PickupType/constants'

export const INTERNAL_STEP_STATUS = {
  current: 'current',
  completed: 'completed',
  unvisited: 'unvisited'
}

export enum MMCC_OC_STEP {
  SERVICE_DETAILS,
  ORDERS,
  COMMERCIAL_INVOICE,
  DELIVERY_ORDER,
  REVIEW,
  DONE
}

export enum FILE_UPLOAD_STATUS {
  READY_TO_UPLOAD,
  UPLOADED,
  IN_PREVIEW
}

export const enum SUB_STEP_STATUS {
  IN_ADDING,
  IN_EDTING,
  IN_LIST,
  CLOSE
}

export enum KEYBOARD_INPUT_STATUS {
  IN_SHOWING,
  IN_PREVIEW
}

export enum SUPPORTED_DOCUMENT_STATUS {
  READY_TO_UPLOAD,
  UPLOADED_SUCCESS,
  UPLOADED_FAIL,
  IN_PREVIEW,
  SKIP
}

export const TRANSLATION_KEY_BY_MMCC_STEP = {
  [MMCC_OC_STEP.SERVICE_DETAILS]: 'service_details',
  [MMCC_OC_STEP.ORDERS]: 'orders',
  [MMCC_OC_STEP.COMMERCIAL_INVOICE]: 'commercial_invoice',
  [MMCC_OC_STEP.DELIVERY_ORDER]: 'delivery_order',
  [MMCC_OC_STEP.REVIEW]: 'review'
}

export const START = 0

export const LIMIT = 100

export const SERVICE_SUB_STEPS = {
  NONE: 'NONE',
  SENDER_ADDRESS: 'SENDER_ADDRESS',
  SERVICE: 'SERVICE',
  CUSTOMS_CLEARANCE_TYPE: 'CUSTOMS_CLEARANCE_TYPE',
  DELIVERY_TYPE: 'DELIVERY_TYPE',
  RECIPIENT_ADDRESS: 'RECIPIENT_ADDRESS',
  PICKUP_TYPE: 'PICKUP_TYPE',
  PICKUP_ADDRESS: 'PICKUP_ADDRESS',
  TIME: 'TIME',
  VOLUMES: 'VOLUMES',
  CUSTOM_CURRENCY: 'CUSTOM_CURRENCY'
}

export const OUTSIDE_ASIA_COUNTRY_CODES = {
  CN: 'CN'
}

export const PICKUP_METADATA = {
  [PICKUP_TYPES.SCHEDULED]: {
    title: 'scheduled_pickup',
    description: 'scheduled_pickup_boxes_desc'
  },
  [PICKUP_TYPES.NO]: {
    title: 'no_pickup',
    description: 'no_pickup_boxes_desc'
  }
}

export enum CCType {
  B2B,
  B2C
}

export const TRANSLATION_KEY_BY_CC_TYPE = {
  [CCType.B2B]: 'custom_clearance_b2b',
  [CCType.B2C]: 'custom_clearance_b2c'
}

export enum OCMethod {
  KEYBOARD = 7,
  UPLOAD = 8
}

export const FIELD_KEYS = {
  BOX_ID: 'Shipper Box ID',
  BOX_WEIGHT: "Box's Weight (Optional)",
  BOX_LENGTH: "Box's Length (Optional)",
  BOX_HEIGHT: "Box's Height (Optional)",
  BOX_WIDTH: "Box's Width (Optional)",
  GOODS_DESCRIPTION: 'Goods Description',
  NO_OF_PARCELS: 'No. of Parcels',
  PARCEL_TRACKING_ID: 'Parcel tracking ID',
  TO_NAME: 'To Name',
  TO_ADDRESS: 'To Address',
  TO_CONTACT_NUMBER: 'To Contact Number',
  ITEM_QUANTITY: 'Item Quantity',
  ITEM_DESCRIPTION: 'Item Description',
  ITEM_VALUE: 'Item Value'
}

export const FLOAT_NUMBER_AND_GREATER_THAN_0 = /^(?!0+(\.0+)?$)\d+(\.\d+)?$/

export const INTEGER_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000 = /^(?!0+$)\d{1,3}$/

export const FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000 = /^(?!0\d|0\.0+$|1000)([1-9]\d{0,2}(\.\d+)?|0\.\d*[1-9]\d*)$/
