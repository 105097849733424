/**
 *
 * Asynchronously loads the component for Shopify
 *
 */

import React from 'react'
import loadable from 'utils/loadable'
import { Loading } from 'components/Loading'

export default loadable({
  loader: () => import('./index' /* webpackChunkName: "Shopify" */),
  loading: <Loading />
})
