import { OC_SERVICE_TYPES } from 'containers/Base/constants'
import {
  selectIsScheduledPickupAvailable,
  selectShipper,
  selectCountry,
  selectIsCorporateBranch,
  selectIsCorporateHQ,
  selectPaymentSettings,
  selectAllowedDeliveryTimeslots
} from 'containers/Base/selectors'
import _ from 'lodash'
import { createSelector } from 'reselect'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { OCForm } from 'components/OCForm'
import { TimeSlotSelector } from 'components/TimeSlotSelector'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { ORDER_CREATE_TYPES } from 'containers/OrderTypeModal/constants'
import csvMapping from 'utils/csvMapping'
import { transformSize } from 'utils/csvMappingFields'
import { generateErrorData } from './utils'
import { formatHour } from 'utils/time'
import { includesAny } from 'utils/includesAny'
import { selectPrepaidBalance } from 'containers/WalletProvider/selectors'
import { selectIsCreatingShopifyOrders } from 'containers/Shopify/Redux/selectors'
import {
  selectIsPrepaidShipper,
  selectOcSettings,
  selectAvailableServiceTypes,
  selectMasterAvailableServiceTypes,
  selectIsSubShipperAccount
} from '../Base/selectors'
import { OCPickupOptions, PICKUP_TYPES_OPTIONS_KEYS } from './constants'
import { PAYMENT_SETTINGS } from 'constants/payment/paymentSettings'
import { processTimeslotString } from 'utils/processTimeslotString'

const { selector } = SelectorUtils

export const PICKUP_TYPE = 'pickupType'
export const PICKUP_ADDRESS = 'pickupAddress'
export const RESERVATION = 'reservation'
export const VOLUME = 'volume'

export const selectIsPricingStepValid = () =>
  createSelector(
    selectIsOCTypeNinjaPack(),
    selectIsAllowedToUseAccountBalance(),
    selectPrepaidBalance(),
    selectPaymentSettings(),
    selector('global', 'orderCreate', 'pricing', 'data', 'total')(),
    (isNinjaPackOrder, isAllowedToUseAccountBalance, balance, paymentSettings, total) => {
      return (
        isNinjaPackOrder ||
        !isAllowedToUseAccountBalance ||
        paymentSettings === PAYMENT_SETTINGS.nettCod ||
        (total >= 0 && total <= balance)
      )
    }
  )

export const selectNoCoverageDetailsError = () =>
  createSelector(
    selector('entity', 'coverageDetails', 'error')(),
    selector('entity', 'coverageDetails', 'data', 'inService')(),
    (coverageError, isAddressInCoverage) => {
      return !coverageError && isAddressInCoverage
    }
  )

export const selectIsPickupStepValid = () =>
  createSelector(
    selector('global', 'orderCreate', PICKUP_TYPE)(),
    selector('global', 'orderCreate', PICKUP_ADDRESS)(),
    selector('global', 'orderCreate', RESERVATION)(),
    selector('global', 'orderCreate', VOLUME)(),
    selectNoCoverageDetailsError(),
    (pickupType, pickupAddress, reservations, volumes, noCoverageError) => {
      if (!_.includes([PICKUP_TYPES.SCHEDULED, PICKUP_TYPES.ON_DEMAND], pickupType)) {
        return !!pickupAddress
      } else {
        const isAddressValid = !!pickupAddress
        const isVolumeValid = !!volumes
        if (pickupType === PICKUP_TYPES.SCHEDULED) {
          const isMilkRun = pickupAddress?.isMilkRun
          const isReservationValid = isMilkRun || reservations
          return isAddressValid && isReservationValid && isVolumeValid
        } else if (pickupType === PICKUP_TYPES.ON_DEMAND) {
          return isAddressValid && noCoverageError && isVolumeValid
        }
        return false
      }
    }
  )

export const selectOCType = () => selector('global', 'orderCreate', 'ocType')()

export const selectIsOC = () =>
  createSelector(selectOCType(), type => {
    return Boolean(type)
  })

export const selectIsOCTypeNinjaPack = () =>
  createSelector(selectOCType(), type => {
    return type === ORDER_CREATE_TYPES.NINJA_PACK
  })

export const selectIsOCTypeCorporateAWB = () =>
  createSelector(selectOCType(), type => {
    return type === ORDER_CREATE_TYPES.CORPORATE_AWB
  })

export const selectIsOCTypeRegular = () =>
  createSelector(selectOCType(), type => {
    return type === ORDER_CREATE_TYPES.REGULAR
  })

export const selectIsOCTypeReturn = () =>
  createSelector(selectOCType(), type => {
    return type === ORDER_CREATE_TYPES.RETURN
  })

// TODO: rename this to be clearer
export const selectIsValid = () =>
  createSelector(
    selectIsPickupStepValid(),
    selectIsPricingStepValid(),
    selectOrders({ isReturnOrder: false }),
    (isPickupValid, isPricingValid, orders) => {
      return isPickupValid && isPricingValid && !_.isEmpty(orders)
    }
  )

export const selectChosenMappingId = () => selector('global', 'orderCreate', 'csvMapping', 'id')()

export const selectIsBulkUpload = () => createSelector(selectChosenMappingId(), id => !!id)

export const selectIsValidMapping = () =>
  createSelector(
    selectChosenMappingId(),
    selector('entity', 'mappings', 'data')(),
    selectShipper(),
    selectIsCodSupported(),
    selectIsAllowedToUseAccountBalance(),
    selectIsB2BBundleSupported(),
    selectAllowedDeliveryTimeslots(),
    (
      id,
      mappings,
      shipper,
      isCodSupported,
      isAllowedToUseAccountBalance,
      isB2BBundleSupported,
      allowedDeliveryTimeslots
    ) => {
      const mapping = _.find(mappings, { id })
      if (mapping) {
        const serviceType = mapping.type
        const fields = csvMapping.getOrderFields({
          serviceType,
          shipper,
          isCodSupported,
          isAllowedToUseAccountBalance,
          isB2BBundleSupported,
          allowedDeliveryTimeslots
        })
        const headers = csvMapping.identifyFieldsPosition(fields, mapping.data)
        return _.reduce(headers, (r, { compulsory, position }) => (compulsory ? r && position !== -1 : r), true)
      }
      return false
    }
  )

export const selectCod = ({ isReturnOrder } = { isReturnOrder: false }) =>
  createSelector(selectOrders({ isReturnOrder }), selectIsCreatingShopifyOrders(), orders => {
    if (orders) {
      return _.sumBy(orders, o => {
        const floatCod = parseFloat(o.cod)
        return isNaN(floatCod) ? 0 : floatCod
      })
    }
    return 0
  })

// TODO: Lim/Teddy refactor this and merge with getDeliveryTimeslot in the csvMapping
export const transformDeliveryTimeslot = timeslot => {
  if (/^([01]\d|2[0-3]):?([0-5]\d)-([01]\d|2[0-3]):?([0-5]\d)$/.test(timeslot)) {
    return timeslot // Return correct string constants as-is, without trying to transform it
  }
  return (processTimeslotString(timeslot) || TimeSlotSelector.DEFAULT_TIMESLOT).map(hour => formatHour(hour)).join('-')
}

export const selectPrefilledOrderReturn = () =>
  createSelector(selector('global', 'orderReturn')(), (orderReturn = {}) => {
    const { order, trackingId, returnAddresses, returnTime } = orderReturn
    const phoneNumber = returnAddresses?.contact
    return {
      requestedTrackingNumber: trackingId?.trackingId,
      deliveryType: returnTime?.returnTimeslot?.deliveryType,
      instructions: returnTime?.returnTimeslot?.instructions,
      deliveryTimeslot: transformDeliveryTimeslot(returnTime?.returnTimeslot?.deliveryTimeslot),
      size: order?.dimensions?.size,
      ..._.omit(returnAddresses, 'contact'),
      phoneNumber,
      parcelDescription: order?.description,
      isDangerousGood: order?.isDangerousGood
    }
  })

const collectOrderReturn = (orders, prefilledOrderReturn) => {
  if (orders) {
    return _.map(orders, o => ({ ...o, size: transformSize(o.size) }))
  }
  return [prefilledOrderReturn]
}

export const selectMapping = () =>
  createSelector(selectChosenMappingId(), selector('entity', 'mappings', 'data')(), (id, mappings) =>
    _.find(mappings, { id })
  )

export const selectIsInternational = () =>
  createSelector(selectMapping(), mappings => mappings?.type === OC_SERVICE_TYPES.INTERNATIONAL)

export const selectOrders = ({ isReturnOrder = false, recompose = false } = {}) => {
  const ocSelectorPath = ['global', isReturnOrder ? 'orderReturn' : 'orderCreate']
  return createSelector(
    selectCountry(),
    selectChosenMappingId(),
    selector(...ocSelectorPath, 'orders')(),
    selector('global', 'orderCreate', 'progress', 'batchId')(),
    selector('global', 'orderCreate', 'failedOrders')(),
    selector('global', 'orderCreate', 'upload', 'data')(),
    selector(...ocSelectorPath, RESERVATION)(),
    selector('global', 'orderCreate', 'deliveryType')(),
    selector('global', 'orderCreate', 'pickupType')(),
    selector('entity', 'mappings', 'data')(),
    selectPrefilledOrderReturn(),
    selectIsCodSupported(),
    selectIsAllowedToUseAccountBalance(),
    selectIsCreatingShopifyOrders(),
    selectIsB2BBundleSupported(),
    selectAllowedDeliveryTimeslots(),
    (
      country,
      mappingId,
      orders,
      batchId,
      failedOrders,
      parsedFileData,
      reservations,
      deliveryType,
      pickupType,
      mappings,
      prefilledOrderReturn,
      isCodSupported,
      isAllowedToUseAccountBalance,
      isCreatingShopify,
      isB2BBundleSupported,
      allowedDeliveryTimeslots
    ) => {
      // In the middle of creating orders
      if (batchId && !recompose) {
        return failedOrders
      }

      if (isCreatingShopify && _.size(failedOrders)) {
        return failedOrders
      }

      if (isReturnOrder) {
        return collectOrderReturn(orders, prefilledOrderReturn)
      }
      const mapping = _.find(mappings, {
        id: mappingId
      })
      if (!mapping) {
        // Transform enums into expected string constants
        return _.map(orders, o => ({
          ...o,
          deliveryTimeslot: transformDeliveryTimeslot(o.deliveryTimeslot),
          size: transformSize(o.size)
        }))
      } else {
        const serviceType = mapping.type
        const fields = csvMapping.getOrderFields({
          serviceType,
          shipper: { country },
          orderCreate: { reservations, deliveryType, pickupType },
          isCodSupported,
          isAllowedToUseAccountBalance,
          isB2BBundleSupported,
          allowedDeliveryTimeslots
        })
        if (!parsedFileData) {
          return []
        }
        const parsedOrders = mapping.includeHeader ? parsedFileData.slice(1) : parsedFileData
        const fieldByKey = _.mapKeys(fields, field => field.key)
        return parsedOrders.map(o =>
          _.omit(
            _.mapKeys(o, (val, idx) => {
              const key = mapping.data[idx]
              if (fieldByKey[key]) {
                return _.camelCase(key || '')
              }
              return ''
            }),
            ''
          )
        )
      }
    }
  )
}

function getError (asyncUuid, batchErrors) {
  const details = (batchErrors?.[asyncUuid]?.details ?? []).map(x => {
    let msg = ''
    if (x.field) {
      msg += x.field
    }
    if (x.message) {
      msg += ' ' + x.message
    }
    return msg
  })

  if (_.isEmpty(details)) {
    return batchErrors?.[asyncUuid]?.message ?? ''
  }

  return details
}

export const selectErrorOrders = () =>
  createSelector(
    selectCountry(),
    selector('global', 'orderCreate', 'failedOrders')(),
    selector('global', 'orderCreate', 'batchError')(),
    (country, failedOrders, batchError = {}) => {
      const ordersWithError = []
      failedOrders.forEach((failedOrder, i) => {
        const batchSequenceNumber = failedOrder.batchSequenceNumber || -1
        const error = Object.values(batchError).find(e => e.batchSequenceNumber === batchSequenceNumber) || {}
        const asyncUuid = error.uuid
        const errorMsg = getError(asyncUuid, batchError)
        const size = global.isNaN(failedOrder.size) ? failedOrder.size : OCForm.SIZE_OPTS[parseInt(failedOrder.size)]
        const deliveryTimeslot = transformDeliveryTimeslot(failedOrder.deliveryTimeslot)

        const o = {
          ...failedOrder,
          error: errorMsg,
          asyncUuid,
          rowNum: i + 1,
          deliveryTimeslot,
          size
        }

        ordersWithError.push(o)
      })
      return ordersWithError
    }
  )

export const selectErrorFileFormat = () =>
  createSelector(
    selectErrorOrders(),
    selectIsBulkUpload(),
    selector('global', 'orderCreate')(),
    selector('entity', 'mappings', 'data')(),
    selectShipper(),
    selectIsCodSupported(),
    selectIsPrepaidShipper(),
    selectIsB2BBundleSupported(),
    selectAllowedDeliveryTimeslots(),
    generateErrorData
  )

const getOrdersCount = successOrders => {
  // Group the orders by the delivery type
  const groupedByDeliveryType = _.groupBy(successOrders, order => order.deliveryType.toLowerCase())

  // For each delivery type group, calculate the total count considering noOfCartons
  return _.mapValues(groupedByDeliveryType, orders =>
    _.sumBy(orders, order => {
      const cartons = parseInt(order.noOfCartons, 10) || 0
      return Math.max(cartons, 1)
    })
  )
}

const getCodInfo = successOrders => {
  const codInfo = { codOrdersCount: 0, codOrdersAmount: 0 }
  for (const order of successOrders) {
    if (order.cod) {
      codInfo.codOrdersCount += 1
      codInfo.codOrdersAmount += parseFloat(order.cod)
    }
  }
  return codInfo
}

export const selectOrderInfo = () =>
  createSelector(
    selector('global', 'orderCreate', 'successOrders')(),
    selector('ocCompleted', 'data', 'estimatedPrice')(),
    (successOrders, batchPrice) => {
      const estimatedPrice = batchPrice || 0
      return {
        orders: successOrders,
        ordersCount: getOrdersCount(successOrders),
        ...getCodInfo(successOrders),
        estimatedPrice: estimatedPrice
      }
    }
  )

export const selectIsParcelOCSupported = () =>
  createSelector(
    selectAvailableServiceTypes(),
    selectMasterAvailableServiceTypes(),
    selectIsSubShipperAccount(),
    (serviceTypes, masterServiceTypes, isSubShipper) => {
      return (
        _.includes(serviceTypes, OC_SERVICE_TYPES.PARCEL) ||
        (isSubShipper &&
          includesAny(masterServiceTypes, [
            OC_SERVICE_TYPES.CORPORATE,
            OC_SERVICE_TYPES.CORPORATE_DOCUMENT,
            OC_SERVICE_TYPES.MARKETPLACE
          ]))
      )
    }
  )

export const selectIsInternationalOCSupported = () =>
  createSelector(
    selectAvailableServiceTypes(),
    selectMasterAvailableServiceTypes(),
    selectIsSubShipperAccount(),
    (serviceTypes, masterServiceTypes, isSubShipper) => {
      return (
        _.includes(serviceTypes, OC_SERVICE_TYPES.INTERNATIONAL) ||
        (isSubShipper && _.includes(masterServiceTypes, OC_SERVICE_TYPES.MARKETPLACE_INTERNATIONAL))
      )
    }
  )

export const selectIsDocumentAvailable = () =>
  createSelector(selectAvailableServiceTypes(), serviceTypes => {
    return _.includes(serviceTypes, OC_SERVICE_TYPES.DOCUMENT)
  })

export const selectIsDocumentSupported = () =>
  createSelector(
    selectIsDocumentAvailable(),
    selectOCType(),
    (isDocumentTypeAvailable, ocType) => isDocumentTypeAvailable && ocType.toLowerCase() === 'regular'
  )

export const selectIsNinjaPackOCSupported = () =>
  createSelector(selectAvailableServiceTypes(), availableServiceTypes => {
    return _.includes(availableServiceTypes, OC_SERVICE_TYPES.NINJA_PACKS)
  })

export const selectIsCorporateAWBOCSupported = () =>
  createSelector(selectIsCorporateHQ(), selectAvailableServiceTypes(), (isCorporateHQ, availableServiceTypes) => {
    return isCorporateHQ && _.includes(availableServiceTypes, OC_SERVICE_TYPES.CORPORATE_AWB)
  })

export const selectIsNormalReturnSupported = () =>
  createSelector(selectAvailableServiceTypes(), selectIsCorporateBranch(), (serviceTypes, isCorporateBranch) => {
    return _.includes(serviceTypes, OC_SERVICE_TYPES.RETURN) && !isCorporateBranch
  })

export const selectIsCorporateReturnSupported = () =>
  createSelector(
    selectAvailableServiceTypes(),
    selectMasterAvailableServiceTypes(),
    selectIsCorporateBranch(),
    (serviceTypes, masterServiceTypes, isCorporateBranch) => {
      return (
        isCorporateBranch &&
        (_.includes(serviceTypes, OC_SERVICE_TYPES.CORPORATE_RETURN) ||
          _.includes(masterServiceTypes, OC_SERVICE_TYPES.CORPORATE_RETURN))
      )
    }
  )

export const selectIsCorporateDocumentAvailable = () =>
  createSelector(selectMasterAvailableServiceTypes(), serviceTypes => {
    return _.includes(serviceTypes, OC_SERVICE_TYPES.CORPORATE_DOCUMENT)
  })

export const selectIsCorporateAvailable = () =>
  createSelector(selectMasterAvailableServiceTypes(), serviceTypes => {
    return _.includes(serviceTypes, OC_SERVICE_TYPES.CORPORATE)
  })

export const selectIsParcelSupported = () =>
  createSelector(
    selectAvailableServiceTypes(),
    selectMasterAvailableServiceTypes(),
    selectIsSubShipperAccount(),
    (serviceTypes, masterServiceTypes, isSubShipper) => {
      return (
        _.includes(serviceTypes, OC_SERVICE_TYPES.PARCEL) ||
        (isSubShipper && includesAny(masterServiceTypes, [OC_SERVICE_TYPES.CORPORATE, OC_SERVICE_TYPES.MARKETPLACE]))
      )
    }
  )

export const selectIsCorporateDocumentSupported = () =>
  createSelector(
    selectIsCorporateDocumentAvailable(),
    selectOCType(),
    selectIsCorporateBranch(),
    (isCorporateDocumentTypeAvailable, ocType, isCorporateBranch) =>
      isCorporateDocumentTypeAvailable && isCorporateBranch && ocType.toLowerCase() === 'regular'
  )

export const selectIsReturnOCSupported = () =>
  createSelector(
    selectIsNormalReturnSupported(),
    selectIsCorporateReturnSupported(),
    (isNormalReturn, isCorporateReturn) => isNormalReturn || isCorporateReturn
  )

export const selectIsB2BBundleSupported = () =>
  createSelector(
    selectAvailableServiceTypes(),
    selectMasterAvailableServiceTypes(),
    selectIsCorporateBranch(),
    (serviceTypes, masterServiceTypes, isCorporateBranch) => {
      return (
        _.includes(serviceTypes, OC_SERVICE_TYPES.B2B_BUNDLE) ||
        (isCorporateBranch && includesAny(masterServiceTypes, [OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE]))
      )
    }
  )

export const selectIsCorporateB2BBundleSupported = () =>
  createSelector(
    selectIsCorporateBranch(),
    selectMasterAvailableServiceTypes(),
    (isCorporateBranch, masterServiceTypes) => {
      return isCorporateBranch && includesAny(masterServiceTypes, [OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE])
    }
  )

export const selectIsCodSupported = () =>
  createSelector(selectOcSettings(), orderCreateConfig => {
    return orderCreateConfig?.allowCodService ?? true
  })

export const selectIsScheduledPickupSupported = () =>
  createSelector(
    selectOCType(),
    selectCountry(),
    selectIsScheduledPickupAvailable(),
    (ocType, country, isAvailable) => {
      const whitelist = OCPickupOptions[country.toLowerCase()]
      const isWhitelisted = whitelist?.[ocType]?.[PICKUP_TYPES_OPTIONS_KEYS.SCHEDULED] ?? false
      return isWhitelisted && isAvailable
    }
  )

export const selectIsDropOffSupported = () =>
  createSelector(selectOCType(), selectCountry(), (ocType, country) => {
    const whitelist = OCPickupOptions[country.toLowerCase()]
    return whitelist?.[ocType]?.[PICKUP_TYPES_OPTIONS_KEYS.DROP_OFF] ?? false
  })

export const selectIsNoPickupSupported = () =>
  createSelector(selectOCType(), selectCountry(), (ocType, country) => {
    const whitelist = OCPickupOptions[country.toLowerCase()]
    return whitelist?.[ocType]?.[PICKUP_TYPES_OPTIONS_KEYS.NO] ?? false
  })

export const selectPickupType = () =>
  createSelector(
    selectOCType(),
    selector('global', 'orderCreate', 'pickupType')(),
    selector('global', 'orderReturn', 'pickupType')(),
    (ocType, regularOCPickupType, returnOCPickupType) => {
      return ocType === ORDER_CREATE_TYPES.RETURN ? returnOCPickupType : regularOCPickupType
    }
  )

export const selectIsCreditPaySupported = () =>
  createSelector(selector('entity', 'OCSettings', 'data', 'orderCreate', 'creditTerm')(), creditTerm => {
    return creditTerm > 0
  })

export const selectIsAllowedToUseAccountBalance = () =>
  createSelector(selector('entity', 'OCSettings', 'data', 'orderCreate')(), orderCreateConfig => {
    return orderCreateConfig?.allowUseAccountBalance ?? false
  })

export const selectAllowTopup = () =>
  createSelector(selector('entity', 'OCSettings', 'data', 'orderCreate')(), orderCreateConfig => {
    return orderCreateConfig?.allowTopup ?? false
  })

export const selectIsPricingStepVisible = () =>
  createSelector(selectIsAllowedToUseAccountBalance(), allowUseAccountBalance => allowUseAccountBalance)

export const selectDefaultOrderServiceType = selector('global', 'orderCreate', 'serviceType')

export { selectIsCreatingShopifyOrders }

// END TODO
