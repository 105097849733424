import { identity } from 'lodash'
import { createSelector } from 'reselect'

import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { SelectorUtils } from '@nv/react-commons/src/Utils'

import { selectCountry, selectIsPrepaidShipper, selectPaymentSettings } from 'containers/Base/selectors'
import { selectIsCreatingShopifyOrders } from 'containers/Shopify/Redux/selectors'
import { PAYMENT_SETTINGS } from 'constants/payment/paymentSettings'

const { selector } = SelectorUtils

export const PREPAID_COUNTRIES = [COUNTRIES.SG, COUNTRIES.MY, COUNTRIES.MA]
export const PREPAID_PARCEL_ENABLED_COUNTRIES = [...PREPAID_COUNTRIES, COUNTRIES.TH]

export const selectPrepaidBalance = () => selector('entity', 'prepaidAccountBalance', 'data', 'overallBalance')()

export const selectIsPrePaidAndSupported = () =>
  createSelector(
    selectIsPrepaidShipper(),
    selectCountry(),
    selectIsCreatingShopifyOrders(),
    (isPrepaidShipper, country, creatingShopifyOrders) => {
      return (
        !creatingShopifyOrders && !!country && PREPAID_COUNTRIES.includes(country.toLowerCase()) && isPrepaidShipper
      )
    }
  )

export const selectClientCredentialsErrorCode = () =>
  createSelector(selector('entity', 'clientCredentials', 'error', 'code')(), identity)

export const selectPricingData = () =>
  createSelector(selector('global', 'orderCreate', 'pricing', 'data', 'data')(), identity)

export const selectIsTopUpDisabled = () =>
  createSelector(selectCountry(), selectPaymentSettings(), (country, paymentSettings) => {
    return (
      !country ||
      [COUNTRIES.MA, COUNTRIES.MY].includes(country.toLowerCase()) ||
      paymentSettings != PAYMENT_SETTINGS.topupCredit
    )
  })
