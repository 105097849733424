import React from 'react'
import { Col } from '@nv/react-commons/src/Components'
import { PaddingRow, ShortText, TableHeaderRow } from './styles'
import { Text } from 'components/Text'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { faEdit } from '@fa-pro-light/faEdit'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { faTrashAlt } from '@fa-pro-light/faTrashAlt'
import { IconButton } from 'components/IconButton'
import { Tooltip } from 'antd'
import { compact } from 'lodash'

const formatNumber = value => value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const BoxTable = ({ orders, onDeleteOrder, onEditOrder }) => {
  const editOrder = order => {
    onEditOrder(order)
  }

  const deleteOrder = order => {
    onDeleteOrder(order)
  }

  return (
    <>
      <TableHeaderRow>
        <Col span={2}>
          <Text id='box_no' type='bold' style={{ paddingLeft: 8 }} />
        </Col>
        <Col span={4}>
          <Text id='shipper_box_id' type='bold' />
        </Col>
        <Col span={5}>
          <Text id='goods_description' type='bold' />
        </Col>
        <Col span={3}>
          <Text id='no_of_parcels' type='bold' />
        </Col>
        <Col span={3}>
          <Text id='goods_value' type='bold' />
        </Col>
        <Col span={4}>
          <Text id='mmcc_dimension' type='bold' />
        </Col>
        <Col span={3}>
          <Text id='weight_kg' type='bold' />
        </Col>
      </TableHeaderRow>

      {orders.map((order, index) => {
        const formatedGoodsValue = formatNumber(order.goodsValue)
        const formatedWeight = formatNumber(order.weight)
        const formatedQuantity = formatNumber(order.quantity)
        const dimensions = compact([order.length, order.width, order.height]).join(' x ')
        return (
          <PaddingRow key={order.boxNo}>
            <Col span={2}>
              <Text style={{ paddingLeft: 8 }}>{index + 1}</Text>
            </Col>
            <Col span={4}>
              <Text>{order.boxId}</Text>
            </Col>
            <Col span={5}>
              <Tooltip title={order.goodsDescription} placement='topLeft'>
                <ShortText>{order.goodsDescription}</ShortText>
              </Tooltip>
            </Col>
            <Col span={3}>
              <Text>{formatedQuantity}</Text>
            </Col>
            <Col span={3}>
              <Text>{formatedGoodsValue}</Text>
            </Col>
            <Col span={4}>
              <Text>{dimensions}</Text>
            </Col>
            <Col span={3}>
              <div className='flex justify-between'>
                <Text>{formatedWeight}</Text>
                <div>
                  <IconButton icon={faEdit} onClick={() => editOrder(order)} />
                  <IconButton icon={faTrashAlt} onClick={() => deleteOrder(order)} />
                </div>
              </div>
            </Col>
          </PaddingRow>
        )
      })}
    </>
  )
}

export { BoxTable }
