import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

export const { Types, Creators } = createActions(
  {
    getShopifyLocations: ['locations'],
    fulfillShopifyBatch: ['batchId'],
    setShopifyLocations: ['locations'],
    setFulfillingState: ['queue'],
    enqueuePendingFlash: ['flash'],
    dequeuePendingFlash: [],
    getOrderHistory: [],
    setFlash: ['batchId', 'status'],
    unsetFlash: [],
    checkFlash: [],
    getApiOrderHistory: ['reset', 'batchOffset'],
    setApiOrderHistory: ['reset', 'batch'],
    setApiOrderTotal: ['total'],
    setApiOrderLoading: ['loading'],
    setApiOrderError: ['error'],
    getAllOrdersHistory: ['reset', 'batchOffset'],
    setAllOrdersHistory: ['reset', 'batch'],
    setAllOrdersTotal: ['total'],
    setAllOrdersLoading: ['loading'],
    setAllOrdersError: ['error']
  },
  { prefix: 'OrderHistory/' }
)

const INITIAL_API_ORDER_STATE = {
  history: [],
  total: 0,
  loading: false,
  error: null
}
const INITIAL_STATE = fromJS({
  ...INITIAL_API_ORDER_STATE,
  fulfilling: [],
  shopifyLocations: null,
  pendingFlash: [],
  flash: {}
})

const setFulfillingState = (state, { queue }) => state.set('fulfilling', queue)
const enqueuePendingFlash = (state, { flash }) => state.update('pendingFlash', q => q.push(flash))
const dequeuePendingFlash = state => state.update('pendingFlash', q => q.shift())
const setFlash = (state, { batchId, status }) => state.set('flash', { batchId, status })
const unsetFlash = state => state.set('flash', {})
const setShopifyLocations = (state, { locations }) => state.set('shopifyLocations', locations)

const setApiOrderTotal = (state, { total }) => state.set('total', total)
const setApiOrderHistory = (state, { reset, batch }) => {
  if (reset) {
    return state.set('history', batch)
  }
  return state.update('history', prevHistory => [...prevHistory, ...batch])
}
const setApiOrderLoading = (state, { loading }) => state.set('loading', loading)
const setApiOrderError = (state, { error }) => state.set('error', error)

export const orderHistoryReducer = createReducer(INITIAL_STATE, {
  [Types.SET_FULFILLING_STATE]: setFulfillingState,

  [Types.ENQUEUE_PENDING_FLASH]: enqueuePendingFlash,
  [Types.DEQUEUE_PENDING_FLASH]: dequeuePendingFlash,

  [Types.SET_FLASH]: setFlash,
  [Types.UNSET_FLASH]: unsetFlash,

  [Types.SET_API_ORDER_HISTORY]: setApiOrderHistory,
  [Types.SET_API_ORDER_TOTAL]: setApiOrderTotal,
  [Types.SET_API_ORDER_LOADING]: setApiOrderLoading,
  [Types.SET_API_ORDER_ERROR]: setApiOrderError,
  [Types.SET_SHOPIFY_LOCATIONS]: setShopifyLocations
})

const setAllOrdersTotal = (state, { total }) => state.set('total', total)
const setAllOrdersHistory = (state, { reset, batch }) => {
  if (reset) {
    return state.set('history', batch)
  }
  return state.update('history', prevHistory => [...prevHistory, ...batch])
}
const setAllOrdersLoading = (state, { loading }) => state.set('loading', loading)
const setAllOrdersError = (state, { error }) => state.set('error', error)

export const allOrdersHistoryReducer = createReducer(INITIAL_STATE, {
  [Types.SET_ALL_ORDERS_HISTORY]: setAllOrdersHistory,
  [Types.SET_ALL_ORDERS_TOTAL]: setAllOrdersTotal,
  [Types.SET_ALL_ORDERS_LOADING]: setAllOrdersLoading,
  [Types.SET_ALL_ORDERS_ERROR]: setAllOrdersError
})
