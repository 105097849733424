import { wayBillApi } from 'services/api'
import { sleep } from 'utils/sleep'
import { getBaseDomain } from '../../services/utils'

const PULL_DELAY = 500
const billsPerPageToOrientation = { 1: 'full_landscape', 2: 'half_portrait', 4: 'quarter_landscape' }

const AWB_IN_PROGRESS_STATUS = [202, 204]

async function getFileUrl (country, taskId) {
  let fileResponse = await wayBillApi.getAWB(country, taskId)

  //In Queue. To keep pulling till the pdf url is returned
  while (AWB_IN_PROGRESS_STATUS.includes(fileResponse.status)) {
    await sleep(PULL_DELAY)
    fileResponse = await wayBillApi.getAWB(country, taskId)
  }

  if (!fileResponse.ok || !fileResponse?.data?.data2?.assetUuid) {
    return 'failed'
  }
  return getAssetsProxyAsset(fileResponse?.data?.data2?.assetUuid)
}

export const getBatchAwbUrl = async (country, batchId, billsPerPage, hideShipperDetails, page) => {
  const taskResponse = await wayBillApi.generateAWBByBatchId(country, {
    batchId,
    templateType: billsPerPageToOrientation[billsPerPage],
    hideShipperDetails,
    page
  })

  const taskId = taskResponse?.data?.data

  if (!taskResponse.ok || !taskId) {
    return 'failed'
  }

  return getFileUrl(country, taskId)
}

export const getTrackingIdsAwbBUrl = async (country, trackingIds, billsPerPage, hideShipperDetails, page, hideCod) => {
  const taskResponse = await wayBillApi.generateAWBByTrackingIDs(country, {
    trackingIds,
    templateType: billsPerPageToOrientation[billsPerPage],
    hideShipperDetails,
    page,
    hideCod
  })

  const taskId = taskResponse?.data?.data

  if (!taskResponse.ok || !taskId) {
    return 'failed'
  }

  return getFileUrl(country, taskId)
}

const getAssetsProxyAsset = (assetUuid) => {
  let baseUrl = getBaseDomain()
  return `${baseUrl}/assets/${assetUuid}`
}
