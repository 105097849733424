import React, { useContext } from 'react'
import { MMCC_OC_STEP, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { SupportedDocument } from './SupportedDocument'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'

const DeliveryOrderStep = () => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { deliveryStatus, deliveryFiles, deliveryErrors, deliveryFileNames } = ocState

  const handleSkip = () => {
    updateOCState({
      deliveryStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.REVIEW,
      deliveryFiles: [],
      deliveryErrors: [],
      deliveryFileNames: []
    })
  }

  const editStep = () => {
    updateOCState({
      deliveryStatus: deliveryFiles.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
        : SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD
    })
  }

  const saveFiles = (uploadedResult, fileErrors, fileNames) => {
    updateOCState({
      deliveryFiles: uploadedResult,
      deliveryErrors: fileErrors,
      deliveryFileNames: fileNames,
      deliveryStatus: fileErrors.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
        : SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
    })
  }

  const goToNextStep = () => {
    updateOCState({
      currentStep: MMCC_OC_STEP.REVIEW,
      deliveryStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW
    })
  }

  const handleReset = () => {
    updateOCState({
      deliveryFiles: [],
      deliveryStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      deliveryErrors: [],
      deliveryFileNames: []
    })
  }

  return (
    <SupportedDocument
      fileType={MMCC_OC_STEP.DELIVERY_ORDER}
      status={deliveryStatus}
      errors={deliveryErrors}
      fileNames={deliveryFileNames}
      uploadedFiles={deliveryFiles}
      onEditStep={editStep}
      onSave={saveFiles}
      onSkip={handleSkip}
      onNext={goToNextStep}
      onReset={handleReset}
    />
  )
}

export { DeliveryOrderStep }
