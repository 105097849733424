/**
 *
 * 4PL MMCC Order Batch by Batch ID
 *
 */
import { ROUTES } from 'containers/Base/constants'
import { PrintAwbModal } from 'containers/FPLOrderRequestList/PrintAwbModal'
import React, { useEffect, useMemo, useState } from 'react'
import { getOrderRequestList } from 'services/api/fplApi'
import { getUrlParameter } from 'utils/url'
import { ORDER_REQUEST_STATUS } from 'containers/FPLOrderRequestList/constants'
import { parseOrderRequestList, mappingOrderPrinters } from 'containers/FPLOrderRequestList/dataUtils'
import { PageLoading } from 'components/Loading'
import { useLocation, useNavigate } from 'react-router-dom'
import { message } from '@nv/react-commons/src/Components'
import { useIntl } from 'hooks/common'
import { ORDER_BATCH_STATUS } from 'containers/FPLOrderBatch/constants'
import { BatchWrapper } from 'containers/FPLOrderBatch/styles'
import { OrderBatchCard } from 'containers/FPLOrderBatch/OrderBatchCard'
import { countOrdersByStatus } from 'containers/FPLOrderBatch/dataUtils'
import { OrderSummary } from './OrderSummary'
import { buildFullErrorInfo } from 'containers/FPLErrorPage/dataUtils'

interface FPLMMCCOrderBatchProps {
  orderRequestList: any
  filterStatus: number
  isNextPage?: boolean
  loading: boolean
  orderBatchStatus: number
  totalCreated: number
  totalOrders: number
  totalGoodsValues: number
  totalNeededActions: number
  totalProcessing: number
  totalGoodsValueAmount: number
  isNextFix: boolean
}

const initState: FPLMMCCOrderBatchProps = {
  orderRequestList: [],
  filterStatus: ORDER_REQUEST_STATUS.ALL,
  isNextPage: false,
  loading: true,
  orderBatchStatus: ORDER_REQUEST_STATUS.CREATED,
  totalCreated: 0,
  totalOrders: 0,
  totalGoodsValues: 0,
  totalNeededActions: 0,
  totalProcessing: -1,
  totalGoodsValueAmount: 0,
  isNextFix: false
}

const FPLMMCCOrderBatch = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [showPrint, setShowPrint] = useState(false)
  const intl = useIntl()
  const batchId = useMemo(() => {
    return +getUrlParameter('id', location.search)
  }, [location.search])
  let isStopPulling = false

  const [state, setState] = useState(initState)
  const {
    orderBatchStatus,
    totalOrders,
    totalCreated,
    totalNeededActions,
    totalProcessing,
    orderRequestList,
    loading
  } = state
  useEffect(() => {
    const fetchData = async () => {
      if (!batchId) return

      const params = {
        limit: 1000,
        batch_id: batchId
      }
      const res = await getOrderRequestList(params)

      if (res.ok) {
        const resList = parseOrderRequestList(res.data?.data || [])
        const orderInfo = countOrdersByStatus(resList)
        isStopPulling = orderInfo.isReachLimitTime
        let batchStatusRes = -1
        if (orderInfo.totalCreated === orderInfo.totalOrders) {
          batchStatusRes = ORDER_BATCH_STATUS.ALL_CREATED

          if (!isStopPulling) {
            isStopPulling = true
          }
        } else if (orderInfo.totalCreated === 0 && orderInfo.totalProcessing === 0) {
          batchStatusRes = ORDER_BATCH_STATUS.NO_CREATED

          if (!isStopPulling) {
            isStopPulling = true
          }
        } else {
          batchStatusRes = ORDER_BATCH_STATUS.PARTIAL_CREATED
        }

        setState(prevState => ({
          ...prevState,
          isNextPage: res.data?.paging?.next,
          loading: false,
          orderRequestList: resList,
          totalCreated: orderInfo.totalCreated,
          totalOrders: orderInfo.totalOrders,
          totalNeededActions: orderInfo.totalNeededActions,
          orderBatchStatus: batchStatusRes,
          totalProcessing: orderInfo.totalProcessing
        }))
      } else {
        if (res.status !== 500) {
          message.error(intl.formatMessage({ id: 'error_load_order_request' }))
          setState(prevState => ({
            ...prevState,
            loading: false
          }))
        } else {
          const { apiUrl, message: errorMsg, status } = buildFullErrorInfo(res)
          setState(prevState => ({
            ...prevState,
            loading: false,
            errors: [errorMsg],
            apiUrl,
            status
          }))
          isStopPulling = true
        }
      }
    }

    const intervalId = setInterval(() => {
      if (!isStopPulling) {
        fetchData()
      }
    }, 3000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const showPrintModal = () => setShowPrint(true)

  const hidePrintModal = () => setShowPrint(false)

  const handleClickFixing = () => {
    navigate(`${ROUTES.FPL_OC_REQUEST_BY_BATCH}?batch_id=${batchId}`)
  }

  if (loading) {
    return <PageLoading />
  }

  return (
    <>
      <BatchWrapper>
        <PrintAwbModal
          parcelIds={orderRequestList.map(order => order.parcel_id)}
          batchId={batchId}
          onCancel={hidePrintModal}
          totalOrderCount={orderRequestList.length}
          visible={showPrint}
          orders={mappingOrderPrinters(orderRequestList)}
        />
        <OrderBatchCard
          orderBatchStatus={orderBatchStatus}
          totalProcessing={totalProcessing}
          totalCreated={totalCreated}
          totalOrders={totalOrders}
          showPrintModal={showPrintModal}
          handleFixing={handleClickFixing}
        />
        <OrderSummary
          totalOrders={totalOrders}
          totalNeededActions={totalNeededActions}
          totalCreated={totalCreated}
          orderBatchStatus={orderBatchStatus}
          orderRequestList={orderRequestList}
          totalProcessing={totalProcessing}
        />
      </BatchWrapper>
    </>
  )
}

export default FPLMMCCOrderBatch
