import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/Text'
import { Tabs } from 'antd/lib'
import { T } from '@nv/react-commons/src/Components'
import { InfoTooltip } from 'containers/FPLOrderRequestList/Fields'
import { getKeysTab } from 'containers/FPLOrderCreate/constants'
import { FIELD_KEYS } from './constants'
import { FullScreenModal, StyledModalHeader } from 'containers/FPLOrderCreate/styles'
import { ReviewOrderTable } from 'containers/FPLOrderCreate/ReviewOrderTable'
import { useIntl } from 'hooks/common'
import { convertObjectTo2DArray } from 'containers/FPLOrderCreate/dataUtils'
import { makeFriendlyError2DArray } from './utils'
import { StyledTabs } from './styles'

const { TabPane } = Tabs

const ReviewOrdersModal = ({ onClose, dataSource }) => {
  const intl = useIntl()
  const TABS = getKeysTab(true)
  const [activeKey, setActiveKey] = useState(TABS.INVALID_ORDER.KEY)
  const tabsData = useMemo(() => {
    return [
      {
        label: TABS.VALID_ORDER.LABEL,
        key: TABS.VALID_ORDER.KEY,
        quantity: Object.keys(dataSource.validOrders).length
      },
      {
        label: TABS.INVALID_ORDER.LABEL,
        key: TABS.INVALID_ORDER.KEY,
        quantity: Object.keys(dataSource.invalidOrders).length
      },
      {
        label: TABS.ALL_ORDERS.LABEL,
        key: TABS.ALL_ORDERS.KEY,
        quantity: Object.keys(dataSource.allOrders).length
      }
    ]
  }, [dataSource, TABS])

  const sourceTable = useMemo(() => {
    let source = dataSource?.allOrders

    switch (activeKey) {
      case TABS.VALID_ORDER.KEY:
        source = dataSource?.validOrders
        break
      case TABS.INVALID_ORDER.KEY:
        source = dataSource?.invalidOrders
        break
    }

    const sourceArr = convertObjectTo2DArray(source || [])
    const formatedSourceArr = makeFriendlyError2DArray(sourceArr, intl)

    return formatedSourceArr
  }, [activeKey, dataSource, TABS, intl])

  const renderTabHeader = () => {
    return tabsData.map(item => (
      <TabPane
        tab={
          <>
            <T id={item.label} /> <span>{`(${item.quantity})`}</span>
          </>
        }
        key={item.key}
      />
    ))
  }

  const handleTabClick = key => {
    setActiveKey(key)
  }

  return (
    <FullScreenModal
      visible
      zIndex={1059}
      width='100vw'
      height='80%'
      wrapClassName='international-overflow-hidden'
      title={
        <StyledModalHeader>
          <div className='flex items-center'>
            <Text size={16} type='bold' id='international_box_list_detail' />
            <InfoTooltip tooltipTextId='international_box_list_items_detail_info' />
          </div>
        </StyledModalHeader>
      }
      onCancel={onClose}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-expect-error */}
      <StyledTabs defaultActiveKey={TABS.INVALID_ORDER.KEY} onTabClick={handleTabClick}>
        {renderTabHeader()}
      </StyledTabs>
      <ReviewOrderTable dataSource={sourceTable} headerFields={['Row#', ...Object.values(FIELD_KEYS)]} />
    </FullScreenModal>
  )
}

ReviewOrdersModal.propTypes = {
  onClose: PropTypes.func,
  dataSource: PropTypes.object,
  isItemised: PropTypes.bool,
  destinationCountry: PropTypes.string,
  isBulkRelabelVersion: PropTypes.bool
}

export { ReviewOrdersModal }
