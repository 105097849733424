export const FIELDS = {
  EXTERNAL_ORDER_ID: 'externalOrderId',
  NAME: 'name',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  DELIVERY_START_DATE: 'deliveryStartDate',
  DELIVERY_ZONE: 'deliveryZone',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  POSTCODE: 'postcode',
  SIZE: 'size',
  WIDTH: 'width',
  HEIGHT: 'height',
  LENGTH: 'length',
  WEIGHT: 'weight',
  COD: 'cod',
  INSURED_VALUE: 'insuredValue',
  REQUESTED_TRACKING_NUMBER: 'requestedTrackingNumber',
  PARCEL_DESCRIPTION: 'parcelDescription',
  IS_DANGEROUS_GOOD: 'isDangerousGood',
  DELIVERY_TYPE: 'deliveryType',
  DELIVERY_TIMESLOT: 'deliveryTimeslot',
  INSTRUCTIONS: 'instructions',
  DISTRICT: 'district',
  CITY: 'city',
  PROVINCE: 'province',
  AREA: 'area',
  STATE: 'state',
  KECAMATAN: 'kecamatan',
  SUB_DISTRICT: 'sub_district',
  SUBDIVISION: 'subdivision',
  TOWNSHIP: 'township',
  CARTON_NUMBER: 'noOfCartons',
  RDO_REQUIRED: 'rdoRequired',
  GRN_REQUIRED: 'grnRequired',
  SHIPPER_ORDER_NUMBER: 'shipperOrderNumber',
  REQUESTED_PIECE_TRACKING_NUMBERS: 'requestedPieceTrackingNumbers'
} as const

export const dimensionFields = [FIELDS.WIDTH, FIELDS.HEIGHT, FIELDS.LENGTH]

export const SIZE_OPTS = ['S', 'M', 'L', 'XL', 'XXL'] as const

export const SIZE_DIMENSIONS = {
  S: {
    width: 20,
    height: 30,
    length: 10
  },
  M: {
    width: 27,
    height: 43,
    length: 20
  },
  L: {
    width: 40,
    height: 50,
    length: 30
  },
  XL: {
    width: 100,
    height: 100,
    length: 100
  }
}

export const DIMENSIONS_EXCEED_LIMIT_ERROR = 'dimensions_exceed_limit_error'
